<!-- =========================================================================================
  File Name: App.vue
  Description: Main vue file - APP
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div id="app" :class="vueAppClasses">
    <loading v-if="isLoading" />
    <router-view @setAppClasses="setAppClasses" />
  </div>
</template>

<script>
import themeConfig from '@/../themeConfig.js'
import jwt         from '@/http/requests/auth/jwt/index.js'
import Loading from '@/views/q-pages/Loading-Screen/Loading.vue'
export default {
  data () {
    return {
      vueAppClasses: []
    }
  },
  watch: {
    '$store.state.theme' (val) {
      this.toggleClassInBody(val)
    },
    '$vs.rtl' (val) {
      document.documentElement.setAttribute('dir', val ? 'rtl' : 'ltr')
    }
  },
  computed: {
    // custom for qosoor
    isLoggedIn : function(){
      return this.$store.getters.isLoggedIn
    },
    isLoading(){
      return  this.$store.getters.isLoading || this.$store.state.loader.isLoading;
    }
    /////////
  },
  components: {
    Loading
  },
  methods: {
    // custom for q
    logout(){
      this.$store.dispatch('logout')
      .then(() => {
        this.$router.push('/login')
      })
    },
    /////////
    toggleClassInBody (className) {
      if (className === 'dark') {
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
        document.body.classList.add('theme-dark')
      } else if (className === 'semi-dark') {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
        document.body.classList.add('theme-semi-dark')
      } else {
        if (document.body.className.match('theme-dark'))      document.body.classList.remove('theme-dark')
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
      }
    },
    setAppClasses (classesStr) {
      this.vueAppClasses.push(classesStr)
    },
    handleWindowResize () {
      this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

      // Set --vh property
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    },
    handleScroll () {
      this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY)
    },
    init(){
      //  axios.interceptors.response.use(function (response) {
      //     return response
      //   }, function (error) {
      //     // const { config, response: { status } } = error
      //     const { config, response } = error
      //     const originalRequest = config

      //     // if (status === 401) {
      //     if (response && response.status === 401) {
           
      //       store.dispatch('auth/setAccessToken', access_token);

      //       const retryOriginalRequest = new Promise((resolve) => {
      //         addSubscriber(access_token => {
      //           originalRequest.headers.Authorization = `Bearer ${access_token}`
      //           resolve(axios(originalRequest))
      //         })
      //       })
      //       return retryOriginalRequest
      //     }
      //     return Promise.reject(error)
      //   })
    }
  },
  mounted () {
    this.toggleClassInBody(themeConfig.theme)
    if(this.isLoggedIn) this.$store.dispatch('auth/agentVenues',this.$store.getters.currentUser.id)
    this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

    const vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  },
  async created () {

    // jwt
    // jwt.init()
    this.init();
    let locale =  await localStorage.getItem('locale') || 'ar';
    this.$vs.rtl = locale == 'ar'? true:false; 
    const dir = this.$vs.rtl ? 'rtl' : 'ltr'
    document.documentElement.setAttribute('dir', dir)

    window.addEventListener('resize', this.handleWindowResize)
    window.addEventListener('scroll', this.handleScroll)

    // Auth0
    try       { await this.$auth.renewTokens() } catch (e) { console.error(e) }

  },
  destroyed () {
    window.removeEventListener('resize', this.handleWindowResize)
    window.removeEventListener('scroll', this.handleScroll)
  }
}

</script>
