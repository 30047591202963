/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

import moduleAuth from './auth/moduleAuth.js'
import moduleDataList from './data-list/moduleDataList.js'
import receipts from './receipts/moduleReceipt'
import loader from './loader/moduleLoader'
import customPricings from './customPricings/moduleCustomPricing'
import bookingStates from './bookingStates/moduleBookingStates'
import general from './general/moduleGeneral'
import businessEvents from './businessEvents/moduleBusinessEvent'
import settlements from './settlements/moduleSettlement'
import reviews from './reviews/moduleReview'
import categories from './categories/moduleCategory'
import bookingOptions from './bookingOptions/moduleBookingOption'
import services from './services/moduleService'
import customersQuestions from './customersQuestions/moduleCustomersQuestions'
import venue from './venue/moduleVenue'

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth,
    dataList: moduleDataList,
    receipts: receipts,
    loader: loader,
    customPricings: customPricings,
    bookingStates: bookingStates,
    general: general,
    businessEvents: businessEvents,
    settlements: settlements,
    reviews:reviews,
    categories: categories,
    bookingOptions: bookingOptions,
    services: services,
    customersQuestions: customersQuestions,
    venue: venue
  },
  strict: process.env.NODE_ENV !== 'production'
})
