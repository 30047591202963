export default {
    install (Vue) {
        Vue.prototype.generateRandomString = function(nbChars = 3) {
            let result           = '';
            let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            let charactersLength = characters.length;
            for ( let i = 0; i < nbChars; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },
        Vue.prototype.generateRandomNumber = function(nbDigits = 3) {
            let result           = '';
            let characters       = '0123456789';
            let charactersLength = characters.length;
            for ( let i = 0; i < nbDigits; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }
    }
}