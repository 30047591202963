/*=========================================================================================
  File Name: moduleTodoActions.js
  Description: Todo Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

export default {
  async getBusinessEvents ({ commit },{venueId, params}) {
    return await new Promise((resolve, reject) => {

      axios.get(`/venues/${venueId}/businessEventRequests`, {params})
        .then((response) => {
          commit('SET_BUSINESS_EVENTS', response.data.eventRequests)
          commit('SET_BUSINESS_EVENTS_COUNT', response.data.eventRequests.length)
          resolve(response)
        })
        .catch((error) => { 
          console.log(error, "from store :(")
          reject(error) 
        })
    })
  },
  async changeStatus ({ commit },{businessEventId, venueId, params}) {
    return await new Promise((resolve, reject) => {

      axios.post(`/venues/${venueId}/businessEventRequests/${businessEventId}/changeStatus`, params)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { 
          console.log(error, "from store :(")
          reject(error) 
        })
    })
  }, 
  async getProposals ({ commit },{businessEventId, venueId}) {
    return await new Promise((resolve, reject) => {
      axios.get(`/venues/${venueId}/businessEventRequests/${businessEventId}/proposals`)
        .then((response) => {
          commit('SET_PROPOSALS', response.data)
          resolve(response)
        })
        .catch((error) => { 
          console.log(error, "from store :(")
          reject(error) 
        })
    })
  }, 
  async sendProposal ({ commit },{businessEventId, venueId, proposal}) {
    return await new Promise((resolve, reject) => {
      
      const body = new FormData();
      if(proposal.name) body.append('name', proposal.name);
      if(proposal.details) body.append('details', proposal.details);
      if(proposal.price) body.append('price', proposal.price);
      
      proposal.attachments.forEach(attachment => {
        body.append('attachments[]', attachment);
      });

      axios.post(`/venues/${venueId}/businessEventRequests/${businessEventId}/sendProposal`, body)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { 
          console.log(error, "from store :(")
          reject(error) 
        })
    })
  }, 
}
