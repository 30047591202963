/*=========================================================================================
  File Name: moduleTodoActions.js
  Description: Todo Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

export default {
  async getSettlements ({ commit },{venueId, params}) {
    return await new Promise((resolve, reject) => {

      axios.get(`/venues/${venueId}/settlements`, {params})
        .then((response) => {
          commit('SET_SETTLEMENTS', response.data.data)
          resolve(response)
        })
        .catch((error) => { 
          console.log(error, "from store :(")
          reject(error) 
        })
    })
  },
  async submitAnswer ({ commit },{settlementId, venueId, params}) {
    return await new Promise((resolve, reject) => {

      const data = new FormData();

      data.append('status', params.action);

      if(params.rejection_reason) data.append('rejection_reason', params.rejection_reason);

      axios.post(`/venues/${venueId}/settlements/${settlementId}/submitAnswer`, data)
        .then((response) => {
          commit('UPDATE_SETTLEMENT', response.data)
          resolve(response)
        })
        .catch((error) => { 
          console.log(error, "from store :(")
          reject(error) 
        })
    })
  }, 
  async getInvolvedBookings ({ commit },{settlementId, venueId}) {
    return await new Promise((resolve, reject) => {

      axios.get(`/venues/${venueId}/settlements/${settlementId}/involvedBookings`)
        .then((response) => {
          commit('SET_INVOLVED_BOOKINGS', response.data.data)
          commit('SET_INVOLVED_BOOKINGS_LOADED', true)
          resolve(response)
        })
        .catch((error) => { 
          console.log(error, "from store :(")
          reject(error) 
        })
    })
  },
}
