/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_DAYS_LIST(state, daysList) {
    state.daysList = daysList
  },
  SET_FACILITIES_LIST(state, facilitiesList) {
    state.facilitiesList = facilitiesList
  },
  SET_ROOMS_LIST(state, roomsList) {
    state.roomsList = roomsList
  },
  SET_CITIES_LIST(state, citiesList) {
    state.citiesList = citiesList
  },
  SET_BOOKING_OPTIONS_LIST(state, bookingOptionsList) {
    state.bookingOptionsList = bookingOptionsList
  },
}
