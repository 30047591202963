/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  toggleLoader({commit}){
    commit('TOGGLE_LOADER', null);
  },
  loaderOff({commit}){
    commit('SET_LOADER', false);
  },
  loaderOn({commit}){
    commit('SET_LOADER', true);
  }
}
