/*=========================================================================================
  File Name: moduleTodoActions.js
  Description: Todo Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

export default {
  // async getReviews ({ commit },{venueId, params}) {
  //   return await new Promise((resolve, reject) => {

  //     axios.get(`/venues/${venueId}/reviews`, {params})
  //       .then((response) => {
          
  //         commit('SET_REVIEWS', response.data.data )
          
  //         resolve(response)
  //       })
  //       .catch((error) => { 
  //         console.log(error, "from store :(")
  //         reject(error) 
  //       })
  //   })
  // },

  async getReviews ({ commit },{venueId, params}) {
    try {
      
      const response = await axios.get(`/venues/${venueId}/reviews`, { params })
      commit('SET_REVIEWS', response.data.data);
      
      return response;

    } catch (error) {

      return error.response;
    }
  },
  
}
