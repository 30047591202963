/*=========================================================================================
  File Name: moduleTodoActions.js
  Description: Todo Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

export default {
  async getServices ({ commit },{venueId, query}) {
    try {
      
      const response = await axios.get(`/venues/${venueId}/services`, { params: query })
      commit('SET_SERVICES', response.data.data);
      
      return response;

    } catch (error) {

      return error.response;
    }
  },
  async updateService({ commit }, {venueId, data}) {
    let service = data.service;

    data.venueId = venueId;

    const formData = _serviceFormData(service, data);
    
    try { 
      const response = await axios.post(`/venues/${venueId}/services/${service.id}/update`, formData)
      
      commit('UPDATE_SERVICE', response.data.service)
      
      return response;

    } catch (error) {

      return error.response;
    }

  },
  async duplicateService({ commit }, {venueId, data}) {
    let service = data.service;

    data.venueId = venueId;

    const formData = _serviceFormData(service, data);
    formData.append('is_duplicate', true);
    formData.append('duplicated_service', service.id);
    
    try { 
      const response = await axios.post(`/venues/${venueId}/services`, formData)
      
      commit('ADD_SERVICE', response.data.service)
      
      return response;

    } catch (error) {

      return error.response;
    }
  },
  async addService({ commit }, {venueId, data}) {
    let service = data.service;

    data.venueId = venueId;

    const formData = _serviceFormData(service, data);
    
    try { 
      const response = await axios.post(`/venues/${venueId}/services`, formData)
      
      commit('ADD_SERVICE', response.data.service)
      
      return response;

    } catch (error) {

      return error.response;
    }

  },
  async deleteService({ commit }, {venueId, data}) {
    let service = data.service;

    try { 
      const response = await axios.delete(`/venues/${venueId}/services/${service.id}`)

      if(response.status == 200)
        commit('REMOVE_SERVICE', service);
      
      return response;

    } catch (error) {

      return error.response;
    }


  }
}




function _serviceFormData(service, data) { // this function sets up the basic service form data object, used in [add, update, duplicate] actions


  let selectedBookingOptions = data.selectedBookingOptions;
  let source = data.source;
  let venueId = data.venueId;
  let images = data.images;

  const formData = new FormData();

  formData.append('pricing_type', service.pricing_type);
  formData.append('percentage_tax', service.percentage_tax);
  formData.append('custom_prices', JSON.stringify(service.custom_prices))
  if(selectedBookingOptions && selectedBookingOptions.length > 0) selectedBookingOptions.forEach(booking_option => formData.append('bookingOptions[]', booking_option.id)); else formData.append('bookingOptions', []);
  if(images && images.length > 0) images.forEach(img => formData.append('images[]', img.imgUrl ? img.imgUrl : img.path));
  formData.append('name', service.name);
  formData.append('description', service.description);
  formData.append('fixed_price', service.fixed_price);
  formData.append('tax_included', service.tax_included);
  formData.append('provider_type', 'App\Venue');
  formData.append('provider_id', venueId);
  formData.append('source', source);


  return formData;
}
