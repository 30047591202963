/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios'
import jwt from '../../http/requests/auth/jwt/index.js'


import firebase from 'firebase/app'
import 'firebase/auth'
import router from '@/router'
import { resolve } from 'core-js/fn/promise'

export default {
  // login by sms
  loginBySMS ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('AUTH_REQUESTED')
      payload.append('test', true)
      axios.post('/loginBySMS', payload)
      .then((response) => {
        // console.log("response from actions isssss")
        // console.log(response);
        // console.log("ressss issss", response)
        commit('AUTH_VC_SENT')
        resolve(response)
      }).catch(error => {
        console.log("errrrrrorrrr")
        console.log("the error is", error)
        commit('AUTH_PHONE_NUMBER_ERROR')
        reject(error)
      });
    })
  },

  verifyCode({commit}, payload){
    return new Promise((resolve, reject) => {
      axios.post('/checkCodeToLogin', payload)
      .then((response) => {
        var token = response.data.token
        var user = response.data.user
        var venue = response.data.venue
        var agentVenues = response.data.agentVenues
        var contract = response.data.contract
        var percentage = response.data.percentage
        commit("AUTH_SUCCEEDED", {token, user, venue,contract, percentage,agentVenues})
        resolve(response)
      }).catch(error => {
        // const errorMessage = error.response.data.error_message
        commit('AUTH_VC_CODE_ERROR')
        reject(error)
      })
    })
  },

  signContract({commit}, venueId){
    return new Promise((resolve, reject) => {
      axios.post(`venues/${venueId}/signContract`,{})
      .then((response) => {
        commit("SIGN_CONDITIONS_SUCCEEDED", 
                    {venue: response.data.data.venue, 
                        contract:response.data.data.contract})
        resolve(response.data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  logout({commit}){
    return new Promise((resolve, reject) => {      
      commit('LOGOUT_SUCCEEDED')
      resolve()
    })    
  },

  // firebase
  loginAttempt ({ dispatch }, payload) {

    // New payload for login action
    const newPayload = {
      userDetails: payload.userDetails,
      notify: payload.notify,
      closeAnimation: payload.closeAnimation
    }

    // If remember_me is enabled change firebase Persistence
    if (!payload.checkbox_remember_me) {

      // Change firebase Persistence
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

      // If success try to login
        .then(function () {
          dispatch('login', newPayload)
        })

      // If error notify
        .catch(function (err) {

          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()

          payload.notify({
            time: 2500,
            title: 'Error',
            text: err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    } else {
      // Try to login
      dispatch('login', newPayload)
    }
  },
  login ({ commit, state, dispatch }, payload) {

    // If user is already logged in notify and exit
    if (state.isUserLoggedIn()) {
      // Close animation if passed as payload
      if (payload.closeAnimation) payload.closeAnimation()

      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })

      return false
    }

    // Try to sigin
    firebase.auth().signInWithEmailAndPassword(payload.userDetails.email, payload.userDetails.password)

      .then((result) => {

        // Set FLAG username update required for updating username
        let isUsernameUpdateRequired = false

        // if username is provided and updateUsername FLAG is true
        // set local username update FLAG to true
        // try to update username
        if (payload.updateUsername && payload.userDetails.displayName) {

          isUsernameUpdateRequired = true

          dispatch('updateUsername', {
            user: result.user,
            username: payload.userDetails.displayName,
            notify: payload.notify,
            isReloadRequired: true
          })
        }

        // Close animation if passed as payload
        if (payload.closeAnimation) payload.closeAnimation()

        // if username update is not required
        // just reload the page to get fresh data
        // set new user data in localstorage
        if (!isUsernameUpdateRequired) {
          router.push(router.currentRoute.query.to || '/')
          commit('UPDATE_USER_INFO', result.user.providerData[0], {root: true})
        }
      }, (err) => {

        // Close animation if passed as payload
        if (payload.closeAnimation) payload.closeAnimation()

        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
  },

  // Google Login
  loginWithGoogle ({commit, state}, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })
      return false
    }
    const provider = new firebase.auth.GoogleAuthProvider()

    firebase.auth().signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_USER_INFO', result.user.providerData[0], {root: true})
      }).catch((err) => {
        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
  },

  // Facebook Login
  loginWithFacebook ({commit, state}, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })
      return false
    }
    const provider = new firebase.auth.FacebookAuthProvider()

    firebase.auth().signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_USER_INFO', result.user.providerData[0], {root: true})
      }).catch((err) => {
        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
  },

  // Twitter Login
  loginWithTwitter ({commit, state}, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })
      return false
    }
    const provider = new firebase.auth.TwitterAuthProvider()

    firebase.auth().signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_USER_INFO', result.user.providerData[0], {root: true})
      }).catch((err) => {
        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
  },

  // Github Login
  loginWithGithub ({commit, state}, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })
      return false
    }
    const provider = new firebase.auth.GithubAuthProvider()

    firebase.auth().signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || '/')
        commit('UPDATE_USER_INFO', result.user.providerData[0], {root: true})
      }).catch((err) => {
        payload.notify({
          time: 2500,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
  },
  registerUser ({dispatch}, payload) {

    // create user using firebase
    firebase.auth().createUserWithEmailAndPassword(payload.userDetails.email, payload.userDetails.password)
      .then(() => {
        payload.notify({
          title: 'Account Created',
          text: 'You are successfully registered!',
          iconPack: 'feather',
          icon: 'icon-check',
          color: 'success'
        })

        const newPayload = {
          userDetails: payload.userDetails,
          notify: payload.notify,
          updateUsername: true
        }
        dispatch('login', newPayload)
      }, (error) => {
        payload.notify({
          title: 'Error',
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
  },
  updateUsername ({ commit }, payload) {
    payload.user.updateProfile({
      displayName: payload.displayName
    }).then(() => {

      // If username update is success
      // update in localstorage
      const newUserData = Object.assign({}, payload.user.providerData[0])
      newUserData.displayName = payload.displayName
      commit('UPDATE_USER_INFO', newUserData, {root: true})

      // If reload is required to get fresh data after update
      // Reload current page
      if (payload.isReloadRequired) {
        router.push(router.currentRoute.query.to || '/')
      }
    }).catch((err) => {
      payload.notify({
        time: 8800,
        title: 'Error',
        text: err.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    })
  },


  // JWT
  loginJWT ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      jwt.login(payload.userDetails.email, payload.userDetails.password)
        .then(response => {

          // If there's user data in response
          if (response.data.userData) {
            // Navigate User to homepage
            router.push(router.currentRoute.query.to || '/')

            // Set accessToken
            localStorage.setItem('accessToken', response.data.accessToken)

            // Update user details
            commit('UPDATE_USER_INFO', response.data.userData, {root: true})

            // Set bearer token in axios
            commit('SET_BEARER', response.data.accessToken)

            resolve(response)
          } else {
            reject({message: 'Wrong Email or Password'})
          }

        })
        .catch(error => { reject(error) })
    })
  },
  registerUserJWT ({ commit }, payload) {

    const { displayName, email, password, confirmPassword } = payload.userDetails

    return new Promise((resolve, reject) => {

      // Check confirm password
      if (password !== confirmPassword) {
        reject({message: 'Password doesn\'t match. Please try again.'})
      }

      jwt.registerUser(displayName, email, password)
        .then(response => {
          // Redirect User
          router.push(router.currentRoute.query.to || '/')

          // Update data in localStorage
          localStorage.setItem('accessToken', response.data.accessToken)
          commit('UPDATE_USER_INFO', response.data.userData, {root: true})

          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  // fetchAccessToken () {
  //   return new Promise((resolve) => {
  //     jwt.refreshToken().then(response => { resolve(response) })
  //   })
  // },

  registerUserJWT ({ commit }, payload) {

    const { displayName, email, password, confirmPassword } = payload.userDetails

    return new Promise((resolve, reject) => {

      // Check confirm password
      if (password !== confirmPassword) {
        reject({message: 'Password doesn\'t match. Please try again.'})
      }

      jwt.registerUser(displayName, email, password)
        .then(response => {
          // Redirect User
          router.push(router.currentRoute.query.to || '/')

          // Update data in localStorage
          localStorage.setItem('accessToken', response.data.accessToken)
          commit('UPDATE_USER_INFO', response.data.userData, {root: true})

          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  fetchAccessToken () {
    return new Promise((resolve) => {
      jwt.refreshToken().then(response => { resolve(response) })
    })
  },
  setAccessToken({ commit }, payload){
    commit('SET_BEARER', payload);
  },
  agentVenues({commit}, userId){
    return new Promise((resolve, reject) => {
      axios.get(`agentBranches/${userId}`,{})
      .then((response) => {
        commit('AGENT_VENUES', response.data.agentVenues);
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  changeVenue({commit}, venue){
    return new Promise((resolve, reject) => {
      commit("CHANGE_VENUE", {venue})
      resolve(venue)
    })
  },
  updateVenueLogo({commit}, logo){
    return new Promise((resolve, reject) => {
      commit("UPDATE_VENUE_LOGO", {logo})
      resolve(logo)
    })
  },
  async updateUserInformation({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      const body = new FormData();
      if (userInfo.full_name) body.append('full_name', userInfo.full_name);
      if (userInfo.email) body.append('email', userInfo.email);
      if (userInfo.phone_number) body.append('phone_number', userInfo.phone_number);
  
      axios
        .put(`/agents`, {
          full_name: userInfo.full_name,
          email: userInfo.email,
          phone_number: userInfo.phone_number,
        }) 
        .then(response => {
          commit('SET_USER', response.data.user);
          resolve(response);
        })
        .catch(error => {
          console.error('Error updating user information:', error);
          reject(error);
        });
    });
  },

  async verifyVc({ commit }, {vc,phone_number}) {
    try {
      const response = await axios.post('/agents/verify_update', { vc, phone_number }); 
      return response;
    } catch (error) {
      console.error('Error sending vc:', error);
      return error;
    }
  },


};


