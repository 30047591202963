/*=========================================================================================
  File Name: moduleTodoActions.js
  Description: Todo Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

export default {
  async getMarketingCreditForCurrentVenue ({ commit },{venueId}) {
    try {
      
      const response = await axios.get(`/venues/${venueId}/get_venue_marketing_credit`)
      commit('SET_MARKETING_CREDIT', response.data.marketing_credit);
      
      return response;

    } catch (error) {

      return error.response;
    }
  },
  async addRequestToAirtable({ commit }, data) {
    try {

      console.log(data);
      const payload = new FormData();
      payload.append('agent', data.agent);
      payload.append('agent_phone_number', data.agent_phone_number);
      payload.append('package_title', data.package_title);
      payload.append('price', data.price);
      data.includings.forEach(including => {
        payload.append('includings[]', including.title_en);
      });
      
      const response = await axios.post(`/venues/${data.venue_id}/businessMarketing/airtable/add_request_to_airtable`, payload);


      commit('SET_MARKETING_CREDIT', response.data.marketing_credit);
      
      return response;

    } catch (error) {
      return error.response;
    }
  }
  
}
