<template>
  <div id="loading" class="fixed top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center w-full h-screen img bg-secondary-500 loading">
    <img
      class="w-full md:w-2/5 lg:w-1/5 h-auto"
      src="@/assets/images/eventful-loader.gif"
      alt="loading..."
      width="50"
      style="max-width: 100px"
    />
  </div>
</template>

<script>
export default {
  name: "Loading"
};
</script>

<style>
.loading {
  border-radius: 0;
  z-index: 99999;
  background-color: rgba(0,0,0,0.2);
}
</style>
