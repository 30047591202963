/*=========================================================================================
  File Name: moduleCustomPricing.js
  Description: CustomPricing Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './moduleCustomPricingState.js'
import mutations from './moduleCustomPricingMutations.js'
import actions from './moduleCustomPricingActions.js'
import getters from './moduleCustomPricingGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
