/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_BOOKING_CONFIRMATION_STATES (state, bookingConfirmationStates) {
    state.bookingConfirmationStates = [...bookingConfirmationStates]
  },
  SET_ALL_BOOKING_STATES (state, allBookingStates) {
    state.allBookingStates = [...allBookingStates]
  },
}
