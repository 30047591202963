/*=========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// added so later we can keep breakpoint in sync automatically using this config file
// import tailwindConfig from "../../tailwind.config.js"

const getters = {

  // COMPONENT
  // vx-autosuggest
  // starredPages: state => state.navbarSearchAndPinList.data.filter((page) => page.highlightAction),
  windowBreakPoint: state => {

    // This should be same as tailwind. So, it stays in sync with tailwind utility classes
    if (state.windowWidth >= 1200) return 'xl'
    else if (state.windowWidth >= 992) return 'lg'
    else if (state.windowWidth >= 768) return 'md'
    else if (state.windowWidth >= 576) return 'sm'
    else return 'xs'
  },

  scrollbarTag: state => {
    return state.is_touch_device ? 'div' : 'VuePerfectScrollbar'
  },

  isLoggedIn: state => !!state.auth.token,
  currentUser: (state) => {
    return state.auth.user
  },
  currentVenueId: state => state.auth.currentVenueId,
  customPricings: state => state.customPricings.customPricings,
  customPricingsInCalendar: state => state.customPricings.customPricingsInCalendar,
  currentVenue: state => state.auth.currentVenue,
  contract: state => state.auth.contract,
  percentage: state => state.auth.percentage,
  isSignedVenue: state =>  state.auth.token && (JSON.parse(state.auth.currentVenue).agent_contract_signed_at !== null) && JSON.parse(state.auth.currentVenue).agent_contract_signed_at !== undefined,
  isLoading: state => !!state.dataList.isLoading,
  businessEvents: state => state.businessEvents.businessEvents,
  proposals: state => state.businessEvents.proposals,
  
}

export default getters
