/*=========================================================================================
  File Name: moduleAuthState.js
  Description: Auth Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import auth from '@/auth/authService'
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  status: '',
  token: localStorage.getItem('accessToken') || '',
  user: JSON.parse(localStorage.getItem('currentUser')) || {},
  currentVenue: localStorage.getItem('currentVenue') || {},
  agentVenues: localStorage.getItem('agentVenues') || [],
  currentVenueId: localStorage.getItem('currentVenueId') || '',
  contract: localStorage.getItem('contract') || '',
  percentage: localStorage.getItem('percentage') || ''
}
