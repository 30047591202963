/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_SERVICES(state, services) {
    state.services = services
  },
  UPDATE_SERVICE(state, updatedService) {
    const index = state.services.findIndex(service => service.id === updatedService.id)

    if(index !== -1) {
      state.services.splice(index, 1, updatedService)
    }
  },
  ADD_SERVICE(state, service) {
    state.services.push(service);
  },
  REMOVE_SERVICE(state, deletedService) {
    const index = state.services.findIndex(service => service.id === deletedService.id)

    if(index !== -1) {
      state.services.splice(index, 1)
    }
  },
}
