/*=========================================================================================
  File Name: moduleTodoActions.js
  Description: Todo Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

export default {
  async getQuestions ({ commit },{venueId, params}) {
    try {
      
      const response = await axios.get(`/venues/${venueId}/questions`, { params: params })
      commit('SET_CUSTOMERS_QUESTIONS', response.data.data);

      return response;

    } catch (error) {

      return error.response;
    }
  },
  async answerQuestion({ commit }, {venueId, customerQuestion}) {
    const formData = new FormData();

    if(customerQuestion.answer) formData.append('answer', customerQuestion.answer);
    
    try { 
      const response = await axios.post(`/venues/${venueId}/questions/${customerQuestion.id}/answerQuestion`, formData)
      
      commit('UPDATE_CUSTOMER_QUESTION', response.data.question)
      
      return response;

    } catch (error) {

      return error.response;
    }
  }
  
}