/*=========================================================================================
  File Name: moduleTodoActions.js
  Description: Todo Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

export default {
  getBookingStatesArrayByArrayName ({ commit }, {arrayName}) { // not used anymore
    return new Promise((resolve, reject) => {

      let params = new URLSearchParams();
      params.append('booking_states_array', arrayName);

      axios.get(`/bookingStates/agentStates?${params}`)
        .then((response) => {
          if(arrayName == 'bookingConfirmationStatesAgent') {
            commit('SET_BOOKING_CONFIRMATION_STATES', response.data.bookingStates)
          }
          resolve(response)
        })
      .catch((error) => { 
        console.log('get analysis errer', error) 
        reject(error) 
      })

    })
  },
  getAllBookingStates ({ commit }) { // not used anymore
    return new Promise((resolve, reject) => {

      axios.get(`/bookingStates`)
        .then((response) => {
          commit('SET_ALL_BOOKING_STATES', response.data.bookingStates)
          resolve(response)
        })
      .catch((error) => { 
        console.log('get analysis errer', error) 
        reject(error) 
      })

    })
  },
}
