<template>
    <div v-if="currentBooking != null">

        <vs-popup class="holamundo "  :title="'#'+currentBooking.booking_number+' | '+currentBooking.customer_name" :active.sync="isActive" @close="closePopup">
        <div class="container w-full">

            <div class="flex flex-row w-full items-end justify-between m-0" v-if="!isLeadBooking">
                <div></div>
              <div class="w-full flex justify-end">
                <vs-button icon-pack="feather" icon="icon-file" color="success" type="border" @click="generateReceipt" class="mx-2">{{ $t("buttons.generateReceipt")}}</vs-button>
                <vs-button icon-pack="feather" icon="icon-edit" @click="editBooking" class="mx-2">{{ $t("buttons.edit")}}</vs-button>
              </div>
            </div>

            <hr class="row w-full my-5" style="height: 1px; opacity: 0.2" v-if="!isLeadBooking">


            <div class="vx-row m-0 mb-5 justify-around" v-if="currentBooking.state && !isLeadBooking">
                <div class="vx-col lg:w-1/3 mt-5">
                    <h5 class="mb-3">{{$t('bookingPreview.currentBookingState')}}</h5>
                    <h5 class="rounded-lg p-3 px-5 text-base text-white text-center" :style="{background: currentBooking.state.color}" :color="currentBooking.state.color">
                        {{currentBooking.state.agent_display_name}}
                    </h5>
                </div>
                <div class="vx-col lg:w-2/3 mt-5 pl-10">
                    <h5 class="mb-2 w-full">{{$t('bookingPreview.changeBookingStateTo')}}</h5>
                    <div class="vx-row justify-start" v-if="statesList.length > 0">
                        <vx-tooltip v-for="state in statesList" :key="state.id" :text="state.action_description" class="tooltip-popup">
                            <vs-button class="state-button mt-1 mx-1" :color="state.color" @click="manageStateSubmission(state)">{{state.agent_action}}</vs-button>
                        </vx-tooltip>
                        <vs-button class="state-button mt-1 mx-1" color="danger" @click="canceledPromptActive = true;">{{$t('bookingPreview.cancelBooking')}}</vs-button>
                    </div>
                    <div class="vx-row justify-start" v-else>
                        <vs-button class="state-button mt-1 mx-1" icon-pack="feather" icon="icon-message-circle" @click="changeStateWhatsapp" color="success">
                            {{ $t("bookingPreview.whatsappContactToChangeState")}}
                        </vs-button>
                    </div>
                </div>
                <div class="vx-col w-full my-3" v-if="statesList.length > 0">
                    <vs-alert color="warning" icon="new_releases">
                        {{$t('bookingPreview.updateStateNote')}}
                    </vs-alert>
                </div>
            </div>

            <div class="w-full px-3 py-5" v-if="currentBooking.cancelation_reason && (currentBooking.state_id == 6 || currentBooking.state_id == 19 || currentBooking.state_id == 20 || currentBooking.state_id == 21)">
                <div v-if="currentBooking.state_id == 20">
                    <div class="flex space-x-3">
                        <span class="text-lg">{{$t('bookingPreview.refundAmount')}}:</span>
                        <span>{{ currentBooking.receipt.refund_amount }} {{ $t('SAR') }}</span>
                    </div>
                </div>
                <div class="mt-3">
                    <span class="text-lg">{{$t('bookingPreview.cancelationReason')}}</span>
                </div>
                <div class="px-1">
                    <p>{{ currentBooking.cancelation_reason }}</p>
                </div>
            </div>



            <div class="vx-row m-0 w-full">
                <!-- CUSTOMER INFORMATION -->
                <div class="vx-row w-full sm:flex flex m-0 mt-5">
                    <div class="vx-col p-0 w-full flex flex-wrap justify-between mt-3">
                        <h4 class="opacity-75 text-lg lg:text-2xl"><i class="far fa-user"></i> {{$t('booking.customerName')}}</h4>
                        <h4 class="text-lg lg:text-2xl">{{currentBooking.customer_name}}</h4>
                    </div>
                    <div class="vx-col p-0 w-full flex flex-wrap justify-between mt-3" v-if="!isLeadBooking">
                        <h4 class="opacity-75 text-lg lg:text-2xl"><i class="far fa-mobile"></i> {{$t('booking.customerPhoneNumber')}}</h4>
                        <h4 class="text-lg lg:text-2xl">{{currentBooking.customer_phone_number}}</h4>
                    </div>
                    <div class="vx-col p-0 w-full flex flex-wrap justify-between mt-3" v-if="!isLeadBooking && currentBooking.customer_city">
                        <h4 class="opacity-75 text-lg lg:text-2xl"><i class="far fa-map-pin"></i> {{$t('booking.customer_address')}}</h4>
                        <h4 class="text-lg lg:text-2xl">{{currentBooking.customer_city}}</h4>
                    </div>
                    <div class="vx-col p-0 w-full flex flex-wrap justify-between mt-3" v-if="!isLeadBooking && currentBooking.customer_commercial_registration_number">
                        <h4 class="opacity-75 text-lg lg:text-2xl"><i class="far fa-file"></i> {{$t('booking.customer_commercial_registration_number')}}</h4>
                        <h4 class="text-lg lg:text-2xl">{{currentBooking.customer_commercial_registration_number}}</h4>
                    </div>
                    <div class="vx-col p-0 w-full flex flex-wrap justify-between mt-3" v-if="!isLeadBooking && currentBooking.customer_vat_number">
                        <h4 class="opacity-75 text-lg lg:text-2xl"><i class="far fa-file"></i> {{$t('booking.customer_vat_number')}}</h4>
                        <h4 class="text-lg lg:text-2xl">{{currentBooking.customer_vat_number}}</h4>
                    </div>
                    <vs-textarea v-if="currentBooking.customer_notes" :label="$t('addBookingSidebar.customerNotes')" :value="currentBooking.customer_notes" class="mt-5 w-full" readonly/>
                    <!-- CONTACT BUTTONS -->
                    <div class="flex flex-row p-0 w-full mt-4 justify-between" v-if="!isLeadBooking">
                        <vs-button class="flex-grow mx-1 h-12" v-if="isPhoneScreen" icon-pack="feather" icon="icon-phone" @click="callCustomer" >
                            {{ $t("addBookingSidebar.phone_call")}}
                        </vs-button>
                        <vs-button class="flex-grow mx-1" icon-pack="feather" icon="icon-message-circle" @click="whatsappCustomer"  color="success">
                            {{ $t("addBookingSidebar.whatsapp")}}
                        </vs-button>
                    </div>
                    <!--// CONTACT BUTTONS //-->

                    <div class="vx-row w-full m-0 mt-5 mb-5 opacity-50">
                        <hr class="w-full">
                    </div>
                </div>
                <!--// CUSTOMER INFORMATION //-->


                <!-- BOOKING INFORMATION -->
                <div class="vx-row w-full sm:flex flex m-0">
                    <div class="vx-col p-0 w-full flex flex-wrap justify-between mt-5">
                        <h3 class="opacity-75 text-lg lg:text-2xl"><i class="far fa-solid fa-thumbs-up"></i> {{$t('booking.eventType')}}</h3>
                        <h3 class="text-lg lg:text-2xl">{{currentBooking.event_type ? currentBooking.event_type.arabic_name : ''}}</h3>
                    </div>
                    <div class="vx-col p-0 w-full flex flex-wrap justify-between mt-5">
                        <h3 class="opacity-75 text-lg lg:text-2xl"><i class="far fa-solid fa-calendar-check"></i> {{$t('booking.bookingDate')}}</h3>
                        <h3 class="text-lg lg:text-2xl">{{currentBooking.event_type ? currentBooking.start_date : ''}}</h3>
                    </div>
                    <div class="vx-col p-0 w-full flex flex-wrap justify-between mt-5" v-for="guestInput in currentBooking.event_type.guests_inputs" :key="guestInput.key">
                        <h3 class="opacity-75 text-lg lg:text-2xl"><i class="far fa-solid fa-ballot-check"></i> {{guestInput.name}}</h3>
                        <h3 class="text-lg lg:text-2xl">{{currentBooking[guestInput.key] > 0 ? currentBooking[guestInput.key] : ''}}</h3>
                    </div>

                    <div class="vx-col p-0 w-full flex flex-wrap justify-between mt-5">
                        <h3 class="opacity-75 text-lg lg:text-2xl"><i class="far fa-solid fa-dollar-sign ml-1 mr-1"></i> {{$t('booking.bookingFinalValue')}}</h3>
                        <div>
                            <h3 class="text-danger text-lg lg:text-2xl">
                                {{currentBooking.receipt.local_final_price_without_discount ? currentBooking.receipt.local_final_price_without_discount.toLocaleString() : 0 + ' '}} {{$t("SAR")}}
                            </h3>
                            <div class="vx-col p-0 w-full flex flex-wrap justify-center">
                                <small class="very-small-note">{{$t('vatIncluded')}}</small>
                            </div>
                        </div>
                    </div>
                    <div class="vx-col p-0 w-full flex flex-wrap justify-between mt-3" v-if="currentBooking.state_id == 17">
                        <h3 class="opacity-75 text-success text-lg lg:text-2xl"><i class="far fa-solid fa-check"></i> {{$t('depositPaid')}}</h3>
                    </div>
                    <div class="vx-col p-0 w-full flex flex-wrap sm:justify-between justify-center mt-5" v-if="currentBooking.receipt.discount > 0">
                        <h3 class="opacity-75 sm:mx-0 mx-9 text-success"><i class="far fa-solid fa-dollar-sign ml-1 mr-1"></i> {{$t('booking.bookingFinalValueAfterDiscount', {discount: currentBooking.receipt.discount})}}</h3>
                        <h3 class="text-success text-lg lg:text-2xl sm:mt-1 sm:mx-0 mx-20 mt-3">
                            {{currentBooking.receipt.discounted_price ? currentBooking.receipt.discounted_price.toLocaleString() : 0 + ' '}} {{$t("SAR")}}
                        </h3>
                    </div>
                </div>
                <!--// BOOKING INFORMATION //-->

                <hr class="row w-full mt-5 mb-3" style="height: 1px; opacity: 0.2">

                <!-- PAYMENT INFORMATION -->

                <div class="vx-row w-full sm:flex flex m-0" v-if="!isLeadBooking">
                    <div class="vx-col p-0 w-full mt-5">
                        <h3 class="text-lg lg:text-2xl">{{$t('invoice.paymentDetails.paymentDetails')}}</h3>
                    </div>
                    <div class="vx-col p-0 w-full flex flex-wrap justify-between mt-5">
                        <h3 class="opacity-75 text-lg lg:text-2xl">{{$t('invoice.paymentDetails.collectingFromCustomer')}}</h3>
                        <h3 class="text-lg lg:text-2xl">{{currentBooking.receipt.local_final_price ? currentBooking.receipt.local_final_price.toLocaleString() : 0 + ' '}} {{$t("SAR")}}</h3>
                    </div>
                    <div class="vx-col p-0 w-full flex flex-wrap justify-between mt-5">
                        <h3 class="opacity-75 text-lg lg:text-2xl">{{$t('invoice.paymentDetails.collectedFromEventful')}}</h3>
                        <h3 class="text-lg lg:text-2xl">{{currentBooking.receipt.total_paid_amount_at_qosoor ? currentBooking.receipt.total_paid_amount_at_qosoor.toLocaleString() : 0 + ' '}} {{$t("SAR")}}</h3>
                    </div>
                    <div class="vx-col p-0 w-full flex flex-wrap justify-between mt-5">
                        <h3 class="opacity-75 text-lg lg:text-2xl">{{$t('invoice.paymentDetails.collectedFromPlace')}}</h3>
                        <h3 class="text-lg lg:text-2xl">{{currentBooking.receipt.paid_at_place_amount ? currentBooking.receipt.paid_at_place_amount.toLocaleString() : 0 + ' '}} {{$t("SAR")}}</h3>
                    </div>
                    <div class="vx-col p-0 w-full flex flex-wrap justify-between mt-5">
                        <h3 class="opacity-75 text-lg lg:text-2xl">{{$t('invoice.paymentDetails.remainingToBeCollected')}}</h3>
                        <h3 class="text-lg lg:text-2xl">{{currentBooking.receipt.remaining_amount ? currentBooking.receipt.remaining_amount.toLocaleString() : 0 + ' '}} {{$t("SAR")}}</h3>
                    </div>
                    <div class="vx-col p-0 w-full mt-5">
                        <h3 class="opacity-75 text-lg lg:text-2xl">{{$t('invoice.paymentDetails.paymentNotesByAgent')}}</h3>
                        <p class="text-md mt-3">
                            {{ currentBooking.payment_notes_by_agent }}
                        </p>
                    </div>
                </div>

                <!--// PAYMENT INFORMATION //-->

                <!-- BOOKING DETAILS -->
                <div class="vx-row w-full sm:flex flex m-0 mt-5">
                    <vs-collapse type="margin" class="p-0 w-full">
                        <!-- BOOKING OPTION -->
                        <h3>{{$t('booking.bookingOptionTitle')}}</h3>
                        <vs-collapse-item icon-arrow="arrow_downward" v-if="currentBooking.receipt.booking_option" :open="isLeadBooking">
                            <div slot="header">
                                <div class="vx-col p-0 w-full flex flex-wrap lg:justify-between justify-center pr-5">
                                    <h3 class="opacity-75 text-lg lg:text-2xl" :class="{'mt-3': currentBooking.receipt.receipt_taxable || currentBooking.receipt.qosoor_discount > 0}">| {{currentBooking.receipt.booking_option.name}}
                                        <span class="details-text truncate">{{$t('booking.showDetails')}}</span>
                                    </h3>
                                    <div class="flex flex-wrap lg:mt-1 lg:justify-end justify-center mt-2">
                                        <h3 class="text-danger text-lg lg:text-2xl">
                                            {{currentBooking.receipt.total_price_with_tax_for_agent ? currentBooking.receipt.total_price_with_tax_for_agent.toLocaleString() : 0 + ' '}} {{$t("SAR")}}
                                        </h3>
                                        <div class="vx-col p-0 w-full flex flex-wrap lg:justify-end justify-center">
                                            <small v-if="currentBooking.receipt.receipt_taxable" class="very-small-note">{{$t('vatIncluded')}}</small>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-wrap mb-5" v-if="currentBooking.receipt.booking_option">
                                <div class="vx-col w-full flex flex-wrap">

                                    <div v-if="currentBooking.event_type" class="w-full">
                                      <div class="vx-col w-full flex flex-wrap mt-3"  v-for="guestInput in currentBooking.event_type.guests_inputs" :key="guestInput.key">
                                          <h5 class="mt-2 w-1/2" v-if="currentBooking[guestInput.key] > 0">{{guestInput.name ? guestInput.name : $t('booking.guestsCount')}}:</h5>
                                          <vs-input v-if="currentBooking[guestInput.key] > 0" class="w-1/2" v-model="currentBooking[guestInput.key]" readonly/>
                                      </div>
                                    </div>
                                    <div class="w-full">
                                        <vs-input readonly :label="$t('booking.bookingOption.description')"  v-model="currentBooking.receipt.booking_option.description" class="w-full mt-5" />
                                        <vs-input readonly :label="$t('booking.bookingOption.priceNoVat')"  v-model="currentBooking.receipt.booking_option.price" class="w-full mt-5" />
                                        <vs-textarea readonly :label="$t('booking.bookingOption.detailedDescription')" height="150px" v-model="currentBooking.receipt.booking_option.detailed_description" class="w-full mt-5"/>
                                    </div>
                                </div>
                            </div>
                        </vs-collapse-item>
                        <!-- // BOOKING OPTION // -->

                        <!-- addons collapse -->
                        <div v-if="currentBooking.receipt.booking_copy">
                            <h3 class="m-5" v-if="currentBooking.receipt.booking_copy.internal_services.length > 0">الإضافات:</h3>
                            <vs-collapse-item icon-arrow="arrow_downward" v-for="internalService in currentBooking.receipt.booking_copy.internal_services" :key="internalService.id">
                                <div slot="header">
                                    <div class="vx-col p-0 w-full flex flex-wrap xl:justify-between justify-center pr-5">
                                        <div class="flex flex-wrap lg:mt-1 lg:justify-end justify-center mt-2">
                                            <h3 class="opacity-75 text-lg lg:text-2xl mt-3">| {{internalService.name}}</h3>
                                            <span class="details-text truncate lg:mt-5 mt-3">{{$t('booking.showDetails')}}</span>
                                        </div>
                                        <div class="flex flex-wrap lg:mt-1 lg:justify-end justify-center mt-2">
                                            <h3 class="text-danger">
                                                <h3 class="text-danger text-lg lg:text-2xl">{{internalService.final_price.toLocaleString() + ' '}} {{$t("SAR")}} </h3>
                                            </h3>
                                            <div class="vx-col p-0 w-full flex flex-wrap lg:justify-end justify-center">
                                                <small v-if="internalService.is_taxable" class="very-small-note">{{$t('vatIncluded')}}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="vx-col w-full">
                                    <div class="flex flex-wrap">
                                        <div class="vx-col w-full flex flex-wrap">
                                            <h4 class="mt-2 w-1/4">{{$t('booking.service.serviceName')}}:</h4>
                                            <vs-input readonly v-model="internalService.name" class="w-3/4"/>
                                        </div>
                                        <div class="vx-col w-full flex flex-wrap mt-4">
                                            <vs-input readonly :label="$t('booking.service.description')" v-if="internalService.description" v-model="internalService.description" class="w-full px-1"/>
                                            <vs-input readonly :label="$t('booking.service.price')" :value="internalService.final_price - internalService.tax" class="w-1/2 px-1"/>
                                            <vs-input readonly :label="$t('booking.service.taxAmount')" v-model="internalService.tax" class="w-1/2 px-1"/>
                                        </div>
                                    </div>
                                </div>
                            </vs-collapse-item>
                        </div>
                        <!-- // addons collapse // -->
                        <!-- <p v-if="currentBooking.receipt && currentBooking.receipt.external_booking_services.length > 0"> يوجد خدمات خارجية لهذا الحجز</p> -->

                        <div class="vx-row w-full m-0 mt-10 mb-5 opacity-50" v-if="!isLeadBooking">
                            <hr class="w-full">
                        </div>
                        <vs-collapse-item icon-arrow="arrow_downward" class="mb-20" v-if="!isLeadBooking">
                            <div slot="header">
                                <div class="vx-col p-0 w-full flex flex-wrap justify-between pr-5">
                                    <h3 class="opacity-75">| {{$t('invoice.invoice')}}</h3>
                                    <vs-chip :color="currentBooking.source_color" class="product-order-status"><p class="font-medium truncate">{{currentBooking.source}}</p></vs-chip>
                                </div>
                            </div>
                            <div class="vx-col w-full">
                                <h4>{{$t('invoice.general')}}</h4>
                                <div class="flex flex-wrap">
                                    <div class="vx-row w-full ml-1">
                                        <div class="vx-col w-full mt-4 flex flex-wrap">
                                            <h5 class="inline">{{$t('invoice.totalPrice')}}:</h5>
                                            <h5 class="inline ml-10">
                                                {{currentBooking.receipt.final_price_for_agent ? currentBooking.receipt.final_price_for_agent.toLocaleString() : 0 + ' '}} {{$t("SAR")}}
                                            </h5>
                                        </div>
                                        <div class="vx-col w-full lg:w-1/2 mt-2 flex flex-wrap">
                                            <h5 class="inline">{{$t('invoice.eventfulPercentage')}}:</h5>
                                            <h5 class="inline ml-5">{{currentBooking.receipt.final_percentage_qosoor_commission}} %</h5>
                                        </div>
                                        <div class="vx-col w-full lg:w-1/2 mt-2 flex flex-wrap">
                                            <h5 class="inline">{{$t('invoice.yourNet')}}:</h5>
                                            <h5 class="inline ml-10">{{currentBooking.receipt.cost_of_goods_sold ? currentBooking.receipt.cost_of_goods_sold.toLocaleString() : 0 + ' '}} {{$t("SAR")}}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="vx-row w-full m-0 mt-5 mb-5 opacity-50">
                                    <hr class="w-full">
                                </div>
                                <h4>{{$t('invoice.details')}}</h4>
                                <div class="flex flex-wrap">
                                    <div class="vx-row w-full ml-1">
                                        <div class="vx-col w-full mt-3">
                                            <h4>| {{$t('invoice.option.optionPrice')}}</h4>
                                        </div>
                                        <div class="vx-col w-full mt-2 flex flex-wrap">
                                            <h5 class="inline">{{$t('invoice.option.beforeVat')}}:</h5>
                                            <h5 class="inline lg:ml-3 ml-1">{{currentBooking.receipt.total_price ? currentBooking.receipt.total_price.toLocaleString() : 0 + ' '}} {{$t("SAR")}}</h5>
                                        </div>
                                        <div class="vx-col w-full mt-2 flex flex-wrap">
                                            <h5 class="inline">{{$t('invoice.option.vat')}}:</h5>
                                            <h5 class="inline lg:ml-3 ml-1">{{currentBooking.receipt.tax_without_discount ? currentBooking.receipt.tax_without_discount.toLocaleString() : 0 + ' '}} {{$t("SAR")}}</h5>
                                        </div>
                                        <div class="vx-col w-full mt-2 flex flex-wrap">
                                            <h5 class="inline">{{$t('invoice.option.afterVat')}}:</h5>
                                            <h5 class="inline lg:ml-3 ml-1">{{currentBooking.receipt.total_price_with_tax ? currentBooking.receipt.total_price_with_tax.toLocaleString() : 0 + ' '}} {{$t("SAR")}}</h5>
                                        </div>
                                        <div v-if="currentBooking.receipt.discount > 0">
                                            <div class="vx-col w-full mt-2 flex flex-wrap" v-if="currentBooking.receipt.agent_manual_discount > 0">
                                                <h5 class="inline">{{$t('invoice.option.discountBy')}} {{ currentVenue.arabic_name }}:</h5>
                                                <h5 class="inline lg:ml-3 ml-1">{{currentBooking.receipt.agent_manual_discount.toLocaleString()}}</h5>
                                            </div>
                                            
                                            <div class="vx-col w-full mt-2 flex flex-wrap" v-if="currentBooking.receipt.manual_discount > 0">
                                                <h5 class="inline">{{$t('invoice.option.discountBy')}} {{ $t('eventful') }}:</h5>
                                                <h5 class="inline lg:ml-3 ml-1">{{currentBooking.receipt.manual_discount.toLocaleString()}} {{$t("SAR")}}</h5>
                                            </div>

                                            <div class="vx-col w-full mt-2 flex flex-wrap" v-if="(currentBooking.receipt.discount - currentBooking.receipt.agent_manual_discount) > 0 && (currentBooking.receipt.manual_discount == null || currentBooking.receipt.manual_discount == 0)">
                                                <h5 class="inline">{{$t('invoice.option.discountBy')}} {{ $t('eventful') }}:</h5>
                                                <h5 class="inline lg:ml-3 ml-1">{{(currentBooking.receipt.discount - currentBooking.receipt.agent_manual_discount).toLocaleString()}} {{$t("SAR")}}</h5>
                                            </div>


                                            <div class="vx-col w-full mt-2 flex flex-wrap">
                                                <h5 class="inline">{{$t('invoice.option.priceAfterDiscount')}}:</h5>
                                                <h5 class="inline lg:ml-3 ml-1">{{(currentBooking.receipt.discounted_price).toLocaleString()}} {{$t("SAR")}}</h5>
                                            </div>

                                        </div>
                                        

                                        <!-- 
                                            if discount
                                                price after discount
                                                agent discount
                                                eventful duscount


                                         -->
                                        
                                        <!-- <div class="vx-col w-full mt-2 flex flex-wrap" v-if="currentBooking.receipt.qosoor_discount > 0">
                                            <h5 class="inline">{{$t('invoice.option.discountBy')}} {{ $t('eventful') }}:</h5>
                                            <h5 class="inline lg:ml-3 ml-1">{{currentBooking.receipt.qosoor_discount.toLocaleString()}}</h5>
                                        </div> -->
                                        <!-- <div class="vx-col w-full mt-2 flex flex-wrap" v-if="currentBooking.receipt.discount > 0">
                                            <h5 class="inline">{{$t('invoice.option.discountSource')}}:</h5>
                                            <h5 class="inline lg:ml-3 ml-1">{{currentBooking.receipt.discount > 0 ? $t('invoice.option.eventful') : currentVenue.arabic_name}}</h5>
                                        </div> -->
                                        <!-- <div class="vx-col w-full mt-2 flex flex-wrap" v-if="currentBooking.receipt.discount > 0">
                                            <h5 class="inline">{{$t('invoice.option.priceAfterDiscount')}}:</h5>
                                            <h5 class="inline lg:ml-3 ml-1">{{currentBooking.receipt.total_price_with_tax ? currentBooking.receipt.total_price_with_tax.toLocaleString() : 0 + ' '}} {{$t("SAR")}}</h5>
                                        </div> -->
                                    </div>
                                    <div class="vx-row w-full m-0 my-5 justify-center opacity-50">
                                        <hr class="w-3/4">
                                    </div>
                                    <div class="vx-row w-full ml-1">
                                        <div class="vx-col w-full mt-3">
                                            <h4>| {{$t('invoice.services.servicesPrice')}}</h4>
                                        </div>
                                        <div class="vx-col w-full mt-2 flex flex-wrap">
                                            <h5 class="inline">{{$t('invoice.services.beforeVat')}}:</h5>
                                            <h5 class="inline lg:ml-3 ml-1">{{currentBooking.receipt.internal_services_total_price_without_tax ? currentBooking.receipt.internal_services_total_price_without_tax.toLocaleString() : 0 + ' '}} {{$t("SAR")}}</h5>
                                        </div>
                                        <div class="vx-col w-full mt-2 flex flex-wrap">
                                            <h5 class="inline">{{$t('invoice.services.vat')}}:</h5>
                                            <h5 class="inline lg:ml-3 ml-1">{{currentBooking.receipt.total_tax_of_internal_services ? currentBooking.receipt.total_tax_of_internal_services.toLocaleString() : 0 + ' '}} {{$t("SAR")}}</h5>
                                        </div>
                                        <div class="vx-col w-full mt-2 flex flex-wrap">
                                            <h5 class="inline">{{$t('invoice.services.afterVat')}}:</h5>
                                            <h5 class="inline lg:ml-3 ml-1">{{currentBooking.receipt.services_total_price ? currentBooking.receipt.services_total_price.toLocaleString() : 0 + ' '}} {{$t("SAR")}}</h5>
                                        </div>
                                    </div>
                                    <!-- <div class="vx-row w-full m-0 my-5 justify-center opacity-50">
                                        <hr class="w-3/4">
                                    </div>
                                    <div class="vx-row w-full ml-1">
                                        <div class="vx-col w-full mt-3">
                                            <h4>| {{$t('invoice.paymentDetails.paymentDetails')}}</h4>
                                        </div>
                                        <div class="vx-col w-full mt-2 flex flex-wrap">
                                            <h5 class="inline">{{$t('invoice.paymentDetails.collectingFromCustomer')}}:</h5>
                                            <h5 class="inline lg:ml-3 ml-1">{{currentBooking.receipt.local_final_price ? currentBooking.receipt.local_final_price.toLocaleString() : 0 + ' '}} {{$t("SAR")}}</h5>
                                        </div>
                                        <div class="vx-col w-full mt-2 flex flex-wrap">
                                            <h5 class="inline">{{$t('invoice.paymentDetails.collectedFromEventful')}}:</h5>
                                            <h5 class="inline lg:ml-3 ml-1">{{currentBooking.receipt.paid_amount_at_qosoor ? currentBooking.receipt.paid_amount_at_qosoor.toLocaleString() : 0 + ' '}} {{$t("SAR")}}</h5>
                                        </div>
                                        <div class="vx-col w-full mt-2 flex flex-wrap">
                                            <h5 class="inline">{{$t('invoice.paymentDetails.collectedFromPlace')}}:</h5>
                                            <h5 class="inline lg:ml-3 ml-1">{{currentBooking.receipt.paid_at_place_amount ? currentBooking.receipt.paid_at_place_amount.toLocaleString() : 0 + ' '}} {{$t("SAR")}}</h5>
                                        </div>
                                        <div class="vx-col w-full mt-2 flex flex-wrap">
                                            <h5 class="inline">{{$t('invoice.paymentDetails.remainingToBeCollected')}}:</h5>
                                            <h5 class="inline lg:ml-3 ml-1">{{currentBooking.receipt.local_remaining_amount ? currentBooking.receipt.local_remaining_amount.toLocaleString() : 0 + ' '}} {{$t("SAR")}}</h5>
                                        </div>
                                    </div> -->
                                </div>
                                <!--// RECEIPT //-->

                                <!-- RECEIPT DOCUMENTS -->
                                <div class="vx-row w-full mt-10 p-2 w-full">
                                    <div class="vx-row m-0 w-full">
                                        <h3 class="my-3 opacity-75" >| {{$t('addBookingSidebar.exportedReceipts')}}</h3>
                                    </div>
                                    <div class="grid grid-cols-3 gab-4">
                                        <div v-for="doc in receiptDocuments" :key="doc.id"  class="flex p-4 m-2 rounded-lg justify-center items-center vx-card" >
                                            <h5 class="m-2">{{doc.created_at}}</h5>
                                            <vs-button @click="showReceiptDocButtonClicked(doc.booking_copy.booking_number, doc.id)" icon-pack="feather" icon="icon-eye" class="p-2" style="background: #673ab7 !important"></vs-button>
                                        </div>
                                    </div>
                                </div>
                                <!--// RECEIPT DOCUMENTS //-->
                            </div>
                        </vs-collapse-item>
                    </vs-collapse>
                </div>
                <!--// BOOKING DETAILS //-->
            </div>
        </div>
        <vs-prompt color="danger" :title="$t('addBookingSidebar.cancelBooking')" class="export-options" :buttons-hidden="true" :active.sync="canceledPromptActive">
                <!-- <div class="vx-row mx-1 mb-2 required">
                    <label class="v-select-label">{{$t('booking.writeCancelationReason')}}</label>
                </div>
                <div class="flex w-full">
                    <vs-input v-model="otherCancelationReason" class="w-full"/>
                </div> -->
                <div v-if="currentBooking.receipt.paid_amount > 0">
                    <div>
                        <span>{{$t('bookingPreview.refundAmountNote', {amount: currentBooking.receipt.paid_amount})}}</span>
                    </div>
                    <div class="flex flex-col space-y-3 my-3">
                        <vs-radio color="primary" v-model="cancelPromptRefundSelection" vs-name="cancelPromptRefundSelection" vs-value="without-refund">{{$t('bookingPreview.cancelWithoutRefund')}}</vs-radio>
                        <vs-radio color="primary" v-model="cancelPromptRefundSelection" vs-name="cancelPromptRefundSelection" vs-value="with-refund">{{$t('bookingPreview.cancelWithRefund')}}</vs-radio>
                    </div>
                    <div v-if="cancelPromptRefundSelection == 'without-refund'">
                        <vs-alert color="warning" icon="new_releases" class="my-3">
                            {{$t('bookingPreview.cancelationNotificationNote')}}
                        </vs-alert>
                        <div class="flex justify-end">
                            <vs-button color="danger" @click="cancelBooking('canceled_without_refund')">{{$t('bookingPreview.cancelWithoutRefund')}}</vs-button>
                        </div>
                    </div>
                    <div v-else>
                        <div class="my-2">
                            <div class="vx-row mx-1 mb-2 required">
                                <label class="v-select-label">{{$t('bookingPreview.refundAmountFancy')}}</label>
                            </div>
                            <vs-input v-model="refundAmount" class="w-full"/>
                        </div>
                        <vs-alert color="warning" icon="new_releases" class="my-3">
                            {{$t('bookingPreview.cancelationNotificationNote')}}
                        </vs-alert>
                        <div class="flex justify-end">
                            <vs-button color="danger" @click="cancelBooking('canceled_with_refund')">{{$t('bookingPreview.cancelWithRefund')}}</vs-button>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <vs-alert color="warning" icon="new_releases" class="my-3">
                        {{$t('bookingPreview.cancelationNotificationNote')}}
                    </vs-alert>
                    <div class="flex justify-end">
                        <vs-button color="danger" @click="cancelBooking('cancelled')">{{$t('bookingPreview.cancelBooking')}}</vs-button>
                    </div>
                </div>
        </vs-prompt>
        </vs-popup>
    </div>
</template>

<script>
import axios from '@/axios'
import vSelect from 'vue-select'
export default {
    name: 'booking-preview',
    props: {
        bookingPreviewActive: {
            type: Boolean,
            defult: false
        },
        currentBooking: {
            type: Object,
            defult: null
        },
        isLeadBooking: {
            type: Boolean,
            defult: false
        },
    },
    components: {
        vSelect
    },
    data () {
        return {
            data: null,
            canceledPromptActive: false,
            clickedState: null,
            receiptDocuments: [],
            statesList: [],
            otherCancelationReason: null,
            cancelPromptRefundSelection: 'without-refund',
            refundAmount: 0,
        }
    },
    computed: {
        isActive: {
            get() {
                return this.bookingPreviewActive
            },
            set(newState) {
                return newState
            }
        },
        isPhoneScreen() {
            return /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        },
        currentVenueId () {
            return this.$store.getters.currentVenueId
        },
        currentVenue () {
        return this.$store.getters.currentVenue? JSON.parse(this.$store.getters.currentVenue): {};
    },
    },
    methods: {
        getReceiptDocuments(booking) {
          axios.get(`/venues/${booking.venue_id}/bookings/${booking.id}/receiptDocuments`)
              .then((response) => {
                  this.receiptDocuments = response.data.data
              })
              .catch((error) => { console.log(error) })
        },
        editBooking() {
            this.$emit('update:bookingPreviewActive', false);
            this.$emit('openEditBookingPopUp', this.currentBooking);
        },

        closePopup() {
            this.removeBookingIdQueryParameter();
            this.$emit('update:bookingPreviewActive', false);
        },

        callCustomer() {
            let customer_phone = this.currentBooking.customer_phone_number;
            window.open(`tel:+${ customer_phone }`);
        },

        whatsappCustomer() {
            let customer_name = this.currentBooking.customer_name;
            let customer_phone = this.currentBooking.customer_phone_number;
            let venue_name = JSON.parse(this.$store.getters.currentVenue).arabic_name;

            window.open('https://wa.me/'+customer_phone+'?text=أهلا%20'+customer_name+'%20بخصوص%20حجزك%20لـ '+venue_name+'', '_blank');
        },
        changeStateWhatsapp() {
            let booking_number = this.currentBooking.booking_number;
            let venue_name = JSON.parse(this.$store.getters.currentVenue).arabic_name;

            window.open(`https://wa.me?text=أرغب%20في%20تغيير%20حالة%20الحجز%20رقم%20${booking_number}%20في%20${venue_name}`, '_blank');
        },
        updateBookingState() {
            if(this.clickedState == null) return;
            if(this.clickedState == 6 || this.clickedState == 19 || this.clickedState == 20 || this.clickedState == 21) {
                if(!this.otherCancelationReason){
                    this.canceledPromptActive = true;
                    this.$vs.notify({
                        color:'danger',
                        title: this.$t('error'),
                        text: 'تأكد من كتابة سبب إلغاء الحجز'
                    })
                    return;
                }
            }
            let cancelationReason = this.otherCancelationReason ? this.otherCancelationReason:  this.currentBooking.cancelation_reason
            this.error = null;
            this.$store.dispatch('loader/loaderOn')
            this.$store.dispatch('dataList/updateReceipt', {venueId:this.currentVenueId, receipt:this.currentBooking.receipt, stateId: this.clickedState, cancelationReason: cancelationReason})
                .then(e=>{
                    let response = Array.isArray(e)? e[0].data:e.data;
                    this.$emit('updateCurrentBooking', response.data.booking);
                    this.$vs.notify({
                        color:'success',
                        title: this.$t('editsDone'),
                        text:response.success_message || 'تم تعديل بيانات الفاتورة بنجاح'
                    })
                })
                .catch(function(error) {
                    this.$vs.notify({
                        time: 8000,
                        color:'danger',
                        title: this.$t('error'),
                        text: error.response.data.error_message ||'عذرًا لم نستطع تعديل حجز لوجود خطأ ما، اذا تكرر الخطأ يُرجى التواصل مع الادارة، وشكرًا'
                    })
                }.bind(this))
                .finally(()=>{this.$store.dispatch('loader/loaderOff')},this);
        },
        manageStateSubmission(state) {
            let state_id = state.id
            this.clickedState = state_id;
            if(state_id == 6 || state_id == 19 || state_id == 20 || state_id == 21) {
                this.canceledPromptActive = true; // open the prompt
                return;
            }
            this.updateBookingState();

        },
        async cancelBooking(cancel_type = 'cancelled') {
            if(cancel_type == 'canceled_with_refund') {
                if(!this.refundAmount || this.refundAmount == 0) {
                    this.$vs.notify({
                        color:'danger',
                        type: 'error',
                        text: this.$t('bookingPreview.refundAmountRequired')
                    });
                    return;
                }
            }
            let data = {
                state_name: cancel_type,
            }
            
            if(cancel_type == 'canceled_with_refund') data.refund_amount = this.refundAmount;

            const response = await this.$store.dispatch('dataList/cancelBooking', {venue_id:this.currentVenueId, booking_id:this.currentBooking.id, data: data})
            if(response.status == 200) {
                this.canceledPromptActive = false;
                this.closePopup();
            }
            
        },
        showReceiptDocButtonClicked(bookingNumber, docId) {
          const baseUrl = process.env.VUE_APP_IMAGES_BASE_URL
          window.open(`${baseUrl}/${bookingNumber}/receiptDocuments/${docId}`, '_blank');
        },
        async generateReceipt() {
            await axios.post(`/venues/${this.currentVenueId}/bookings/${this.currentBooking.id}/generateReceiptDocument`)
                .then((response) => {
                    this.getReceiptDocuments(this.currentBooking);
                    this.showReceiptDocButtonClicked(this.currentBooking.booking_number, response.data.receipt_document.id);
                })
                .catch((error) => { console.log(error) })
        }
    },
    watch: {
        currentBooking(newVal) {
            if(!this.isLeadBooking) {
                if(newVal.id) this.getReceiptDocuments(newVal);
                if(newVal.cancelation_reason) {
                    if(!(newVal.cancelation_reason == 'unavailable_date' || newVal.cancelation_reason == 'wrong_count' || newVal.cancelation_reason == 'other')) {
                        this.otherCancelationReason = newVal.cancelation_reason;
                    }
                }
                if(newVal && newVal.state) {
                    if(newVal.added_by == 'agent') this.statesList = newVal.state.other_next_states;
                    else this.statesList = newVal.state.next_states;
                }
                this.refundAmount = newVal.receipt.paid_amount;
            } else {
                this.currentBooking.customer_name = newVal.customer_name ? newVal.customer_name.substring(0, 2)+"*****" : '';
            }
        },
    }
}
</script>

<style lang="css">
.export-options {
    z-index: 10000000 !important;
}
.details-text {
    font-size: 0.8rem;
    margin-left: 5px;
    font-weight: lighter;
    text-decoration: underline;
    color: #FF9F43;
}
.details-text:hover {
    transition: 300ms;
    color: #ffc793;
}
</style>
