/*=========================================================================================
  File Name: moduleTodoActions.js
  Description: Todo Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

export default {
  getCustomPricings ({ commit }, {venueId, optionId}) { // not used anymore
    return new Promise((resolve, reject) => {
      

      axios.get(`/venues/${venueId}/bookingOption/${optionId}/customPricings`)
        .then((response) => {
          commit('SET_CUSTOM_PRICINGS', response.data.customPricings)
          resolve(response)
        })
        .catch((error) => { 
          console.log(error, "from store :(", error)
          reject(error) 
        })
    })
  },
  getNonSeasonalCustomPricing ({ commit }, {venueId, optionId}) {
    return new Promise((resolve, reject) => {
      

      axios.get(`/venues/${venueId}/bookingOption/${optionId}/customPricings/non-seasonal`)
        .then((response) => {
          commit('SET_NON_SEASONAL_CUSTOM_PRICING', response.data.customPricing)
          resolve(response)
        })
        .catch((error) => { 
          console.log(error, "from store :(", error)
          reject(error) 
        })
    })
  },
  getSeasonalCustomPricings ({ commit }, {venueId, optionId}) {
    return new Promise((resolve, reject) => {
      

      axios.get(`/venues/${venueId}/bookingOption/${optionId}/customPricings/seasonal`)
        .then((response) => {
          commit('SET_SEASONAL_CUSTOM_PRICINGS', response.data.customPricings)
          resolve(response)
        })
        .catch((error) => { 
          console.log(error, "from store :(", error)
          reject(error) 
        })
    })
  },
  updateCustomPricing ({ commit }, {venueId, optionId, pricingId, request}) {
    return new Promise((resolve, reject) => {

      axios.put(`/venues/${venueId}/bookingOption/${optionId}/customPricings/${pricingId}`, {...request} )
        .then((response) => {
          this.dispatch('customPricings/getNonSeasonalCustomPricing', {venueId, optionId});
          this.dispatch('customPricings/getSeasonalCustomPricings', {venueId, optionId});
          resolve(response)
        })
        .catch((error) => { 
          console.log(error, "from store :(", error)
          reject(error) 
        })
    })
  },

  addCustomPricing ({ commit }, {venueId, optionId, pricingId, request}) {
    return new Promise((resolve, reject) => {

      axios.post(`/venues/${venueId}/bookingOption/${optionId}/customPricings`, {...request} )
        .then((response) => {
          this.dispatch('customPricings/getNonSeasonalCustomPricing', {venueId, optionId});
          this.dispatch('customPricings/getSeasonalCustomPricings', {venueId, optionId});
          resolve(response)
        })
        .catch((error) => { 
          console.log(error, "from store :(", error)
          reject(error) 
        })
    })
  },

  deleteCustomPricing ({ commit }, {venueId, optionId, pricingId}) {
    return new Promise((resolve, reject) => {
      axios.delete(`/venues/${venueId}/bookingOption/${optionId}/customPricings/${pricingId}`)
        .then((response) => {
          this.dispatch('customPricings/getNonSeasonalCustomPricing', {venueId, optionId});
          this.dispatch('customPricings/getSeasonalCustomPricings', {venueId, optionId});
          resolve(response)
        })
        .catch((error) => { 
          console.log(error, "from store :(", error)
          reject(error) 
        })
    })
  },
  
  getCustomPricingsForCalendar ({ commit }, {venueId, optionId, request}) {
    return new Promise((resolve, reject) => {
      
      let params = new URLSearchParams();

        params.append('start_date', request.start_date);
        params.append('end_date', request.end_date);
        params.append('men_count', request.men_count);
        params.append('women_count', request.women_count);
        params.append('people_count', request.people_count);
        
      axios.get(`/venues/${venueId}/bookingOption/${optionId}/customPricings/calendar?${params.toString()}`)
        .then((response) => {
          
          commit('SET_CUSTOM_PRICINGS_FOR_CALENDAR', response.data.pricesInCalendar)
          resolve(response)
        })
        .catch((error) => { 
          console.log(error, "from store :(", error)
          reject(error) 
        })
    })
  },

  getUnappliedPricings ({ commit }, {venueId, optionId, request}) {
    return new Promise((resolve, reject) => {
      
      let params = new URLSearchParams();

        params.append('date', request.date);
        params.append('men_count', request.men_count);
        params.append('women_count', request.women_count);
        params.append('people_count', request.people_count);
        
      axios.get(`/venues/${venueId}/bookingOption/${optionId}/customPricings/unappliedPricings?${params.toString()}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { 
          console.log(error, "from store :(", error)
          reject(error) 
        })
    })
  },



  
}
