export default {
    install (Vue) {
        // this function adds booking_id to query parameter when a booking preview pop up is opened
        Vue.prototype.addBookingIdQueryParameter = function(bookingId) {
            let agentVenues = this.$store.state.auth.agentVenues != 'undefined' ? typeof(this.$store.state.auth.agentVenues) == 'string' ? JSON.parse(this.$store.state.auth.agentVenues) : this.$store.state.auth.agentVenues : [];
            let currentVenueId = this.$store.getters.currentVenueId;
            let currentVenue = JSON.parse(this.$store.getters.currentVenue);
            let venueInUrl = this.$router.history.current.params.id;
            if(currentVenueId != venueInUrl) { // the venue in url and the current venue are not the same
                // switch to the venue in url
                let foundVenue = agentVenues.length > 0 ? agentVenues.find(venue => venue.id == this.$router.history.current.params.id) : currentVenue;
                this.$store.dispatch('auth/changeVenue', foundVenue);
            }

            if(!this.$router.history.current.query.booking_id) // if query parameter does not have booking_id
                this.$router.replace({'query': {booking_id: bookingId}});
        };

        // this function removes booking_id to query parameter when a booking preview pop up is closed
        Vue.prototype.removeBookingIdQueryParameter = function() {
            if(this.$router.history.current.query.booking_id) {
                let q = JSON.parse(JSON.stringify(this.$router.history.current.query)) // clone the query cuz normal assign is causing duplication error
                delete q.booking_id // delete booking_id query only (url might have other queries)
                this.$router.replace({'query': q});
            }
        };


    }
  }
