/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  RUN_LOADING (state){
    state.isLoading = true
  },
  STOP_LOADING (state){
    state.isLoading = false
  },
  ADD_ITEM (state, item) {
    state.bookings.unshift(item)
  },
  ADD_RECEIPT (state, receipt) {
    state.receipt = receipt;
  },
  SET_PRODUCTS (state, bookings) {
    state.bookings = bookings
  },
  SET_UPCOMING_BOOKINGS (state, upcomingBookings) {
    state.upcomingBookings = upcomingBookings
  },
  SET_AWAITING_PLACE_ACTION_BOOKINGS (state, awaitingPlaceActionBookings) {
    state.awaitingPlaceActionBookings = awaitingPlaceActionBookings
  },
  SET_AWAITING_CUSTOMER_ACTION_BOOKINGS (state, awaitingCustomerActionBookings) {
    state.awaitingCustomerActionBookings = awaitingCustomerActionBookings
  },
  SET_LAST_ADDED_BOOKINGS (state, lastAddedBookings) {
    state.lastAddedBookings = lastAddedBookings
  },
  SET_CANCELLED_BOOKINGS (state, cancelledBookings) {
    state.cancelledBookings = cancelledBookings
  },
  SET_ENDED_BOOKINGS (state, endedBookings) {
    state.endedBookings = endedBookings
  },
  SET_TOTALS (state, totals) {
    state.totals = totals
  },
  SET_BOOKINGS (state, bookings) {
    state.bookings = bookings
  },
  SET_BOOKING (state, booking) {
    state.booking = booking
  },
  SET_PAGES (state, pages){
    state.pages = pages
  },
  // SET_LABELS(state, labels) {
  //   state.eventLabels = labels
  // },
  UPDATE_PRODUCT (state, booking) {
    if(state.bookings.length > 0) {
      let bookingIndex = state.bookings.findIndex((p) => p.id === booking.id)
      Object.assign(state.bookings[bookingIndex], booking ? booking : []);
    }
  },
  REMOVE_ITEM (state, itemId) {
    const ItemIndex = state.bookings.findIndex((p) => p.id === itemId)
    state.bookings.splice(ItemIndex, 1)
  },
  
  SET_BOOKING_STATES_LIST(state, statesList){
    state.bookingStates = statesList
  },
  SET_BOOKING_OPTIONS_LIST(state, optionsList){
    state.bookingOptionsList = optionsList
  },
  SET_BOOKING_OPTION(state, bookingOption) {
    state.bookingOption = bookingOption
  },
  SET_EVENT_TYPES_LIST(state, eventTypesList){
    state.eventTypesList = eventTypesList
  },
  SET_VENUE_ROOMS_LIST(state, roomsList){
    state.venueRoomsList = roomsList
  },
  SET_SELECTED_OPTION_SERVICES_LIST(state, selectedOptionServicesList){
    state.selectedOptionServicesList = selectedOptionServicesList
  },
  UPDATE_BOOKING_IN_ALL_LISTS_OF_BOOKINGS(state, updatedBooking) {
    const index = state.bookings.findIndex(booking => booking.id === updatedBooking.id)
    if(index !== -1) {
        state.bookings.splice(index, 1, updatedBooking)
    }

    // TODO: add the other lists upcomingBookings, awaitingPlaceActionBookings, etc
  }
}
