<!-- TODO: Delete this file -->
<template>
    <div v-if="currentBookingOption != null">

        <vs-popup class="holamundo"  :title="actionType == 'addBookingOption' ? $t('home.editBookingOptionForm.addBookingOption') : actionType == 'editBookingOption' ? $t('home.editBookingOptionForm.editBookingOption') +' | '+ currentBookingOption.name : $t('home.editBookingOptionForm.duplicateBookingOption') +' | '+ currentBookingOption.name" :active.sync="isActive" @close="closePopup">
        <div class="container w-full mb-10">
            <div class="flex flex-wrap">
                <h4 class="my-3 opacity-75 w-full" >{{$t('home.editBookingOptionForm.bookingOptionImages')}}</h4>
                <div class="vx-col w-full lg:w-1/2 md:mb-2 pl-2 mt-2">
                    <vue-upload-multiple-image
                        :idUpload="imagesUploadId"
                        :showEdit="false"
                        @upload-success="uploadImageSuccess"
                        @before-remove="beforeRemove"
                        @mark-is-primary="primaryMarked"
                        :popupText="$t('VueUploadMultipleImages.popupText')"
                        :primaryText="$t('VueUploadMultipleImages.primaryText')"
                        :markIsPrimaryText="$t('VueUploadMultipleImages.markIsPrimaryText')"
                        :browseText="$t('VueUploadMultipleImages.browseText')"
                        dragText=""
                        :maxImage="20"
                        :data-images="images" />
                </div>
                <!-- general information -->
                <h4 class="my-3 opacity-75 w-full" >{{$t('home.editBookingOptionForm.generalInformation')}}</h4>
                <div class="vx-col  w-1/2 md:mb-2 pl-2 mt-2">
                    <vs-input :label="$t('home.editBookingOptionForm.name')" v-model="currentBookingOption.name" class="w-full required" autocomplete="off"/>
                    <!-- <span v-show="errors.has('name')" class="help text-danger">{{errors.first("name")}}</span> -->
                </div>

                <div class="vx-col w-1/2 md:mb-2 pl-2 mt-2">
                    <vs-input :label="$t('home.editBookingOptionForm.description')" v-model="currentBookingOption.description" class="w-full"/>
                </div>
                <div class="vx-col w-full md:mb-2 pl-2 mt-2">
                    <vs-textarea :label="$t('home.editBookingOptionForm.detailedDescription')" height="150px" v-model="currentBookingOption.detailed_description" class="w-full"/>
                </div>

                <!-- MIN MAX GUESTS COUNT -->

                <div class="vx-col w-full mb-2 pl-2">
                    <div class="container mt-2">
                        <div class="vx-row">
                            <label class="v-select-label">{{$t('home.editBookingOptionForm.minMaxCountTitle')}}</label>
                        </div>
                        <div class="vx-row mt-3">
                            <div class="vx-col p-0 w-full lg:w-1/2 md:mb-2 pr-2 mt-2">
                                <vs-input type="number" :label="$t('home.editBookingOptionForm.minimumMenCount')" v-model="currentBookingOption.men_count_min" class="w-full"/>
                            </div>
                            <div class="vx-col p-0 w-full lg:w-1/2 md:mb-2 pr-2 mt-2">
                                <vs-input type="number" :label="$t('home.editBookingOptionForm.maximumMenCount')" v-model="currentBookingOption.men_count_max" class="w-full"/>
                            </div>
                            <div class="vx-col p-0 w-full lg:w-1/2 md:mb-2 pr-2 mt-2">
                                <vs-input type="number" :label="$t('home.editBookingOptionForm.minimumWomenCount')" v-model="currentBookingOption.women_count_min" class="w-full"/>
                            </div>
                            <div class="vx-col p-0 w-full lg:w-1/2 md:mb-2 pr-2 mt-2">
                                <vs-input type="number" :label="$t('home.editBookingOptionForm.maximumWomenCount')" v-model="currentBookingOption.women_count_max" class="w-full"/>
                            </div>
                        </div>

                    </div>
                </div>

                <!--// MIN MAX GUESTS COUNT //-->

                <div class="vx-col w-full mb-2 mt-4 pl-2">
                    <div class="container mt-2">
                        <div class="vx-row required">
                            <label class="v-select-label">{{$t('home.editBookingOptionForm.availability')}}</label>
                        </div>
                        <div class="vx-row mt-3">
                            <vs-radio class="mr-8 mt-1" v-model="currentBookingOption.availability" vs-name="currentBookingOption.availability" vs-value="available">{{$t('home.editBookingOptionForm.selectText.available')}}</vs-radio>
                            <vs-radio class="mr-4 mt-1" v-model="currentBookingOption.availability" vs-name="currentBookingOption.availability" vs-value="hidden">{{$t('home.editBookingOptionForm.selectText.hidden')}}</vs-radio>
                        </div>

                    </div>
                </div>

                <div class="vx-col w-full md:mb-2 pl-2 mt-2">
                    <label class="v-select-label">{{$t('home.editBookingOptionForm.chooseEventTypes')}}</label>
                    <ul class="flex flex-wrap">
                        <li v-for="eventType in eventTypesList" :key="eventType.id" class="mt-2 mr-6">
                            <vs-checkbox v-model="selectedEventTypes" :vs-value="eventType.id">{{eventType.display_name}}</vs-checkbox>
                        </li>
                    </ul>
                </div>


                <div class="vx-col w-full md:mb-2 pl-2 mt-10">
                    <span>{{$t('home.editBookingOptionForm.categories')}}</span>
                    <div v-for="category in categories" :key="category.id" class="mt-5">
                        {{ category.title }}
                        <ul class="flex flex-wrap">
                            <li v-for="category_type in category.types" :key="category_type.id" class="mt-2">
                                <vs-checkbox v-model="selectedCategoryTypes" :vs-value="category_type.id" @change="categoryTypeSelected(category_type.id)">{{category_type.title}}</vs-checkbox>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- this section will be visible only if the watch match event type is selected -->
                <div class="vx-col w-full md:mb-2 mt-5 pl-2" v-if="showDates">
                    <div class="flex w-full">
                        <small class="gray-note">{{$t('home.editBookingOptionForm.eventDatesNote')}}</small>
                    </div>
                    <div class="flex w-full">
                        <v-date-picker
                            class="flex w-full"
                            v-model="optionDateRange"
                            mode="datetime"
                            is-range
                            color="orange"
                            :masks="dateFormat"
                            :min-date="minDate"
                            :max-date="maxDate"
                        >
                            <template v-slot="{ inputValue, inputEvents, isDragging }">
                            <div class="flex w-full">

                                <vs-input
                                    :label="$t('home.editBookingOptionForm.eventStartDate')"
                                    class="flex-grow py-1 bg-gray-100 border rounded w-full pr-1"
                                    :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                    :value="inputValue.start"
                                    v-on="inputEvents.start"
                                    icon="today"
                                />

                                <vs-input
                                    :label="$t('home.editBookingOptionForm.eventEndDate')"
                                    class="flex-grow py-1 bg-gray-100 border rounded w-full"
                                    :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                                    :value="inputValue.end"
                                    v-on="inputEvents.end"
                                    icon="event"
                                />
                            </div>
                            </template>
                        </v-date-picker>
                        <div class="pt-2" v-if="optionDateRange.start || optionDateRange.end">
                            <vs-button class="my-5 mx-1" type="border" icon-pack="feather" icon="icon-trash" @click="optionDateRange = { start: null, end:null}"></vs-button>
                        </div>
                    </div>
                </div>


                <!-- ROOMS-BASED VS SIDE BASED -->
                <div class="w-full">
                    <div class="vx-col w-full my-3 opacity-50">
                      <hr>
                    </div>
                    <!-- <h4 class="my-2 opacity-75 w-full" >{{$t('home.editBookingOptionForm.bookingOptionType')}}</h4> -->
                    <div class="vx-col w-full mb-2 pl-2">
                        <div class="container mt-2">
                            <div class="vx-row required">
                                <label class="v-select-label">{{$t('home.editBookingOptionForm.bookingOptionType')}}</label>
                            </div>
                            <div class="vx-row mt-3">
                                <vs-radio class="mr-6 mt-1" v-model="currentBookingOption.availability_type" vs-name="currentBookingOption.availability_type" vs-value="size_based">{{$t('home.editBookingOptionForm.sizeBased')}}</vs-radio>
                                <vs-radio class="mr-2 mt-1" v-model="currentBookingOption.availability_type" vs-name="currentBookingOption.availability_type" vs-value="rooms_based">{{$t('home.editBookingOptionForm.roomsBased')}}</vs-radio>
                            </div>

                        </div>
                    </div>

                    <div class="vx-col w-full lg:w-1/2 md:mb-2 pl-2 mt-2" v-if="actionType != 'duplicateBookingOption' && currentBookingOption.availability_type == 'size_based'">
                      <vs-input type="number" :label="$t('home.editBookingOptionForm.sizeNote')+':'" v-model="currentBookingOption.bookings_size" class="w-full"/>
                    </div>
                </div>
                <!--// ROOMS-BASE VS SIDE BASED //-->

                <!-- SELECT ROOMS -->
                <div class="vx-col w-full md:mb-2 pl-2 mt-2" v-if="actionType != 'duplicateBookingOption' && currentBookingOption.availability_type == 'rooms_based'">
                  <label class="v-select-label">{{$t('home.editBookingOptionForm.chooseRooms')}}</label>
                    <ul class="flex flex-wrap">
                        <li v-for="room in roomsList" :key="room.id" class="mt-2 mr-6">
                            <vs-checkbox v-model="selectedRooms" :vs-value="room.id">{{room.name}}</vs-checkbox>
                        </li>
                    </ul>
                </div>
                <!--// SELECT ROOMS //-->

                <div class="vx-col w-full md:mb-2 pl-2 mt-2" v-if="actionType == 'duplicateBookingOption' && agentVenuesData.length">
                    <h4 class="my-2 opacity-75 w-full" >الفروع:</h4>
                    <ul>
                        <li v-for="venue in agentVenuesData" :key="venue.id" class="mt-2 mr-6 mb-5">
                            <vs-checkbox v-model="selectedBranches" :vs-value="venue.id">{{venue.name}} {{venue.id == currentVenueId? '(الفرع الحالي)' : ''}}</vs-checkbox>
                            <ul class="flex flex-wrap mt-2 ml-5" v-if="currentBookingOption.availability_type == 'rooms_based' && selectedBranches.includes(venue.id)">
                                <li v-for="room in venue.rooms" :key="room.id" class="mt-2 mr-5">
                                    <vs-checkbox v-model="venue.selectedRooms" :vs-value="room.id">{{room.name}}</vs-checkbox>
                                </li>
                            </ul>
                            <ul class="flex flex-wrap mt-2 ml-5" v-if="currentBookingOption.availability_type == 'size_based' && selectedBranches.includes(venue.id)">
                                <li class="mt-2 mr-5">
                                    <vs-input type="number" :label="$t('home.editBookingOptionForm.sizeNote')+':'" v-model="venue.bookings_size" class="w-full"/>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <!-- PRICING METHOD -->
                <div class="vx-col w-full my-3 opacity-50">
                    <hr>
                </div>
                <h4 class="my-2 opacity-75 w-full" >{{$t('home.editBookingOptionForm.pricing')}}</h4>
                <!-- <div class="vx-col w-full md:mb-2 pl-2 mt-2">
                    <vs-checkbox v-model="currentBookingOption.later_deposit_payment_enabled">{{$t('home.editBookingOptionForm.enableLaterPayment')}}</vs-checkbox>
                </div> -->
                <div class="vx-col w-full mb-2 pl-4">
                    <div class="container mt-2">
                        <div class="vx-row required">
                            <label class="v-select-label">{{$t('home.editBookingOptionForm.pricingType')}}</label>
                        </div>
                        <div class="vx-row mt-3">
                            <div class="vx-col w-full">
                                <vs-radio v-model="currentBookingOption.pricing_type" vs-name="currentBookingOption.pricing_type" vs-value="fixed">{{$t('home.editBookingOptionForm.selectText.fixed')}}</vs-radio>
                                <div class="vx-row ml-6 pl-1">
                                    <small class="gray-note">{{$t('home.editBookingOptionForm.selectText.fixedNote')}}</small>
                                </div>
                            </div>
                            <div class="vx-col w-full">
                                <vs-radio class="mt-3" v-model="currentBookingOption.pricing_type" vs-name="currentBookingOption.pricing_type" vs-value="per_person">{{$t('home.editBookingOptionForm.selectText.per_person')}}</vs-radio>
                                <div class="vx-row ml-6 pl-1">
                                    <small class="gray-note">{{$t('home.editBookingOptionForm.selectText.perPersonNote')}}</small>
                                </div>
                            </div>
                            <div class="vx-col w-full">
                                <vs-radio class="mt-3" v-model="currentBookingOption.pricing_type" vs-name="currentBookingOption.pricing_type" vs-value="per_person_range">{{$t('home.editBookingOptionForm.selectText.perPersonRange')}}</vs-radio>
                                <div class="vx-row ml-6 pl-1">
                                    <small class="gray-note">{{$t('home.editBookingOptionForm.selectText.perPersonRangeNote')}}</small>
                                </div>
                            </div>
                            <div class="vx-col w-full">
                                <vs-radio class="mt-3" v-model="currentBookingOption.pricing_type" vs-name="currentBookingOption.pricing_type" vs-value="fixed_range">{{$t('home.editBookingOptionForm.selectText.fixedRange')}}</vs-radio>
                                <div class="vx-row ml-6 pl-1">
                                    <small class="gray-note">{{$t('home.editBookingOptionForm.selectText.fixedRangeNote')}}</small>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!--// PRICING METHOD //-->


                <div class="vx-col w-full lg:w-1/2 lg:mr-5 md:mb-2 pl-2 mt-2" v-if="currentBookingOption.pricing_type == 'fixed'">
                    <vs-input type="number" :label="$t('home.editBookingOptionForm.price')" v-model="currentBookingOption.price" class="w-full"/>
                </div>
                <div class="vx-col w-full lg:w-1/2 md:mb-2 pl-2 mt-2" v-if="currentBookingOption.pricing_type == 'per_person'">
                    <vs-input type="number" :label="$t('home.editBookingOptionForm.manPrice')" v-model="currentBookingOption.man_price" class="w-full"/>
                </div>
                <div class="vx-col w-full lg:w-1/2 md:mb-2 pl-2 mt-2" v-if="currentBookingOption.pricing_type == 'per_person'">
                    <vs-input type="number" :label="$t('home.editBookingOptionForm.womanPrice')" v-model="currentBookingOption.woman_price" class="w-full"/>
                </div>
                <div class="vx-col w-full lg:w-1/2 lg:mr-5 md:mb-2 pl-2 mt-2" v-if="currentBookingOption.pricing_type == 'per_person' || currentBookingOption.pricing_type == 'per_person_range'">
                    <vs-input type="number" :label="$t('home.editBookingOptionForm.minimumPrice')" v-model="currentBookingOption.minimum_price" class="w-full"/>
                </div>

                <div class="vx-col w-full md:mb-2 pl-2 mt-2" v-if="currentBookingOption.pricing_type == 'per_person_range' || currentBookingOption.pricing_type == 'fixed_range'">
                    <div class="vx-row mb-5">
                        <vs-alert color="warning" icon="sms" :active.sync="rangePricingAlert" closable close-icon="close" class="h-full mb-5 py-2 pr-2">
                            <h5 class="mb-3">{{$t('home.editBookingOptionForm.rangePricing.alert.header')}}</h5>
                            <p>{{$t('home.editBookingOptionForm.rangePricing.alert.line1')}}</p>
                            <p v-if="currentBookingOption.pricing_type == 'per_person_range'">{{$t('home.editBookingOptionForm.rangePricing.alert.line2_per_person')}}</p>
                            <p v-if="currentBookingOption.pricing_type == 'fixed_range'">{{$t('home.editBookingOptionForm.rangePricing.alert.line2_fixed')}}</p>
                        </vs-alert>
                    </div>
                    <div v-for="(rangePricing, index) in currentBookingOption.range_pricing" :key="index">
                        <div class="grid grid-cols-2 lg:grid-cols-9">
                            <div class="cos-span-1 lg:col-span-2">
                                <vs-input type="number" class="w-11/12" v-model="rangePricing.men_count" :label="$t('servicesForm.groupPricing.menCount')" @change="computePrices()" autocomplete="off"/>
                            </div>
                            <div class="cos-span-1 lg:col-span-2">
                                <vs-input type="number" class="w-11/12" v-model="rangePricing.man_price" :label="$t('servicesForm.groupPricing.manPrice')" @change="computePrices()" autocomplete="off"/>
                            </div>
                            <div class="cos-span-1 lg:col-span-2">
                                <vs-input type="number" class="w-11/12" v-model="rangePricing.women_count" :label="$t('servicesForm.groupPricing.womenCount')" @change="computePrices()" autocomplete="off"/>
                            </div>
                            <div class="cos-span-1 lg:col-span-2">
                                <vs-input type="number" class="w-11/12" v-model="rangePricing.woman_price" :label="$t('servicesForm.groupPricing.womanPrice')" @change="computePrices()" autocomplete="off"/>
                            </div>
                            <div class="mt-1 col-span-1 flex justify-end">
                                <vs-button @click="removeRow(index)" color="danger" icon="delete" class="m-0 mt-5"></vs-button>
                            </div>
                        </div>
                        <hr>

                    </div>
                    <div class="mt-2 w-full flex justify-end">
                        <vs-button @click="addRow" color="success" icon="add" class="m-0 mt-5 mr-2">{{$t('servicesForm.groupPricing.addRow')}}</vs-button>
                    </div>
                    <!-- old range pricing table -->
                    <!-- <div class="vx-row">
                        <div class="mt-2 w-full flex justify-between" v-for="(rangePricing, index) in currentBookingOption.range_pricing" :key="index">
                            <vs-input type="number" class="pl-2" v-model="rangePricing.men_count" :label="$t('servicesForm.groupPricing.menCount')" @change="computePrices()" autocomplete="off"/>
                            <vs-input type="number" class="pl-1" v-model="rangePricing.man_price" :label="$t('servicesForm.groupPricing.manPrice')" @change="computePrices()" autocomplete="off"/>
                            <vs-input type="number" class="pl-1" v-model="rangePricing.women_count" :label="$t('servicesForm.groupPricing.womenCount')" @change="computePrices()" autocomplete="off"/>
                            <vs-input type="number" class="pl-1" v-model="rangePricing.woman_price" :label="$t('servicesForm.groupPricing.womanPrice')" @change="computePrices()" autocomplete="off"/>
                            <div class="mt-1 px-2">
                                <vs-button @click="removeRow(index)" color="danger" icon="delete" class="m-0 mt-5"></vs-button>
                            </div>
                        </div>
                        <div class="mt-2 w-full flex justify-end">
                            <vs-button @click="addRow" color="success" icon="add" class="m-0 mt-5 mr-2">{{$t('servicesForm.groupPricing.addRow')}}</vs-button>
                        </div>
                    </div> -->
                </div>


                <div class="vx-col w-full pl-4">
                    <div class="container mt-2">
                        <div class="vx-row">
                            <div class="vx-col w-full lg:w-1/2 mt-5">
                                <div class="vx-row mt-2 required">
                                    <label class="v-select-label">{{$t('home.editBookingOptionForm.taxingStatus')}}</label>
                                </div>
                                <div class="vx-row mt-1">
                                    <vs-radio class="mr-6 mt-2" v-model="currentBookingOption.is_taxable" vs-name="currentBookingOption.is_taxable" :vs-value="1">{{$t('home.editBookingOptionForm.taxable')}}</vs-radio>
                                    <vs-radio class="mr-2 mt-2" v-model="currentBookingOption.is_taxable" vs-name="currentBookingOption.is_taxable" :vs-value="0">{{$t('home.editBookingOptionForm.nonTaxable')}}</vs-radio>
                                </div>
                            </div>
                            <div class="vx-col w-full lg:w-1/2 mt-5" v-if="currentBookingOption.is_taxable">
                                <div class="vx-row mt-2 required">
                                    <label class="v-select-label">{{$t('home.editBookingOptionForm.taxingIncluding')}}</label>
                                </div>
                                <div class="vx-row mt-1">
                                    <vs-radio class="mr-6 mt-1 lg:mt-2" v-model="tax_included" vs-name="tax_included" vs-value="included">{{$t('home.editBookingOptionForm.taxIncluded.included')}}</vs-radio>
                                    <vs-radio class="mr-2 mt-3 lg:mt-2" v-model="tax_included" vs-name="tax_included" vs-value="not-included">{{$t('home.editBookingOptionForm.taxIncluded.notIncluded')}}</vs-radio>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="vx-col w-full my-3 opacity-50">
                    <hr>
                </div>
                <div class="vx-col w-full mt-3">
                    <vs-table :data="eventTypesList" v-if="currentBookingOption.pricing_type == 'fixed' || currentBookingOption.pricing_type == 'per_person'">
                        <template slot="header">
                            <h3>{{$t('home.editBookingOptionForm.finalPricesTable.header')}}</h3>
                        </template>
                        <template slot="thead">
                            <vs-th>{{$t('home.editBookingOptionForm.finalPricesTable.pricing')}}</vs-th>
                            <vs-th>{{$t('home.editBookingOptionForm.finalPricesTable.beforeTax')}}</vs-th>
                            <vs-th>{{$t('home.editBookingOptionForm.finalPricesTable.tax')}}</vs-th>
                            <vs-th>{{$t('home.editBookingOptionForm.finalPricesTable.totalPrice')}}</vs-th>
                        </template>

                        <template>
                            <vs-tr v-if="currentBookingOption.pricing_type == 'fixed'">
                                <vs-td>{{$t('home.editBookingOptionForm.finalPricesTable.fixedPrice')}}</vs-td>
                                <vs-td>{{finalPrices.fixedPrices.beforeTax}} {{$t('SAR')}}</vs-td>
                                <vs-td>{{finalPrices.fixedPrices.tax}} {{$t('SAR')}}</vs-td>
                                <vs-td>{{finalPrices.fixedPrices.finalPrice}} {{$t('SAR')}}</vs-td>

                            </vs-tr>
                            <vs-tr v-if="currentBookingOption.pricing_type != 'fixed'">
                                <vs-td>{{$t('home.editBookingOptionForm.finalPricesTable.manPrice')}}</vs-td>
                                <vs-td>{{finalPrices.variablePrices.manPrice.beforeTax}} {{$t('SAR')}}</vs-td>
                                <vs-td>{{finalPrices.variablePrices.manPrice.tax}} {{$t('SAR')}}</vs-td>
                                <vs-td>{{finalPrices.variablePrices.manPrice.finalPrice}} {{$t('SAR')}}</vs-td>

                            </vs-tr>
                            <vs-tr v-if="currentBookingOption.pricing_type != 'fixed'">
                                <vs-td>{{$t('home.editBookingOptionForm.finalPricesTable.womanPrice')}}</vs-td>
                                <vs-td>{{finalPrices.variablePrices.womanPrice.beforeTax}} {{$t('SAR')}}</vs-td>
                                <vs-td>{{finalPrices.variablePrices.womanPrice.tax}} {{$t('SAR')}}</vs-td>
                                <vs-td>{{finalPrices.variablePrices.womanPrice.finalPrice}} {{$t('SAR')}}</vs-td>

                            </vs-tr>
                        </template>
                    </vs-table>
                    <vs-table :data="eventTypesList" v-if="currentBookingOption.pricing_type == 'per_person_range' || currentBookingOption.pricing_type == 'fixed_range'">
                        <template slot="header">
                            <h3>{{$t('home.editBookingOptionForm.finalPricesTable.header')}}</h3>
                        </template>
                        <template slot="thead">
                            <vs-th>{{$t('home.editBookingOptionForm.finalPricesTable.pricing')}}</vs-th>
                            <vs-th>{{$t('home.editBookingOptionForm.finalPricesTable.range')}}</vs-th>
                            <vs-th>{{$t('home.editBookingOptionForm.finalPricesTable.beforeTax')}}</vs-th>
                            <vs-th>{{$t('home.editBookingOptionForm.finalPricesTable.tax')}}</vs-th>
                            <vs-th>{{$t('home.editBookingOptionForm.finalPricesTable.totalPrice')}}</vs-th>
                        </template>
                        <template v-for="(rangePricing, index) in rangeFinalPrices">
                            <vs-tr :key="index + Math.random().toString(36).substring(2,12)">
                                <vs-td>{{$t('home.editBookingOptionForm.finalPricesTable.rangeManPrice')}}</vs-td>
                                <vs-td>{{rangePricing.men_count}} {{$t('to')}} {{currentBookingOption.range_pricing.length != index+1 ? currentBookingOption.range_pricing[index+1].men_count - 1 : '-'}}</vs-td>
                                <vs-td>{{rangePricing.menBeforeTax}} {{$t('SAR')}}</vs-td>
                                <vs-td>{{rangePricing.menTax}} {{$t('SAR')}}</vs-td>
                                <vs-td>{{rangePricing.menFinalPrice}} {{$t('SAR')}}</vs-td>
                            </vs-tr>
                            <vs-tr :key="index + Math.random().toString(36).substring(2,12)">
                                <vs-td>{{$t('home.editBookingOptionForm.finalPricesTable.rangeWomanPrice')}}</vs-td>
                                <vs-td>{{rangePricing.women_count}} {{$t('to')}} {{currentBookingOption.range_pricing.length != index+1 ? currentBookingOption.range_pricing[index+1].women_count - 1 : '-'}}</vs-td>
                                <vs-td>{{rangePricing.womenBeforeTax}} {{$t('SAR')}}</vs-td>
                                <vs-td>{{rangePricing.womenTax}} {{$t('SAR')}}</vs-td>
                                <vs-td>{{rangePricing.womenFinalPrice}} {{$t('SAR')}}</vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </div>

                <div class="vx-col w-full lg:w-1/2 mb-2 mt-4 pl-4">
                    <div class="container mt-2">
                        <div class="vx-row required">
                            <label class="v-select-label">{{$t('home.editBookingOptionForm.hasSeasonalPricing')}}</label>
                        </div>
                        <div class="vx-row mt-3">
                            <vs-radio class="mr-6" v-model="currentBookingOption.has_seasonal_pricing" vs-name="currentBookingOption.has_seasonal_pricing" vs-value="1">{{$t('home.editBookingOptionForm.selectText.yes')}}</vs-radio>
                            <vs-radio class="mr-2" v-model="currentBookingOption.has_seasonal_pricing" vs-name="currentBookingOption.has_seasonal_pricing" vs-value="0">{{$t('home.editBookingOptionForm.selectText.no')}}</vs-radio>
                        </div>

                    </div>
                </div>
                <div class="vx-col w-full lg:w-1/2 mb-2 mt-4 pl-4">
                    <div class="container mt-2">
                        <div class="vx-row required">
                            <label class="v-select-label">{{$t('home.editBookingOptionForm.hasDaysPricing')}}</label>
                        </div>
                        <div class="vx-row mt-3">
                            <vs-radio class="mr-6" v-model="currentBookingOption.has_days_pricing" vs-name="currentBookingOption.has_days_pricing" vs-value="1">{{$t('home.editBookingOptionForm.selectText.yes')}}</vs-radio>
                            <vs-radio class="mr-2" v-model="currentBookingOption.has_days_pricing" vs-name="currentBookingOption.has_days_pricing" vs-value="0">{{$t('home.editBookingOptionForm.selectText.no')}}</vs-radio>
                        </div>

                    </div>
                </div>
                <div class="vx-col w-full mb-2 mt-4 pl-2" v-if="actionType == 'duplicateBookingOption'">
                    <div class="container mt-2">
                        <div class="vx-row">
                            <vs-checkbox v-model="copyCustomPricings">{{$t('home.editBookingOptionForm.copyCustomPricings')}}</vs-checkbox>
                        </div>
                        <div class="vx-row mt-0 pt-0">
                            <vs-list class="mt-0 pt-0">
                                <vs-list-item icon="info" :subtitle="$t('home.editBookingOptionForm.copyCustomPricingsNote')"></vs-list-item>
                            </vs-list>
                        </div>
                    </div>
                </div>
                <div class="vx-col w-full my-3 opacity-50">
                    <hr>
                </div>
                <div class="vx-col w-full md:mb-2 pl-2 mt-4">
                    <vs-button icon-pack="feather" icon="icon-check" @click="editBookingOption" class="mx-2">{{$t('save')}}</vs-button>
                </div>
            </div>
        </div>
        </vs-popup>
    </div>
</template>

<script>
import axios from '@/axios'
import VueUploadMultipleImage from 'vue-upload-multiple-image'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'

export default {
    name: 'booking-option-form',
    props: {
        editBookingOptionActive: {
            type: Boolean,
            defult: false
        },
        currentBookingOption: {
            type: Object,
            defult: null
        },
        isEditBookingOption: {
            type: Boolean,
            default: false
        },
        actionType: {
            type: String,
            default: 'addBookingOption'
        },
    },
    data () {
        return {
            images: [],
            selectedEventTypes: [],
            selectedCategoryTypes: [],
            selectedRooms: [],
            tax_included: 'not-included',
            rangePricingAlert: true,
            showDates: false,
            finalPrices: {
                fixedPrices: {
                    beforeTax: 0,
                    tax: 0,
                    finalPrice: 0
                },
                variablePrices: {
                    manPrice: {
                        beforeTax: 0,
                        tax: 0,
                        finalPrice: 0
                    },
                    womanPrice: {
                        beforeTax: 0,
                        tax: 0,
                        finalPrice: 0
                    }

                }
            },
            rangeFinalPrices: [],
            copyCustomPricings: false,
            agentVenuesData: [],
            selectedBranches: [],
            optionDateRange: {
                start: null,
                end: null,
            },
            minDate: "2019-01-01",
            maxDate: "2025-01-01",
            dateFormat: {
                input: 'YYYY-MM-DD HH:mm'
            },
        }
    },
    components: {
        vSelect,
        VueUploadMultipleImage,
        Datepicker
    },
    computed: {
        isActive: {
            get() {
                return this.editBookingOptionActive
            },
            set(newState) {
                return newState
            }
        },
        imagesUploadId() { // images upload library had some issues with the idUpload so I had to generate a random string for it
            return Math.random().toString(16).substr(2, 32);
        },
        currentVenueId () {
            return this.$store.getters.currentVenueId
        },
        currentVenue () {
            return JSON.parse(localStorage.getItem('currentVenue'));
        },
        baseURL: () => process.env.VUE_APP_IMAGES_BASE_URL,

        eventTypesList () {
            return [...this.$store.state.dataList.eventTypesList]
        },
        roomsList () {
            return this.$store.state.general.roomsList;
        },
        isOnBoarding() {
            let pathArray = window.location.href.split('/');
            return pathArray[pathArray.length - 1] == 'on-boarding';
        },
        agentVenues(){
            return typeof(this.$store.state.auth.agentVenues) == 'string' ? this.$store.state.auth.agentVenues != 'undefined' ?JSON.parse(this.$store.state.auth.agentVenues) : [] : this.$store.state.auth.agentVenues;
        },
        categories () {
            return this.$store.state.categories.categories;
        },
        vat() {
            return this.$constants.vat;
        }
    },
    created () {
        this.$store.dispatch('dataList/fetchEventTypesList')
        this.$store.dispatch('general/getRoomsList', this.currentVenueId)
        this.$store.dispatch('categories/getCategories')
    },
    methods: {
        editBookingOption() {
            this.$store.dispatch('loader/loaderOn')

            let request = new FormData();

            for (var key in this.currentBookingOption ) {
                if (this.currentBookingOption[key] != null)
                    request.append(key, this.currentBookingOption[key]);
            }

            if(this.images && this.images.length > 0) this.images.forEach(img => request.append('images[]', img.imgUrl ? img.imgUrl : img.path));
            // if(this.selectedEventTypes != null) this.selectedEventTypes.forEach(eventType => request.append('eventTypes[]', eventType.id)); // used when the selected event types were comming from a dropdown menu
            if(this.selectedEventTypes && this.selectedEventTypes.length > 0) this.selectedEventTypes.forEach(eventType => request.append('eventTypes[]', eventType)); else request.append('eventTypes', []);
            if(this.selectedRooms && this.selectedRooms.length > 0) this.selectedRooms.forEach(room => request.append('rooms[]', room)); else request.append('rooms', []);
            if(this.currentBookingOption.range_pricing && this.currentBookingOption.range_pricing.length > 0)
                this.currentBookingOption.range_pricing.forEach((rangePricing, index) => {
                    request.append(`range_pricing[${index}][men_count]`, rangePricing.men_count);
                    request.append(`range_pricing[${index}][man_price]`, rangePricing.man_price);
                    request.append(`range_pricing[${index}][women_count]`, rangePricing.women_count);
                    request.append(`range_pricing[${index}][woman_price]`, rangePricing.woman_price);
                });
            else
                request.append('range_pricing', []);

            request.append('tax_included', this.tax_included);

            let source = this.isOnBoarding ? 'OnBoarding' : 'Normal';
            request.append('source', source);

            if(this.optionDateRange.start) request.append('event_start_date', moment(this.optionDateRange.start).format(this.dateFormat.input))
            if(this.optionDateRange.end) request.append('event_end_date', moment(this.optionDateRange.end).format(this.dateFormat.input));

            this.selectedCategoryTypes.forEach(category_type => {
                request.append('types[]', category_type);
            });


            if(this.actionType == 'duplicateBookingOption') {
                request.append('is_duplicate', true);
                request.append('duplicated_option_id', this.currentBookingOption.id);
                request.append('copyCustomPricings', this.copyCustomPricings);
                if(this.agentVenues.length > 0 && this.selectedBranches && this.selectedBranches.length > 0) this.selectedBranches.forEach(branch => request.append('selectedBranches[]', branch)); else request.append('selectedBranches', []);
                if(this.agentVenues.length > 0) request.append('branches', JSON.stringify(this.agentVenuesData));
            }

            let url = this.actionType == 'editBookingOption' ? `/venues/${this.currentVenueId}/bookingOptions/${this.currentBookingOption.id}` : `/venues/${this.currentVenueId}/bookingOptions`;


            axios.post(url, request)
                .then((response) => {
                    this.closePopup();
                    this.$parent.getBookingOptionsAPI();
                    this.$parent.getNewBookingOptionsAPI();
                    this.$store.dispatch('general/getBookingOptionsList', this.currentVenueId) // update the booking options list state
                    this.$vs.notify({
                        color:'success',
                        text: response.data.success_message || 'تم الحفظ بنجاح'
                    })
                }).catch(error => {
                    this.errorsDisplay(error);
                })
                .finally(() => {
                    this.$store.dispatch('loader/loaderOff')
                }); // end of axios
        },
        closePopup() {
            // this.images = [];
            this.$emit('update:editBookingOptionActive', false);
        },
        errorsDisplay(error) {
            let errs = error.response.data.errors ? Object.keys(error.response.data.errors).map((key) => error.response.data.errors[key]) : []
            let text = '';
            errs.forEach(err => {
                text += '<li>'+err[0]+'</li>'
            });
            this.$vs.notify({
                color:'danger',
                type: 'error',
                title: 'حدث خطأ ما!',
                text: '<ul>'+text+'</ul>'
            });
        },

        computePrices() {
          // TODO: THE VAT SHOULDN'T BE HARD-CODED AS 1.15 AND WE NEED TO CHANGE THIS, IF THE REGULATIONS OF VAT CHANGES, IT WILL BE A HASSTLE TO FIND ALL 1.15
            let vatPlusOne = this.vat + 1;
            if(this.tax_included == 'included') {
                // fixed prices
                this.finalPrices.fixedPrices.tax = Math.round((this.currentBookingOption.price - (this.currentBookingOption.price / vatPlusOne)))
                this.finalPrices.fixedPrices.finalPrice = this.currentBookingOption.price;
                this.finalPrices.fixedPrices.beforeTax = Math.round((this.finalPrices.fixedPrices.finalPrice / vatPlusOne).toFixed(2));

                // variable prices
                this.finalPrices.variablePrices.manPrice.tax = (this.currentBookingOption.man_price - (this.currentBookingOption.man_price / vatPlusOne)).toFixed(2)
                this.finalPrices.variablePrices.manPrice.finalPrice = this.currentBookingOption.man_price;
                this.finalPrices.variablePrices.manPrice.beforeTax = (this.currentBookingOption.man_price / vatPlusOne).toFixed(2);

                this.finalPrices.variablePrices.womanPrice.tax = (this.currentBookingOption.woman_price - (this.currentBookingOption.woman_price / vatPlusOne)).toFixed(2)
                this.finalPrices.variablePrices.womanPrice.finalPrice = this.currentBookingOption.woman_price;
                this.finalPrices.variablePrices.womanPrice.beforeTax = (this.currentBookingOption.woman_price / vatPlusOne).toFixed(2);

                this.rangeFinalPrices = this.currentBookingOption.range_pricing;
                this.rangeFinalPrices.forEach(rangePrice => {
                    rangePrice.menTax = (rangePrice.man_price - (rangePrice.man_price / vatPlusOne)).toFixed(2)
                    rangePrice.menFinalPrice = rangePrice.man_price;
                    rangePrice.menBeforeTax = (rangePrice.menFinalPrice / vatPlusOne).toFixed(2);

                    rangePrice.womenTax = (rangePrice.woman_price - (rangePrice.woman_price / vatPlusOne)).toFixed(2)
                    rangePrice.womenFinalPrice = rangePrice.woman_price;
                    rangePrice.womenBeforeTax = (rangePrice.womenFinalPrice / vatPlusOne).toFixed(2);
                });
            } else {
                if(this.currentBookingOption.is_taxable == 1) {
                    this.taxablePrices();
                } else {
                    this.nonTaxablePrices();
                }
            }
        },
        taxablePrices() {
            let vatPlusOne = this.vat + 1;
            // fixed prices
            this.finalPrices.fixedPrices.tax = Math.round((this.currentBookingOption.price * this.vat))
            this.finalPrices.fixedPrices.finalPrice = Math.round((this.currentBookingOption.price * vatPlusOne));
            this.finalPrices.fixedPrices.beforeTax = this.currentBookingOption.price;

            // variable prices
            this.finalPrices.variablePrices.manPrice.tax = ((this.currentBookingOption.man_price * vatPlusOne) - this.currentBookingOption.man_price).toFixed(2)
            this.finalPrices.variablePrices.manPrice.finalPrice = (this.currentBookingOption.man_price * vatPlusOne).toFixed(2);
            this.finalPrices.variablePrices.manPrice.beforeTax = this.currentBookingOption.man_price;

            this.finalPrices.variablePrices.womanPrice.tax = ((this.currentBookingOption.woman_price * vatPlusOne) - this.currentBookingOption.woman_price).toFixed(2)
            this.finalPrices.variablePrices.womanPrice.finalPrice = (this.currentBookingOption.woman_price * vatPlusOne).toFixed(2);
            this.finalPrices.variablePrices.womanPrice.beforeTax = this.currentBookingOption.woman_price;


            this.rangeFinalPrices = this.currentBookingOption.range_pricing;
            this.rangeFinalPrices.forEach(rangePrice => {
                rangePrice.menTax = ((rangePrice.man_price * vatPlusOne) - rangePrice.man_price).toFixed(2)
                rangePrice.menFinalPrice = (rangePrice.man_price * vatPlusOne).toFixed(2);
                rangePrice.menBeforeTax = rangePrice.man_price;

                rangePrice.womenTax = ((rangePrice.woman_price * vatPlusOne) - rangePrice.woman_price).toFixed(2)
                rangePrice.womenFinalPrice = (rangePrice.woman_price * vatPlusOne).toFixed(2);
                rangePrice.womenBeforeTax = rangePrice.woman_price;

            });
        },
        nonTaxablePrices() {
            // fixed prices
            this.finalPrices.fixedPrices.tax = (0).toFixed(2);
            this.finalPrices.fixedPrices.finalPrice = Number(this.currentBookingOption.price).toFixed(2);
            this.finalPrices.fixedPrices.beforeTax = Number(this.currentBookingOption.price).toFixed(2);

            // variable prices
            this.finalPrices.variablePrices.manPrice.tax = (0).toFixed(2);
            this.finalPrices.variablePrices.manPrice.finalPrice = Number(this.currentBookingOption.man_price).toFixed(2);
            this.finalPrices.variablePrices.manPrice.beforeTax = Number(this.currentBookingOption.man_price).toFixed(2);

            this.finalPrices.variablePrices.womanPrice.tax = (0).toFixed(2);
            this.finalPrices.variablePrices.womanPrice.finalPrice = Number(this.currentBookingOption.woman_price).toFixed(2);
            this.finalPrices.variablePrices.womanPrice.beforeTax = Number(this.currentBookingOption.woman_price).toFixed(2);

            this.rangeFinalPrices = this.currentBookingOption.range_pricing;
            this.rangeFinalPrices.forEach(rangePrice => {
                rangePrice.menTax = (0).toFixed(2)
                rangePrice.menFinalPrice = Number(rangePrice.man_price).toFixed(2);
                rangePrice.menBeforeTax = Number(rangePrice.man_price).toFixed(2);

                rangePrice.womenTax = (0).toFixed(2)
                rangePrice.womenFinalPrice = Number(rangePrice.woman_price).toFixed(2)
                rangePrice.womenBeforeTax = Number(rangePrice.woman_price).toFixed(2)

            });
        },
        adjustPricesForTaxIncluded() {
            this.currentBookingOption.price = Number(this.currentBookingOption.price * 1.15).toFixed(2);
            this.currentBookingOption.man_price = Number(this.currentBookingOption.man_price * 1.15).toFixed(2);
            this.currentBookingOption.woman_price = Number(this.currentBookingOption.woman_price * 1.15).toFixed(2);
            if(this.currentBookingOption.pricing_type == 'fixed_range' || this.currentBookingOption.pricing_type == 'per_person_range') {
                for (let index = 0; index < this.currentBookingOption.range_pricing.length; index++) {
                    this.currentBookingOption.range_pricing[index].man_price = Number(this.currentBookingOption.range_pricing[index].man_price * 1.15).toFixed(2);
                    this.currentBookingOption.range_pricing[index].woman_price = Number(this.currentBookingOption.range_pricing[index].woman_price * 1.15).toFixed(2);

                }
            }
        },
        uploadImageSuccess(formData, index, fileList) {
            this.images = fileList;
        },
        beforeRemove (index, done, fileList) {
            var r = confirm("remove image")
            if (r == true) {
                done()
            } else {
            }
            this.images = fileList;
        },
        primaryMarked(index, fileList) {
            this.images = fileList;
        },
        addRow() {
            // if first row set it to 1 else null
            this.currentBookingOption.range_pricing.push({
                men_count: 0,
                man_price: 0,
                women_count: 0,
                woman_price: 0,
                // people_count: null,
                // third_input_price: null,
            });
        },
        removeRow(index) {
            this.currentBookingOption.range_pricing.splice(index, 1)
        },
        categoryTypeSelected(category_type_id) {
            this.selectedCategoryTypes = [category_type_id];
        }
    },
    watch: {
        isActive(newVal) {
            if(newVal && this.actionType == 'addBookingOption') {
                this.selectedEventTypes = [];
                this.selectedRooms = [];
                this.selectedCategoryTypes = [];
            }
        },
        currentBookingOption(newVal) {
            // this.selectedEventTypes = newVal.event_types; // selected event types when it was a dropdown list
            // this.tax_included = 'not-included';
            this.tax_included = newVal.tax_included || 'not-included';
            if(newVal.tax_included == 'included'){
                this.adjustPricesForTaxIncluded();
            }
            if(newVal.event_types) this.selectedEventTypes = newVal.event_types.map(eventType => eventType.id)
            if(newVal.rooms) this.selectedRooms = newVal.rooms.map(room => room.id)
            if(newVal.types) this.selectedCategoryTypes = newVal.types.map(type => type.id)
            this.currentBookingOption.is_taxable = newVal.is_taxable != null ? newVal.is_taxable: this.currentVenue.is_taxable
            if(newVal.range_pricing == null) this.currentBookingOption.range_pricing = [];
            if(newVal.event_start_date) this.optionDateRange = {start: newVal.event_start_date, end: newVal.event_end_date};
            if(this.agentVenues) {
                let agentVenuesData = [];
                this.selectedBranches = [];
                this.selectedBranches.push( Number(this.currentVenueId)); // auto select the current branch
                this.agentVenues.forEach(venue => {
                    agentVenuesData.push({
                        id: venue.id,
                        venue_id: venue.id,
                        name: venue.name,
                        rooms: venue.rooms,
                        selectedRooms: [],
                        bookings_size: 1,
                    });
                });
                this.agentVenuesData = agentVenuesData;
            }
            this.computePrices();
        },
        optionDateRange: function() {
            this.currentBookingOption.event_start_date = this.optionDateRange.start;
            this.currentBookingOption.event_end_date = this.optionDateRange.end;
        },
        tax_included(newVal){
            if(newVal == 'included') {
                this.currentBookingOption.is_taxable = 1;
            }
            this.computePrices()
        },
        selectedEventTypes(newVal) {
            let timeBasedEvents = [];
            this.eventTypesList.forEach(eventType => {
                if(eventType.is_time_based)
                    timeBasedEvents.push(eventType.id);
            });
            const filteredArray = timeBasedEvents.filter(value => newVal.includes(value));
            this.showDates = filteredArray.length > 0;
        },
        'currentBookingOption.images'(imgs){
            if(imgs) {
                if(imgs) this.images = imgs.map((image, index) => { return { path: this.baseURL+'/'+image ,default: index == 0 ? 1 : 0,highlight: index == 0 ? 1 : 0,caption: 'caption to display. receive', imgUrl: image}});
            } else {
                this.images = [];
            }
        },
        'currentBookingOption.is_taxable'(newVal){
            if(newVal == 0) {
                this.tax_included = 'not-included';
            }
            this.computePrices()
        },
        'currentBookingOption.price'(newVal){
            this.computePrices()
        },
        'currentBookingOption.man_price'(){
            this.computePrices()
        },
        'currentBookingOption.woman_price'(){
            this.computePrices()
        },
        'currentBookingOption.range_pricing': {
           handler: function() {
               this.computePrices()
            },
            deep: true
        },
    }
}
</script>

<style lang="css">
.edit-flex-col {
    justify-content: flex-end;
}
.vs-tooltip {
  z-index: 99999 !important;
}

input[type=file] {
  padding: 7px 5px;
  border-radius: 8px;
}

input[type=file]::file-selector-button {
  border: none;
  padding: .75rem 2rem;
  border-radius: 6px;
  color: white;
  background-color: rgb(255,177,53);
  transition: 1s;
}

input[type=file]::file-selector-button:hover {
  background-color: rgb(248, 188, 91);
}

.required label::after {
    content: "*";
    color: red;
}

.image-container {
    width: 100% !important;
}

</style>
