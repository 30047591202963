/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_BUSINESS_EVENTS(state, businessEvents) {
    state.businessEvents = businessEvents
  },
  SET_PROPOSALS(state, proposals) {
    state.proposals = proposals
  },
  SET_BUSINESS_EVENTS_COUNT(state, businessEventsCount) {
    state.businessEventsCount = businessEventsCount
  }
}
