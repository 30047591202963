// en, de, fr, pt
export default {
  en: {
    Dashboard: 'Dashboard',
    eCommerce: 'eCommerce',
    Apps: 'Apps',
    Todo: 'Todo',
    Chat: 'Chat',
    Email: 'Email',
    Calendar: 'Calendar',
    PlaceInfo: 'Place Information',
    FullCalendar: 'Full Calendar',
    SimpleCalendar: 'Simple Calendar',
    Shop: 'Shop',
    ItemDetails: 'Item Details',
    Cart: 'Cart',
    WishList: 'Wish List',
    Checkout: 'Checkout',
    UI: 'UI',
    SAR: 'SAR',
    new: 'New',
    soon: 'Soon',
    to: 'To',
    DataList: 'Data List',
    ListView: 'List View',
    ThumbView: 'Thumb View',
    Grid: 'Grid',
    Vuexy: 'Vuexy',
    Vuesax: 'Vuesax',
    Tailwind: 'Tailwind',
    Colors: 'Colors',
    Card: 'Card',
    Basic: 'Basic',
    Statistics: 'Statistics',
    Analytics: 'Analytics',
    CardActions: 'Card Actions',
    CardColors: 'Card Colors',
    Table: 'Table',
    agGridTable: 'agGrid Table',
    Components: 'Components',
    Alert: 'Alert',
    Avatar: 'Avatar',
    Badge: 'Badge',
    Breadcrumb: 'Breadcrumb',
    Button: 'Button',
    ButtonGroup: 'Button Group',
    Chip: 'Chip',
    Collapse: 'Collapse',
    Dialogs: 'Dialogs',
    Divider: 'Divider',
    DropDown: 'DropDown',
    List: 'List',
    Loading: 'Loading',
    Navbar: 'Navbar',
    Notifications: 'Notifications',
    Pagination: 'Pagination',
    Popup: 'Popup',
    Progress: 'Progress',
    Sidebar: 'Sidebar',
    Slider: 'Slider',
    Tabs: 'Tabs',
    Tooltip: 'Tooltip',
    Upload: 'Upload',
    FormsAndTable: 'Forms & Table',
    FormElements: 'Form Elements',
    Select: 'Select',
    Switch: 'Switch',
    Checkbox: 'Checkbox',
    Radio: 'Radio',
    Input: 'Input',
    NumberInput: 'Number Input',
    Textarea: 'Textarea',
    FormLayouts: 'Form Layouts',
    FormWizard: 'Form Wizard',
    FormValidation: 'Form Validation',
    FormInputGroup: 'Form Input Group',
    Pages: 'Pages',
    Authentication: 'Authentication',
    Login: 'Login',
    Register: 'Register',
    ForgotPassword: 'Forgot Password',
    ResetPassword: 'Reset Password',
    LockScreen: 'Lock Screen',
    Miscellaneous: 'Miscellaneous',
    ComingSoon: 'Coming Soon',
    Error: 'Error',
    404: '404',
    500: '500',
    NotAuthorized: 'Not Authorized',
    Maintenance: 'Maintenance',
    Profile: 'Profile',
    User: 'User',
    View: 'View',
    Edit: 'Edit',
    UserSettings: 'User Settings',
    Invoice: 'Invoice',
    FAQ: 'FAQ',
    Search: 'Search',
    KnowledgeBase: 'Knowledge Base',
    ChartsAndMaps: 'Charts & Maps',
    Charts: 'Charts',
    ApexCharts: 'Apex Charts',
    chartjs: 'chartjs',
    echarts: 'echarts',
    chartist: 'chartist',
    GoogleMap: 'Google Map',
    Extensions: 'Extensions',
    QuillEditor: 'Quill Editor',
    DragAndDrop: 'Drag & Drop',
    Datepicker: 'Datepicker',
    DatetimePicker: 'Datetime Picker',
    AccessControl: 'Access Control',
    I18n: 'I18n',
    Carousel: 'Carousel',
    Clipboard: 'Clipboard',
    ContextMenu: 'Context Menu',
    StarRatings: 'Star Ratings',
    Autocomplete: 'Autocomplete',
    Tree: 'Tree',
    // "Import/Export": 'Import/Export',
    Import: 'Import',
    Export: 'Export',
    ExportSelected: 'Export Selected',
    Others: 'Others',
    MenuLevels: 'Menu Levels',
    MenuLevel2p1: 'Menu Level 2.1',
    MenuLevel2p2: 'Menu Level 2.2',
    MenuLevel3p1: 'Menu Level 3.1',
    MenuLevel3p2: 'Menu Level 3.2',
    DisabledMenu: 'Disabled Menu',
    Support: 'Support',
    Documentation: 'Documentation',
    RaiseSupport: 'Raise Support',
    demoTitle: 'Card Title',
    demoText: 'Cake sesame snaps cupcake gingerbread danish I love gingerbread. Apple pie pie jujubes chupa chups muffin halvah lollipop. Chocolate cake oat cake tiramisu marzipan sugar plum. Donut sweet pie oat cake dragée fruitcake cotton candy lemon drops.',

    Dashboard: 'Control Panel',
    Calendar: 'Calendar',
    priceCalculator: 'Price Calculator',
    newBooking: 'New Booking',
    Add: 'Add',
    edit: 'Edit',
    search: 'Search',
    block: 'Block',
    acceptBooking: 'Accept Booking',
    rejectBooking: 'Reject Booking',
    interested: 'Interested',
    notInterested: 'Not Interested',
    BookingsList: 'Reservations List',
    BookingsUpdates: 'Reservation Updates',
    Dashboard: 'Dashboard',
    darkMode: 'Dark Mode',
    lightMode: 'Light Mode',
    bookingSource: {
      Admin: 'Admin',
      SaaS: 'SaaS',
      Agent: 'Agent',
      'Eventful App': 'Eventful App',
      'Web App': 'Web App',
    },
    Booking: {
      New: 'New reservation',
      ReserverName: 'Booking holder name'
    },
    blockDate: {
      header: 'Block Date',
      bodyCustomerDate: 'The customer {customer} has booked an incompleted booking at {date}',
      confirmation: 'Do you want to block this date?',
      block: 'block date',
      cancel: 'close'
    },
    customerOffer: {
      header: 'Send Offer To Customer',
      notAvailableNow: 'This feature is not available now',
      availableSoon: 'Soon you will be able to send custom offers to your customers',
      point1: 'The ability to make a discount',
      point2: 'Add a gift if they booked',
      point3: 'Add a service or any idea that can encourage the customer to book',
      ok: 'Ok',
    },
    bookingsLineChart: 'Bookings Line Chart',
    bookingsStatesPieChart: 'Bookings States Pie Chart',
    appNotifications: "App Notifications",
    logout: 'Logout',
    profile:'profile',
    email:'Email',
    phone_number:'Phone number ',
    full_name:'Name',
    seconds:'seconds',
    vc_enterance:' Please enter the verification code',
    updateInfo:'Update',
    verify:'Verify',
    successChangingNumber:'Your phone number is updated succesfully',
    new: 'New',
    next: 'Next',
    back: 'Back',
    save: 'Save',
    send: 'Send',
    close: 'Close',
    delete: 'Delete',
    depositPaid: 'Deposit is paid',
    eventfulDiscount: 'Discount handled by Eventful',
    agentDiscount: 'Discount handled by {venueName}',
    vatIncluded: 'Including VAT',
    person: 'Person',
    hour: 'Hour',
    login: 'Login',
    actions: 'Actions',
    appName: "Eventful Agent",
    appSlug: "The easiest way to manage your Bookings",
    welcome: "Welcome",
    welcomeLogin: 'Welcome again, enter your number to enter',
    phoneNumber: "Phone Number",
    phoneNumberPlaceholder: 'Ex: 5xxxxxxxx',
    messages:{
      sentCode:'The verification code has been sent to the number',
      enterCode:'Enter the sent code to continue',
    },
    verificationCode:'Verification Code',
    password: 'Password',
    chooseVenue: 'Choose Venue',
    buttons: {
      verify:"Verify",
      changeNumber: "Edit Number",
      sendCode: "Send verification code",
      addBooking: 'Add Booking',
      view: 'View',
      edit: 'Edit',
      delete: 'Delete',
      generateReceipt: 'Generate Receipt'
    },
    VueUploadMultipleImages: {
      popupText: 'This image will appear as the main image in the app',
      primaryText: 'Main Image',
      markIsPrimaryText: 'Mark This Image As Main Image',
      browseText: 'Choose Multiple Images',
    },
    bookingBlock: 'Booking Block',
    dateButtons: {
      currentMonth: 'Current Month',
      lastMonth: 'Last Month',
      currentWeek: 'Current Week',
      lastWeek: 'Last Week',
      currentDay: 'Current Day',
      lastDay: 'Last Day',
      currentYear: 'Current Year',
      lastYear: 'Last Year',
    },
    booking: {
      customerName: 'Customer Name',
      customerPhoneNumber: 'Phone Number',
      customer_vat_number: 'VAT Number',
      customer_commercial_registration_number: 'CR Number',
      customer_address: 'Address',
      eventType: 'Event Type',
      bookingDate: 'Event Date',
      bookingFinalValue: 'Total Price',
      bookingFinalValueAfterDiscount: 'Total Price After Discount',
      showDetails: 'Show Details',
      bookingOptionTitle: 'Booking Option Title',
      writeCancelationReason: 'Write Cancelation Reason',
      guestsCount: 'Guests Count',
      bookingOption: {
        description: 'Description',
        priceNoVat: 'Price (before VAT)',
        detailedDescription: 'What does it include?'
      },
    },
    invoice: {
      invoice: 'Invoice',
      general: 'General',
      totalPrice: 'Total Price',
      eventfulPercentage: 'Eventful Commission',
      yourNet: 'Your Net',
      details: 'Details',
      option: {
        optionPrice: 'Booking Option Price',
        beforeVat: 'Before VAT',
        vat: 'VAT',
        afterVat: 'After VAT',
        discount: 'Discount',
        priceAfterDiscount: 'After Discount',
        discountSource: 'Discount Source',
        eventful: 'Eventful'
      },
      services: {
        servicesPrice: 'Services Price',
        beforeVat: 'Before VAT',
        vat: 'VAT',
        afterVat: 'After VAT',
        discount: 'Discount',
        priceAfterDiscount: 'After Discount',
      },
      paymentDetails: {
        paymentDetails: 'Payment Details',
        collectingFromCustomer: 'Collecting From Customer',
        collectedFromEventful: 'Collected By Eventful',
        collectedFromPlace: 'Collected By You',
        remainingToBeCollected: 'Remaining To Collect From Customer',
        paymentNotesByAgent: 'Payment notes'
      }

    },
    customPricings: {
      customPricingsFor: 'Custom Pricings For',
      title: 'title',
      startDate: 'Start Date',
      endDate: 'End Date',
      pricingIsSeasonal: 'Is The Pricing Seasonal?',
      seasonal: 'Seasonal',
      nonSeasonal: 'Non Seasonal',
      pricingType: 'Pricing Type',
      fixed: 'Fixed',
      variable: 'Variable',
      manPrice: 'Man Price',
      womanPrice: 'Woman Price',
      peoplePrice: 'People Price',
      menCount: 'Men Count',
      womenCount: 'Women Count',
      peopleCount: 'People Count',
      basePrice: 'Base Price',
      day: 'Day',
      daysPricings: 'Days Pricings',
      seasonalPricings: 'Seasonal Pricings',
      deletePricing: 'Delete Pricing',
      areYouSureYouWantToDelete: 'Are You Sure You Want To Delete ',
      edit: 'Edit',
      add: 'Add',
      pricingTypefixed : 'Fixed Pricing',
      pricingTypevariable : 'Variable Pricing',
      pricingTypeper_person : 'Variable Pricing',
      appliedPricing: 'Applied Pricing',
      notAppliedPricing: 'Unapplied Pricing',
      daysValidationError: 'Please define the day and price for each pricing',
      calendar: {
        formTitle: 'Enter The Data To get The Pricings',
        formDescription: 'The Prices Here are as the appear to the customers',
        update: 'Update Calendar',
        todayPricings: 'Today\'s Pricings'
      }
    },
    discountCouponForm: {
      specificCustomer: 'ستقوم بانشاء كوبون خصم مخصص للعميل ({bookingOption}) وباقة الحجز ({customerName}) وعند حجزه واستخدامه للكود سيتم تطبيق الخصم',
      name: 'Coupon Name'

    },
    bookingConfirmation: {
      rejection: {
        why: 'Why do you want to reject this booking?',
        unavailable_venue_in_date: 'Unavailable Date ({date})',
        wrong_count: 'Wrong Count',
        other: 'Other',
        chooseAvailableDate: 'Choose available date',
        chooseAvailablecount: 'Choose correct count',
        writeRejectionReason: 'What is the other reason?',
        pleaseSelectSuggestedDates: 'Please select suggested dates',
        pleaseFillFormWithInformation: 'Please fill information',
        unavailable_booking_option_in_date: '({option_name}) is unavailable',
        chooseAlternativeBookingOptions: 'choose alternative options',
        doYouWantToBlockTheDate: 'Do you want to block the date ({date})?',
        block_venue_in_date: 'Do not receive any booking at ({date}) for all booking options in ({venue_name})',
        block_option_in_date: 'Do not receive any booking at ({date}) only on ({option_name})',
        rejection_alert: 'We will send the rejection to the customer with the reason and the suggestion you provided in this form',
      }
    },
    home: {
      chooseBookingsStates: 'Choose Bookings States',
      welcome: 'Welcome',
      welcomeAgain: "Welcome again to Eventfull Agent",
      CompletedReservations: 'Completed Bookings',
      bookingOptions: "Booking Options",
      services: 'Extra Services',
      name: 'Name',
      state: 'State',
      pending: 'Pending',
      rejected: 'Rejected',
      description: 'Description',
      interiorSpaces: 'Interior spaces',
      gallery: 'Place Photos',
      upcomingBookings: 'Upcoming Bookings',
      bookingsWaitingForYourAction: 'Bookings Waiting For Your Action',
      bookingsWaitingForCustomerAction: 'Bookings Waiting For Customer Action',
      lastAddedBookings: 'Latest Bookings',
      cancelledBookings: 'Cancelled Bookings',
      endedBookings: 'Ended Bookings',
      lastAddedBookings: 'Last Added Bookings',
      BookingsNumbers: 'Bookings Numbers',
      BookingsNumbersDesc: 'Only bookings of selected states are considered in this calculation based on the selected time period.',
      BookingsCount: 'Bookings Count',
      totalPrice: 'Total Sales',
      totalPriceWithTax: 'Total Sales With VAT',
      totalPaidAmount: 'Total Paid',
      totalEventfulCommission: 'Total Eventful Commission',
      changeRequest: 'Change request',
      createRequests: 'Create Request',
      submitRequest: 'Submit request',
      editRequest: 'Edit request',
      noteToAgent: 'By clicking on (Submit request), your request will be sent to Eventful team to assure the validity of the new information and then permitting it within 24 hours.',
      yes: 'Yes',
      pendingDescription: 'This booking option already have an old change request, you can edit it.',
      newPendingDescription: 'This booking option is waiting for thr admin to approve it.',
      newRejectedDescription: 'This booking option is rejected, you can edit it and reupload it',
      changeRequests: 'Change requests',
      eventType: 'Event Type',
      newBookingOptions: 'New Booking Options',
      customPricings: 'Custom Pricings',
      optionPricings: 'Option Pricings',
      seasonalPricings: 'Seasonal Pricings',
      daysPricings: 'Days Pricings',
      editBookingOptionPrompt: {
        accept: 'Continue',
        cancel: 'cancel',
        message: 'You already have a change request waiting for approval, Do you want to edit it?'
      },
      editBookingOptionForm: {
        //general information
        generalInformation: 'General Information',
        name: 'Name',
        bookingsSize: 'Bookings size',
        availability: 'Availability',
        availabilityType: 'Availability type',
        description: 'Description',
        detailedDescription: 'Detailed description',
        availability: 'Availability',

        // deposits
        enableLaterPayment: 'Enable Later Payment',
        deposit: 'Deposit',
        depositType: 'Deposit type',
        percentageDeposit: 'Percentage deposit',
        laterDepositPaymentEnabled: 'Later deposit payment enabled',

        // pricing
        thePrice: 'Price',
        pricing: 'Pricing',
        price: 'Fixed price',
        manPrice: 'Person Price / Man Price (Based on occasion)',
        womanPrice: 'Hour price / Woman Price / Child Price (Based on occasion)',
        minMaxCountTitle: 'What is the minimum or maximum you accept for this booking option? (leave it empty for not limits)',
        minimumMenCount: 'Minimum count for (men count) or (people count) or (kids count) you accept',
        maximumMenCount: 'Maximum count for (men count) or (people count) or (kids count) you accept',
        minimumWomenCount: 'Minimum count for (women count) or (hours count) you accept',
        maximumWomenCount: 'Maximum count for (women count) or (hours count) you accept',
        personPrice: 'Person price',
        weekendPrice: 'Added weekend price',
        minimumPrice: 'Minimum price (Minimum bill you accept)',
        pricingType: 'Pricing type',
        taxingIncluding: 'Including Tax',
        taxingStatus: 'Tax Status',
        taxIsAppliedInVenue: 'Tax is applied in venue',
        taxIsNotAppliedInVenue: 'Tax is NOT applied in venue',
        taxIsAppliedInVenueTooltip: 'choosing (follow venue) option will change the tax change in the booking option if it changed in the venue',
        followPlace: 'Follow Venue',
        taxable: 'Taxable',
        nonTaxable: 'Non Taxable',
        servicesFixedPrice: 'Services fixed price',
        servicesGroups: 'Services groups',
        servicesPerManPrice: 'Services per man price',
        servicesPreWomanPrice: 'Services per woman price',
        servicesPerPersonPrice: 'Services per person price',
        servicesWeekendExtraPrice: 'Services weekend extra price',
        hasSeasonalPricing: 'Does the price effected if it was a season/specific date?',
        hasDaysPricing: 'Is the price different by day?',
        sizeBased: 'Size Based',
        roomsBased: 'Room Based',
        bookingOptionType: 'Booking Optipn Type',
        size: 'Number of times',
        sizeNote: 'Number of times that the booking option can be booked at the same time',

        // discounts
        discounts: 'Discounts',
        discountType: 'Discount type',
        percentageDiscount: 'Percentage discount',
        fixedDiscount: 'Fixed discount',

        taxApplied: 'total price with VAT if applicaple',

        // other information
        otherInformation: 'Other Information',
        eventTypes: 'Event types',
        services: 'Services',
        externalServices: 'External services',
        internalServices: 'Internal services',
        depth: 'Depth',
        lft: 'Lft',
        rgt: 'Rgt',
        mainImage: 'Main image',
        notes: 'Notes',
        rooms: 'Rooms',
        venueInformation: 'Venue Information',
        tags: 'Tags',
        type: 'Type',
        categories: 'Choose the categories for the booking option',

        selectText: {
          fixed: 'Fixed Price',
          per_person: 'Price person',
          followingVenue: 'Price follows the venue pricing',
          fixedNote: 'Ex: Booking option is priced 100SAR, the price will remain 100SAR no matter how many guests',
          perPersonNote: 'Ex: Booking option is priced 100SAR per person , the price will remain 100SAR per person',
          perPersonRangeNote: 'مثال: باقة سعرها ٢٠٠ ريال للشخص، إذا كان العدد بين ١٠٠-٢٠٠ شخص ويصير سعرها ١٨٠ ريال للشخص، إذا كان العدد بين ٢٠١-٥٠٠ شخص',
          fixedRangeNote: 'مثال: باقة سعرها ١٠,٠٠٠ إذا كان العدد بين ١٠٠-٢٠٠ شخص ويصير سعرها ٢٠,٠٠٠ إذا كان العدد بين ٢٠١-٥٠٠ شخص',
          yes: 'Yes',
          no: 'No',
          available: 'Available',
          hidden: 'Hidden',
          perPersonRange: 'Multiple price ranges, reflected by the number of guests',
          fixedRange: 'Multiple price ranges, reflected by the number of guests',
        },

        bookingOptionAvailable: 'Seen By Customers',
        bookingOptionHidden: 'Hidden From Customers',
        duplicateBookingOption: 'Copy Booking Option',
        copyCustomPricings: 'Copy Seasonal Pricing For Days',
        copyCustomPricingsNote: 'سيتم نسخ التسعيرات المخصصة للمواسم و الأيام كما هي من خيار الحجز المنسوخ',

        rejectConfirmPrompt: {
          title: 'Are You Sure You Want To Reject The Booking?',
          reject: 'Reject Booking',
        },

        // images
        bookingOptionImages: 'Booking Option Images',
        mainImage: 'Main image',
        images: 'Extra images',
        extraImagesCheckBoxHover: 'Please choose only the images you want to delete',
        deletedImageBrief: 'This image will be deleted when the request is approved',
        addedImageBrief: 'This image will be added when the request is approved',
        noneImageBrief: 'This image will remain when the request is approved',
        changeMainImage: 'Change main image',
        changeMainImageBrief: 'Upload new image to replace the current image',
        addExtraImages: 'Add extra images',
        addExtraImagesBrief: 'Upload new images to be added to the current images',
        uploadMainImage: 'Upload Main Image',
        uploadAddExtraImages: 'Upload Extra Images',
        uploadMainImageBrief: 'Upload new main image',
        uploadaddExtraImagesBrief: 'Upload new extra images',

        chooseEventTypes: 'Choose Event Types',
        chooseRooms: 'Choose Rooms That Are Included In The Booking Option',


        enableTax: 'Enable Tax for booking option',
        taxIncluded: {
          included: 'Included',
          notIncluded: 'Not Included',
        },

        addBookingOption: 'Add Booking Option',
        editBookingOption: 'Edit Booking Option',
        duplicateBookingOption: 'Duplicate Booking Option',
        deleteBookingOption: 'Delete Booking Option',
        delete: {
          promptHeader: 'Delete Booking Option',
          accept: 'Delete Booking Option',
          cancel: 'Cancel',
          message: '({bookingOptionName}) option will no longer be available on Eventful agent or Eventful app',
        },

        finalPricesTable: {
          header: 'Final Prices',
          pricing: 'Pricing',
          beforeTax: 'Before Tax',
          tax: 'Tax',
          totalPrice: 'Total Price',
          fixedPrice: 'Fixed Price',
          manPrice: 'Man Price',
          womanPrice: 'Woman Price',
          rangeManPrice: 'Man/Person Price',
          rangeWomanPrice: 'Hour/Woman Price',
          range: 'Range',
          to: 'To',
        },
        rangePricing: {
          alert: {
            header: 'هذه التسعيرات يتم احتسابها في حال وصل عدد الضيوف إلى عدد معين',
            line1: 'مثال: عندما يبحث العميل على مناسبة بعدد ضيوف 15، فيتم احتساب السعر الذي يقع فيه هذا العدد.',
            line2_per_person: 'عندما يكون المجال الأول يبدأ من 1 بسعر 70 ريال للرجل والمجال الثاني يبدأ من 10 بسعر 60 للرجل، سيكون السعر النهائي (15 × 60) [قبل الضريبة]',
            line2_fixed: 'عندما يكون المجال الأول يبدأ من 1 بسعر 3000 ريال للرجل والمجال الثاني يبدأ من 10 بسعر 2000 للرجل، سيكون السعر النهائي 2000 [قبل الضريبة]',
          },
          addRow: 'Add Row',
          menCount: 'Men Count',
          manPrice: 'Man Price',
          womenCount: 'Women Count',
          womanPrice: 'Woman Price',
        },
        eventStartDate: 'Booking Option Start Date',
        eventEndtDate: 'Booking Option End Date',
        eventDatesNote: 'This booking option will be hidden in the app when the time is over',
        eventDateShowReason: 'This section is visible because you chose one of these event types: {timeBasedEventTypes}',
      }
    },
    venueInformationForm: {
      sections: {
        pricings: 'General Pricing',
        commercialInformation: 'Commercial Information',
        additionalInformation: 'Additional Information'
      },
      name: 'Venue Name',
      namePlaceholder: 'Commercial Name will appeare in the app',
      city: 'City',
      address: 'Venue Address',
      addressPlaceholder: 'Ex: Al Khobar, Khozama neighborhood',
      about: 'About the venue',
      conditions: 'Booking Conditions',
      aboutPlaceholder: 'Ex: The venue is well known with the 5 stars rating',
      capacityDescription: 'Venue Capacity',
      capacityDescriptionPlaceholder: 'Ex: holds 2 - 50 people',
      phoneNumber: 'Call Number',
      phoneNumberPlaceholder: 'Ex: 5xxxxxxxx',
      whatsappNumber: 'Whatsapp Number',
      currentLogo: 'Current Logo',
      noLogo: 'No logo for the venue',
      uploadNewLogo: 'Upload new logo',
      depositType: 'Deposit Type',
      selectText: {
        fixed: 'Fixed',
        percentage: 'Percentage',
        taxable: 'Yes',
        nonTaxable: 'No',
      },
      deposit: 'Deposit Amount',
      percentageDeposit: 'Deposit Percentage %',
      percentageDepositPlaceholder: 'Deposit percentage that will be calculated from the total price',
      taxingStatus: 'Taxable?',
      commercialName: 'Commercial Registration Name',
      commercialRegistrationNumber: 'Commercial Registration Number',
      vatNumber: 'Vat Number',
      iban: 'IBAN Number',
      bankName: 'Bank Name',
      bankAccountHolderName: 'Bank Account Holder Name',
      tooltip: {
        disabledButtonVenueInformation: 'Change venue information to enable this button',
        saveVenueInformation: 'Save venue information'
      },
      phoneNumberNumbersCount: 'Please make sure that the number is correct',
      phoneNumberFormat: 'Please make sure that the phone number starts with 5',
    },
    bookingList:{
      no: 'Booking Number',
      name: 'Customer Name',
      phone_number: 'Phone Number',
      category: 'Status',
      booking_at: 'Booking Date',
      booked_by: 'Booking Source',
      source: 'Booking Source',
      price: 'Booking Price',
      vat: 'VAT',
      priceWithVat: 'Price With Vat',
      eventfulCommission: 'Eventful Commission',
      internal_services_total_price: 'Services Price',
      local_final_price: 'Final Price',
      internal_services_commission: 'Services commission'
    },
    bookingsLeads:{
      price: 'Price',
      eventDate: 'Event Date',
      bookedAt: 'Booked At',
      name: 'Customer Name',
      phoneNumber: 'Phone Number',
      blockBookingOption: 'Block Booking Option',
      showSpecialOffers: 'Special Offers',
      whatsapp: 'Whatsapp',
      showBooking: 'Show Booking',
      sendOfferToCustomer: 'Send Offer To Customer',
      dateIsBooked: 'Date is blocked',
      unblockDate: 'Unblock Date'
    },
    addBookingSidebar: {
      theNewBookingState: 'The New Booking State',
      confirmed: 'Confirmed',
      completed: 'Completed',
      cancelledWithRefund: 'Cancelled With Refund',
      cancelledWithoutRefund: 'Cancelled Without Refund',
      cancelationReason: 'Cancellation Reason',
      extraServices: 'Extra Services (with VAT)',
      isBookingForBusiness: 'Booking For Company',
      customerVatNumber: 'VAT Number',
      customerAddress: 'Customer Address',
      customerCommercialRegistrationNumber: 'Commercial Registration Number',
      deposit_amount_note: 'Deposit amount for this booking is: ',
      paid_at_place_amount: 'Paid Amount',
      agentPaymentNotes: 'Any notes? save them here...',
      agent_discount: 'Discount Amount',
      qosoor_discount: 'Discount Amount (Discount handled by Eventful)',
      blockBooking: 'To block the bookings in this date please click on block button',
      exportedReceipts: "Exported Receipts",
      bookingInfo: "Booking Info",
      showDetails: 'Show Details',
      showBooking: "Show Booking",
      editBooking: "Edit a Booking",
      addBooking: "Add a Booking",
      customerData: "Customer Data",
      customerName: "customer name",
      customerPhoneNumber: "Phone Number",
      customerEmail: "Email",
      customerIDNumer: "ID Number",
      customerNotes: "Customer Notes",
      eventType: 'Event Type',
      cancelationReason: 'cancelation Reason',
      bookingData: 'Booking Data',
      bookingOptions: 'Select Booking option',
      newBooking: 'New Booking',
      isSeparated: "(separated) The space of women is separated from that of men",
      isManyDays:"Many Days",
      menCount: "Men Count",
      WomenCount: "Women Count",
      peopleCount: "Guests Count",
      startDate: "Booking start date",
      endDate: 'Booking End Date',
      place: 'Places',
      cancelBooking: 'Cancel Booking',
      invoices: "Receipt",
      deposit: 'Deposit',
      paid_amount: 'Amount paid (to Eventful App)',
      remainingAmount: 'Remaining amount',
      total_price: 'Booking Option Price',
      percentage_tax: 'Tax percentage (%)',
      tax: 'Tax %',
      totalPriceWithTax: 'Total price after tax',
      dataBooking_states: 'Booking Status',
      bookingDate: 'Booking Date',
      bookingConfirmationAlert: 'Please accept the booking to view all the contact information and the other information',
      servicesNumber: 'There are ({servicesCount}) chosen services',
      bookingNumber: 'Booking Number',
      created_at: 'Date received Booking',
      paymentsData: 'Payment state',
      discount: 'Discount (Discount handled by Eventful)',
      price_after_discount: 'Price after discount',
      servicesTotalPrice: 'Services Prices',
      finalPrice: 'Final Price',
      booking_service_name: 'Service Name',
      booking_service_price: 'Price',
      services_details: 'Services Details',
      services_price: 'Services Price',
      local_final_price: 'Final Price',
      local_remaining_amount: 'Remaining amount',
      external_remaining_amount: 'Services remaining amount',
      external_remaining_amount_description: 'The amount that the customer should pay for the external services',
      contact_information: 'Contact with customer',
      whatsapp: 'Whatsapp',
      phone_call: 'Phone Call',
      change_booking_state: 'Change booking state to',
      current_booking_state: 'Current booking state',
      show_next_states: 'Show Next States',
      hide_next_states: 'Hide Next States',
      show_all_states: 'Show All States',
      hide_all_states: 'Hide States',
      setReceiptAsBookingOption: 'Set Receipt As Booking Option',
      bookingFinalValue: 'Booking Final Price',
      bookingDetails: 'Booking Details',
      bookingOption: 'Booking Option',
      bookingOptionDescription: 'Booking Option Description',
      bookingOptionPrice: 'Booking Option Price',
      bookingOptionNone: 'No Booking Option',
      services: 'Services',
      externalServices: 'External Services',
      internalServices: 'Internal Services',
      serviceName: 'Service Name',
      description: 'Description',
      price: 'Price',
      bookingInformation: 'Booking Information',
      receipt: 'Receipt',
      noExternalServices: 'No External Services',
      noInternalServices: 'No Internal Services',
      enableTaxForBooking: 'Enable Tax For Booking'
    },
    bookingPreview: {
      cancelationReason: 'Cancelation Reason',
      showDetails: 'Show Details',
      customerName: 'Customer Name',
      customerPhoneNumber: 'Phone Number',
      customerNotes: 'Customer Notes',
      bookingNumber: 'Booking Number',
      created_at: 'Book Date',
      eventType: 'Event Type',
      menCount: 'Men Count',
      WomenCount: 'Women Count',
      peopleCount: 'People Count',
      startDate: 'Booking Start Date',
      endDate: 'Booking End Date',
      cancelBooking: 'Cancel Booking',
      deposit:'Deposit',
      paid_amount:'Paied Amount',
      remainingAmount:'Remaining Amount',
      total_price:'Total Price',
      percentage_tax:'Tax Percentage %',
      tax:'Tax',
      totalPriceWithTax:'Total Price With Tax',
      discount: 'Discount',
      price_after_discount: 'Price After Discount',
      services_price: 'Services Price',
      local_final_price: 'Final Price',
      local_remaining_amount: 'Remaining Amount',
      external_remaining_amount: 'Services Remaining Amount',
      contact_information: 'Contact Information',
      whatsapp: 'Whatsapp',
      phone_call: 'Phone Call',
      changeBookingStateTo: 'Change Booking State To',
      currentBookingState: 'Current Booking State',
      bookingOption: 'Booking Option',
      bookingOptionDescription: 'Booking Option Description',
      bookingOptionPrice: 'Price',
      services: 'Services',
      externalServices: 'External Services',
      internalServices: 'Internal Services',
      serviceName: 'Service',
      description: 'Derscription',
      price: 'Price',
      bookingInformation: 'Booking Information',
      receipt: 'Recipt',
      enableTaxForBooking: 'Enable Tax For This Booking',
      bookingDate: 'Booking Date',
      moreInformation: 'More Information',
      additionalInformation: 'Additional Information',
      basePrice: 'Base Price',
      taxAmount: 'Tax Amount'

    },
    bookingOptionPreview: {
      description: 'Option Details',
      laterDepositAllowed: 'Later Deposit Payment Allowed',
      noBookingOptions: 'No booking options yet, you can add ones using the add button'
    },
    servicesForm: {
      addService: 'Add Service',
      editService: 'Edit Service',
      serviceImages: 'Service Images',
      name: 'Name',
      percentageTax: 'Tax Percentage',
      description: 'Description',
      choosePricingType: 'Pricing Type',
      fixedPrice: 'Fixed Price',
      firstInput: 'First Input Price',
      secondInput: 'Second Input Price',
      thirdInput: 'Third Input Price',
      AddImagesBrief: 'Add Multiple Images To Be Added To The Service',
      images: 'Services Images',
      imagesUpdload: 'Choose Service Images',
      deleteImageBtn: 'Delete This Image Now',
      save: 'Save',
      enableTax: 'Enable Tax For This Service',
      tooltip: {
        name: 'Service Name',
        pricingType: 'Service Pricing Type',
        percentageTax: 'Apply Tax For This Service',
        fixedPrice: 'Fixed Price',
        firstInput: 'First Input Price Value',
        secondInput: 'Second Input Price Value',
        thirdInput: 'Third Input Price Value',
        description: 'Service Description',
      },
      addBookingOptions: 'Add this service to booking options',
      groupPricing: {
        alert: {
          header: 'These prices will be considered if the guests count reach a certain number',
          line1: 'Example: When the primary price per man is 50 SAR, you can make a discount when the men count is more than 30 by entering 30 in (Men Count) and 40 in (Man Price)',
          line2: 'And you can give more discount when the men count is 40, by entering 40 in (Men Count) and 30 in (Man Price)',
        },
        primaryPrices: 'Primary Prices',
        groupPrices: 'Group Prices',
        addRow: 'Add Row',
        menCount: 'Men Count',
        manPrice: 'Man Price',
        womenCount: 'Women Count',
        womanPrice: 'Woman Price',
      }
    },
    bookingUpdatesList: {
      name: 'Customer Name',
      phone_number: 'Mobile number',
      category: 'Status',
      booking_at: 'Booking Date',
      start_date: 'Start date',
      from: 'Booking source',
      showAllDetails: 'Show all details',
    },
    contract: {
      percentage: 'A deficiency app will receive a commission at :percentage:% of every booking',
      applyCheck: 'Accept the terms and conditions',
      message: 'By pressing the I agree button, you will be able to start using the platform and prepare your services to display them in the Qusour app.',
      apply: 'Apply',
      vat_number: 'VAT Number',
      company_name: 'Company Name',
      commercial_record: 'Commercial Record',
      noContract: 'You don\t have a contract with Eventful, Please contact us to activate your account',
    },
    onBoarding: {
      venueInformation: 'Venue Information',
      thankyouHeader: 'Thank you for regestering for Eventfull app',
      thankyouLine1: 'Please wait a seconds to be redirected...',
      venueInformationForm: {
        name: 'Venue Name',
        arabic_name: 'Venue Arabic Name'
      },
      backToOnboarding: 'Go Back To Onboarding',
      services: 'Services',
      bookingOptions: 'Booking Options',
      next: 'Next',
      done: 'Done',
      complete: 'Complete',
      skip: 'Skip Step',
    },
    pages: {
      contract: "Business contract with Agent's palaces",
      calender: 'Calendar',
      calculator: 'Price Calculator',
      bookingsList: 'Bookings List',
      bookingsUpdates: 'Bookings Updates',
      PricingsBookingOptions: 'Pricings And Booking Options',
      dashboard: 'Dashboard',
      bookingsManagement: 'Bookings Management',
      customPricings: 'Custom Pricings',
      placeInfo: 'Place Information',
      onBoarding: 'OnBoarding',
      bookingsLeads: 'Booking Leads',
      discountCoupons: 'Discount Coupons',
      businessEvents: 'Business Events',
      venueSettlements: 'Settlements',
      reviews:'Reviews',
      questions: 'Q&As',
      business_marketing: 'Marketing Credit',
      businessEventConfirmationDetails: 'Please accept the booking to view all the contact information and the other information',
      BookingsCharts: 'Statistics',
      eventfulServices: {
        geoupHeader: 'Eventful Services',
        contactUs:'Contact us',
        contactUsDetails:'Thanks for your interest! You can communicate with us',
        thirdDimensionPhotography: '3D Photography',
        thirdDimensionPhotographyDetails: 'Our experts turn your vision into a 3D reality using advanced technology',
        manageSocialMedia: 'Manage Social Media',
        manageSocialMediaDetails: 'Social media management experts, customized strategies to enhance your presence on social media, increase engagement and follow-up',
        specialWebsite: 'Special Website',
        specialWebsiteDetails: 'Custom website design and development, smooth user experience and attractive user interface, your website reflects your identity and achieves your goals',
        specialApp: 'Special App',
        specialAppDetails: 'Developing customized and innovative mobile applications, designing an attractive user interface and a smooth user experience, contact us today to start developing your own application and achieving your vision',
        buttonToGo:'Contact',
      },
      BookingOptions: 'Booking Optioms',
      Services: 'Services',
    },
    calculator: {
      calc: 'Calculate',
      name: "Name",
      description: "Details",
      price: "Price",
      tax: "Tax",
      total: "Total",
      deposit: "Deposit",
      eventTyopes: "Service Details",
    },
    addOnForm: {
      addAddOn: 'Add Addon',
      editAddon: 'Edit Addon',
      name: 'Name',
      percentageEventfulCommission: 'Percentage Eventful Commission',
      percentageTax: 'Percentage Tax',
      description: 'Description',
      choosePricingType: 'Pricing Type',
      fixedPrice: 'Fixed Price',
      firstInput: 'First Input',
      secondInput: 'Second Input',
      thirdInput: 'Third Input',
      AddImagesBrief: 'Add an image to be added to addon images',
      images: 'Addon Images',
      deleteImageBtn: 'Remove this image now',
      save: 'save',
      enableTax: 'Enable Tax',
    },
    english: 'English',
    arabic: 'Arabic',
    editsDone: 'Edited',
    error: 'Error',
    addedDone: 'Added',
    updateDone: 'Updated',
    deleteDone: 'Deleted',
    dateRangePicker: {
      from: 'from',
      to: 'to',
      timePeriod: "Time Period",
      submit: "apply"
    },
    calendar: {
      bookingBlock: 'Booking Block',
      doYouWantToRemoveBookingBlock: 'Do You Want To Remove Booking Block ?',
    },
    statisticsCards: {
      bookings: {
        monthNameStatistics: 'Statistics for {monthName}',
        bookingsCount: '{bookingsCount} Booking',
        totalPriceBookings: 'Total {totalPriceBookings} SAR',
        knowMore: 'Know More',
        leadsCount: '{leadsCount} Lead Booking',
        totalLeadBookings: 'Value of {totalLeadBookings} SAR',
        convertIntoRealSales: 'Convert them into sales',
        marketingCredit: 'Your Marketing Credit',
        marketingCreditAmount: '{marketingCredit} SAR',
        knowMoreAboutBusinessMarketing: 'Know more about packages',
        sales: '{sales} ريال'
      },
      filters: {
        bookingDateTypeSelect: {
          bookingDate: 'تاريخ الحجز',
          eventDate: 'تاريخ المناسبة'
        }
      }
    },
    venueSettlements: {
      title: 'Settlement',
      startDate: 'Start Date',
      endDate: 'End Date',
      status: 'Status',
      bookingsCount: 'Bookings Count',
      month: 'Month',
      export: 'Export',
      completed_bookings_count: '# completed bookings',
      total_completed_final_price: 'total completed sales',
      total_paid_at_place: 'total paid at eventful',
      total_paid_at_eventful: 'total paid at place',
      total_paid_amount: 'total paid',
      settlementDetails: 'Suttlement Details',
      bookingsInSuttlement: 'Bookings In This Suttlement',
      statuses: {
        draft: 'Waiting for your approval',
        approved: 'Approved',
        rejected: 'Rejected',

      },
      booking: {
        bookingNumber: 'Booking Number',
        eventDate: 'Event Date',
        state: 'State',
        customerName: 'Customer Name'
      },
      confirmation: {
        title: 'Are You Sure?',
        rejectionReason: 'Rejection Reason',
        approvedBtn: 'Approve',
        rejectedBtn: 'Reject',
        approvedDetails: 'By approving we will transact the settlement amount to your bank account',
        rejectedDetails: 'Please provide us with the rejection reason to correct the settlement and resend it again',
        suttlementUpdatedTitle: 'Thank You!',
        suttlementUpdated: 'Suttlement was updated successfully',
      }
    },
    venueReviews: {
      booking:'Booking',
      BookingOption:'BookingOption',
      bookingType:'Booking type',
      addedAt:'Added at',
      account:'Account Linked To Customer',
      actualCustomer:'Actual Customer Linked With Booking',
      venue:'venue',
      bookingRating:'Booking Rating',
      bookingReview:'Booking Review',
      appRating:'App Rating',
      appReview:'App Review',
      status:'status',
      visibleToAgent:'not visible to customers',
      visibleToCustomers:'visible to customers',
      image:'image',
      bookingDetails:' Booking Details',
    },
    
    months: {
      January: 'January',
      February: 'February',
      March: 'March',
      April: 'April',
      May: 'May',
      June: 'June',
      July: 'July',
      August: 'August',
      September: 'September ',
      October: 'October',
      November: 'November',
      December: 'December',

    },

    business_marketing: {
      is_recommended: 'Recommended',
      limied_offer: 'Limited Offer',
      choose_package: 'Choose Package',
      can_not_buy_package: "You don't have enough balance",
      please_relogin: "Faild to retrive marketing credit, please sign in to the system again",
      available_credit_balance: 'Available Marketing Credit',
      for_recharge_contact_us: 'To charge your balance please contact us',
      no_enough_balance: "Sorry, You don't have enough balance",
      confirmation_prompt: {
        accept: "Confirm",
        cancel: 'Cancel',
        are_you_sure: 'Do you want to confirm your request?',
        package_name: 'Request title',
        current_balance: 'Current Credit',
        balance_after_request: 'Credit after confirmation',
        confirmation_note: 'We will contact you soon to facilitate the merketing campaigns, you can modify or cancel your request'
      }
    }
  },
  ar: {
    Dashboard: 'لوحة التحكم',
    Calendar: 'التقويم',
    PlaceInfo: 'بيانات المكان',
    priceCalculator: 'حاسبة الاسعار',
    newBooking: 'حجز جديد',
    Add: 'إضافة',
    edit: 'تعديل',
    search: 'بحث',
    block: 'حجب الحجوزات',
    acceptBooking: 'الموافقة على الحجز',
    rejectBooking: 'رفض الحجز',
    interested: 'مهتم',
    notInterested: 'غير مهتم',
    bookingCheckAccept: 'تحديد هذا اليوم كيوم متاح للحجز',
    bookingCheckReject: 'يتعارض مع حجز آخر',
    acceptBookingVisit: 'تاريخ المناسبة متاح للحجز',
    rejectBookingVisit: 'غير متاح',
    bookingCheckConfirmationAlert: 'عند تعارض هذا اليوم مع حجز آخر يعني أن عملاء تطبيق إيفنتفل لن يتمكنو من الحجز في هذا اليوم',
    BookingsList: 'قائمة الحجوزات',
    leads: 'العملاء المحتملين',
    discountCoupons: 'كوبونات الخصم',
    BookingsUpdates: 'تحديثات الحجوزات',
    Dashboard: 'لوحة التحكم',
    bookingSource: {
      Admin: 'إيفينتفل',
      SaaS: 'أيجنت بزنس',
      Agent: 'أيجنت',
      'Eventful App': 'إيفينتفل',
      'Web App': 'إيفينتفل',
    },
    Booking: {
      New: 'حجز جديد',
      ReserverName: 'اسم صاحب الحجز'
    },
    SAR: 'ريال',
    new: 'جديد',
    soon: 'قريبا',
    to: 'إلى',
    appName: "إيفينتفل آيجنت",
    appSlug: "الطريقة الأسهل لإدارة حجوزاتك",

    appNotifications:"الاشعارات",
    logout: "تسجيل الخروج",
    profile:'حسابي',
    full_name:'الاسم',
    seconds:' الثواني',
    email:'الايميل',
    phone_number:'رقم الجوال',
    updateInfo:'تحديث',
    verify:'تحقق',
    successChangingNumber:'تم تغيير رقم الهاتف بنجاح',
    vc_enterance:'من فضلك ادخل رمز التحقق',
    new: 'جديد',
    actions: 'العمليات',
    next: 'التالي',
    back: 'رجوع',
    save: 'حفظ',
    send: 'إرسال',
    close: 'إغلاق',
    delete: 'حذف',
    depositPaid: 'تم دفع العربون',
    eventfulDiscount: 'يوجد خصم يتحمله تطبيق إيفنتفل',
    agentDiscount: 'يوجد خصم يتحمله {venueName}',
    vatIncluded: 'شامل VAT',
    person: 'شخص',
    hour: 'ساعة',
    withDiscount: 'بعد الخصم',
    bookingsLineChart: 'حجوزات الفترة المحددة',
    bookingsStatesPieChart: 'توزيع الحجوزات حسب حالة الحجز',
    login: 'تسجيل الدخول',
    welcomeLogin: 'أهلا بك مجددا، قم بإدخال رقمك للدخول',
    phoneNumber:'رقم الجوال',
    phoneNumberPlaceholder: 'مثال: 5xxxxxxxx',
    verificationCode: 'رمز التحقق',
    password: 'كلمة المرور',
    chooseVenue: 'اختر الصالة',

    messages:{
      sentCode:'تم إرسال رمز التحقق إلى الرقم',
      enterCode:'قم بإدخال الرمز المرسل للمتابعة',
    },
    darkMode: 'الوضع الليلي',
    lightMode: 'الوضع الفاتح',
    buttons:{
      verify: "تحقق",
      changeNumber: "تعديل الرقم",
      sendCode: 'إرسال رمز التحقق',
      addBooking: 'حجز جديد',
      view:'عرض',
      edit:'تعديل',
      delete:'حذف',
      generateReceipt: 'إصدار فاتورة'
    },
    VueUploadMultipleImages: {
      popupText: 'هذه الصورة ستظهر كصورة أساسية في التطبيق',
      primaryText: 'الصورة الأساسية',
      markIsPrimaryText: 'عين هذه الصورة كأساسية',
      browseText: 'اختر العديد من الصور',
    },
    bookingBlock: 'حجب الحجوزات',
    dateButtons: {
      currentMonth: 'الشهر الحالي',
      lastMonth: 'الشهر السابق',
      currentWeek: 'الأسبوع الحالي',
      lastWeek: 'الأسبوع السابق',
      currentDay: 'اليوم',
      lastDay: 'الأمس',
      currentYear: 'السنة الحالية',
      lastYear: 'السنة السابقة',
    },
    blockDate: {
      header: 'حجب خيار الحجز',
      bodyCustomerDate: 'راح نرسل تنبيه لهذا العميل ان تاريخ ({date}) لخيار ({bookingOption}) محجوز، ولو في عميل آخر اختار نفس التاريخ وباقة الحجز بيصير يظهرله ان التاريخ لهذي الباقة محجوز',
      confirmation: 'هل تريد حجب خيار الحجز؟',
      note: 'متأكدين تبغون نحجب لكم التاريخ على هذي الباقة؟',
      block: 'نعم احجبوا التاريخ',
      cancel: 'لا خلاص'
    },
    unblockDate: {
      header: 'إتاحة باقة الحجز',
      bodyCustomerDate: 'تم حجب خيار الحجز لهذا التاريخ ({date}) من قبلكم، والحين عندكم الخيار لإتاحة خيار الحجز ({bookingOption}) للحجز من قبل العملاء.',
      confirmation: 'هل تريد إتاحة خيار الحجز؟',
      note: 'متأكدين تبغون تتيحون العملاء من حجز باقة الحجز؟',
      block: 'نعم اتيحوا الحجز',
      cancel: 'لا خلاص'
    },
    customerOffer: {
      header: 'إرسال عرض للعميل',
      notAvailableNow: 'هذه الميزة غير متوفرة حاليا',
      availableSoon: 'قريباً بتقدر ترسل عرضك لكل العملاء المهتمين في الحجز عندك',
      point1: 'راح تقدر ترسل لهم خصم',
      point2: 'تضيف لهم هدية عند الحجز',
      point3: 'وأية خدمات وأفكار أخرى عندك، بضغطة زر',
      ok: 'حسنًا',
    },
    booking: {
      customerName: 'اسم العميل',
      customerPhoneNumber: 'رقم الجوال',
      customer_vat_number: 'الرقم الضريبي',
      customer_commercial_registration_number: 'السجل التجاري',
      customer_address: 'العنوان',
      eventType: 'نوع المناسبة',
      bookingDate: 'تاريخ المناسبة',
      bookingFinalValue: 'قيمة الحجز',
      bookingFinalValueAfterDiscount: 'قيمة الحجز بعد الخصم ({discount} ريال)',
      showDetails: 'عرض التفاصيل',
      bookingOptionTitle: 'خيار الحجز',
      writeCancelationReason: 'أكتب سبب إلغاء الحجز',
      guestsCount: 'عدد الضيوف',
      bookingOption: {
        description: 'تفاصيل خيار الحجز',
        priceNoVat: 'السعر (قبل VAT)',
        detailedDescription: 'ايش يشمل السعر؟'
      },
      service: {
        serviceName: 'الإضافة',
        description: 'الوصف',
        price: 'السعر (قبل VAT)',
        taxAmount: 'قيمة VAT',
      }

    },
    invoice: {
      invoice: 'الفاتورة',
      general: 'عام',
      totalPrice: 'المبلغ كامل',
      eventfulPercentage: 'عمولة إيفنتفل',
      yourNet: 'الصافي لكم',
      details: 'التفاصيل',
      option: {
        optionPrice: 'سعر باقة الحجز',
        beforeVat: 'قبل الضريبة',
        vat: 'الضريبة',
        afterVat: 'بعد الضريبة',
        discount: 'قيمة التخفيض',
        priceAfterDiscount: 'بعد التخفيض',
        discountSource: 'مصدر التخفيض',
        eventful: 'إيفينتفل',
        discountBy: 'الخصم عن طريق',
      },
      services: {
        servicesPrice: 'سعر الإضافات',
        beforeVat: 'قبل الضريبة',
        vat: 'الضريبة',
        afterVat: 'بعد الضريبة',
        discount: 'قيمة التخفيض',
        priceAfterDiscount: 'بعد التخفيض'
      },
      paymentDetails: {
        paymentDetails: 'تفاصيل الدفع',
        collectingFromCustomer: 'المبلغ المطلوب من العميل',
        collectedFromEventful: 'تم تحصيله في ايفينتفل',
        collectedFromPlace: 'تم تحصيله لديكم',
        remainingToBeCollected: 'المبلغ المتبقي لتحصيله من العميل',
        paymentNotesByAgent: 'ملاحظات على الدفع'
      }

    },
    customPricings: {
      customPricingsFor: 'التسعيرات الخاصة بـ',
      title: 'اسم التسعيرة الموسمية',
      startDate: 'تاريخ بداية التسعيرة الموسمية',
      endDate: 'تاريخ نهاية التسعيرة الموسمية',
      pricingIsSeasonal: 'هل التسعيرة موسمية؟',
      seasonal: 'موسمية',
      nonSeasonal: 'غير موسمية',
      pricingType: 'طريقة تسعير الباقة',
      fixed: 'ثابتة',
      variable: 'متغيرة',
      manPrice: ' سعر الشخص / سعر الرجل / سعر الكبار (حسب المناسبة)',
      womanPrice: ' سعر الساعة / سعر المرأة / سعر الطفل (حسب المناسبة)',
      peoplePrice: 'سعر الشخص',
      menCount: 'عدد الرجال',
      womenCount: 'عدد النساء',
      peopleCount: 'عدد الأشخاص',
      basePrice: 'ايش تبغى السعر؟',
      day: 'اليوم',
      daysPricings: 'التسعير حسب اليوم',
      seasonalPricings: 'التسعيرات و الخصومات الموسمية',
      deletePricing: 'حذف التسعيرة',
      areYouSureYouWantToDelete: 'هل انت متأكد أنك تريد حذف ',
      edit: 'تعديل',
      add: 'إضافة تسعيرة موسمية جديدة',
      daysValidationError: 'الرجاء تحديد اليوم والسعر لجميع التسعيرات، أو قم بحذف تسعيرة اليوم',
      pricingTypefixed : 'تسعيرة ثابتة',
      pricingTypevariable : 'تسعيرة متغيرة',
      pricingTypeper_person : 'تسعيرة متغيرة',
      appliedPricing: 'تسعيرة مطبقة',
      notAppliedPricing: 'تسعيرة غير مطبقة',
      calendar: {
        formTitle: 'ادخل معايير الحجز لتظهر لك الاسعار بحسب ذلك',
        formDescription: 'الاسعار الظاهرة في التقويم هي كما تظهر للعملاء عند ادخال معايير الحجز',
        update: 'تحديث التقويم',
        todayPricings: 'تسعيرات اليوم'
      },
      validation: {
        TitleRequired: 'العنوان حقل مطلوب',
        VariableBasePriceRequired: 'الحقل مطلوب ',
        BasePriceRequired: 'السعر الأساسي حقل مطلوب',
        VariableDayPriceRequired: 'الرجاء ادخال قيمة الحقل',
        DayName: 'يوم',
        FixedDayPriceRequired: 'السعر الأساسي',
      }
    },
    rooms: 'الأقسام و المساحات',
    venueInformation: 'بيانات التاجر',
    editRoomForm: {
      addRoom: 'إضافة قسم',
      editRoom: 'تعديل قسم',
      name: 'اسم القسم/المساحة',
      description: 'الوصف',
      detailedDescription: 'تفاصيل الوصف',
      size: 'سعة الغرفة (عدد الضيوف)',
      count: 'كم مرة ممكن تنحجز باليوم؟',
      genderType: 'نوع الضيوف',
      gender_type_radio: {
        men: 'رجال',
        women: 'نساء',
        both: 'كلاهما',
      },
      facilities: 'الملحقات',
    },
    discountCouponForm: {
      addDiscountCoupon: 'إضافة كوبون خصم جديد',
      editDiscountCoupon: 'تعديل كوبون خصم',
      specificCustomer: 'ستقوم بانشاء كوبون خصم مخصص للعميل ({customerName}) وباقة الحجز ({bookingOption}) وعند حجزه واستخدامه للكود سيتم تطبيق الخصم',
      specificCustomerBeforeSaveNote: 'عند حفظ الكوبون سيظهر في قائمة صفحة كوبونات الخصم',
      name: 'عنوان الكوبون',
      generalInformation: 'المعلومات العامة',
      code: 'كود الخصم',
      maxUsageCount: 'عدد الكوبونات',
      dateRangeTitleDisabled: 'هل الكوبون محدد بتاريخ بداية وتاريخ نهاية؟',
      dateRangeTitleEnabled: 'تاريخ صلاحية كوبون الخصم',
      dateNote: 'عند انتهاء فترة التاريخ ستنتهي صلاحية الكوبون بشكل تلقائي',
      startDate: 'تاريخ بداية الكوبون',
      endDate: 'تاريخ نهاية الكوبون',
      pricing: 'قيمة الخصم وشروطه',
      minPrice: 'سعر قيمة الحجز الأدنى',
      minPriceDescText: 'أقل قيمة للحجز التي يتم تطبيق الخصم عليها',
      maxPrice: 'أعلى قيمة للخصم',
      maxPriceDescText: 'أكبر قيمة خصم بالريال عندما يكون كوبون الخصم بالنسبة المئوية من سعر الحجز',
      discount: 'قيمة الخصم (من السعر النهائي شامل الضريبة)',
      discountInPercentage: 'النسبة المئوية من قيمة الحجز',
      discountInRiyals: 'قيمة الخصم بالريال',
      discountType: 'نوع الخصم',
      fixedDiscount: 'قيمة ثابتة',
      percentageDiscount: 'نسبة مئوية من قيمة الحجز',
      targeting: 'الاستهداف',
      targetBookingOptions: 'استهدف باقات حجز مخصصة',
      targetCustomer: 'استهدف عملاء مخصصين',
      specificTargetedCustomerNote: 'ظهر قسم استهداف عملاء مخصصين لأنه تم انشاء الكوبون من صفحة العملاء المحتملين، في حال تعطيل الكوبون من العميل لن تتمكن من التراجع ويتوجب عليك إضافة كوبون جديد من صفحة العملاء المحتملين',
      sendOffer: 'إرسال العرض',
      editOffer: 'تعديل العرض',
      deleteOffer: 'حذف العرض',

    },
    discountCouponsList: { // todo: add the English Version
      name: 'عنوان الكوبون',
      code: 'كود الخصم',
      discount: 'قيمة الخصم',
      dateRange: 'كوبون بفترة محددة',
      isValid: 'صلاحية الكوبون',
      yes: 'نعم',
      no: 'لا',
      valid: 'صالح للاستخدام',
      inValid: 'غير صالح للاستخدام',
      deleteCouponHeader: 'حذف كوبون الخصم | ',
      deleteCouponConfirmation: 'هل أنت متأكد أنك تريد حذف كوبون الخصم؟',
    },
    businessEventsList: { // todo: add the English Version
      name: 'المناسبة',
      budget: 'الميزانية',
      date: 'التاريخ',
      description: 'الوصف',
      status: 'الحالة',
      businessEventDetails: 'تفاصيل المناسبة',
      eventDescription: 'تفاصيل المناسبة',
      guestsCount: 'عدد الضيوف',
      eventLocation: 'مكان المناسبة',
      proposals: 'أرسل عرضك (تقدر ترسل أكثر من عرض)',
      attachment: 'المرفق',
      attachments: 'المرفقات',
      contactUsNote: 'عندك استفسار او اسئلة بخصوص الطلب؟',
      contactUs: 'تواصل معنا',
      proposalForm: {
        sendProposal: 'إرسال عرض',
        name: 'العنوان',
        price: 'السعر (قبل الضريبة)',
        details: 'ملاحظات العرض (اختياري)',
        attachments: 'المرفقات',
      }

    },
    bookingConfirmation: {
      rejection: {
        why: 'لماذا تريد إلغاء الحجز؟',
        unavailable_venue_in_date: 'جميع خدماتنا غير متاحة بتاريخ ({date})',
        wrong_count: 'عدد الضيوف غير صحيح',
        other: 'سبب آخر',
        chooseAvailableDate: 'اختر التواريخ المتاحة',
        chooseAvailablecount: 'أدخل عدد الضيوف المناسب',
        writeRejectionReason: 'ما هو السبب الآخر؟',
        pleaseSelectSuggestedDates: 'الرجاء اختيار تواريخ مقترحة',
        pleaseFillFormWithInformation: 'الرجاء ادخال البيانات',
        unavailable_booking_option_in_date: 'الخيار ({option_name}) غير متاح بتاريخ ({date})',
        chooseAlternativeBookingOptions: 'اختر خيارات حجز بديلة',
        doYouWantToBlockTheDate: 'هل تريد حجب تاريخ ({date})؟',
        block_venue_in_date: 'أريد عدم استقبال حجوزات بتاريخ ({date}) على كل الخيارات في ({venue_name})',
        block_option_in_date: 'أريد عدم استقبال حجوزات بتاريخ ({date}) على خيار حجز ({option_name}) فقط',
        rejection_alert: 'سنقوم بإرسال الرفض الى العميل، مع السبب والحل المقترح الي ذكرته هنا',
        option_and_date_will_be_blocked: 'سيتم حجب التاريخ ({date}) للخدمة ({option_name}) لتجنب تكرار المشكلة',
        date_will_be_blocked: 'سيتم حجب التاريخ ({date}) على كافة الخدمات لتجنب تكرار المشكلة',
        do_not_do_it_again: 'سيتم تطبيق رسوم الخدمة في حال تكرار الالغاء',
        yes_block_it: 'موافق، احجب التاريخ',
        do_not_block_it: 'لا تحجبه، خليه متاح',
        rejection_reason_will_be_sent_to_customer: 'سيتم ارسال سبب الرفض إلى العميل',
        send_rejection_reason: 'إرسال',
        rejection_reason_placeholder: 'الحد الأدنى 5 أحرف',
      }
    },
    home: {
      chooseBookingsStates: 'اختر حالات الحجوزات',
      welcome: 'أهلا',
      welcomeAgain: "أهلا بك مجددا في إيفينتفل آيجنت",
      CompletedReservations: 'الحجوزات المكتملة',
      bookingOptions: "خيارات و باقات الحجز",
      services: 'الخدمات الإضافية',
      name: 'اسم الباقة',
      state: 'الحالة',
      pending: 'قيد الانتظار',
      rejected: 'مرفوض',
      description: 'الوصف المختصر',
      interiorSpaces: 'مساحات المكان الداخلية',
      gallery: 'صور المكان',
      upcomingBookings: 'حجوزات قادمة',
      bookingsWaitingForYourAction: 'حجوزات بانتظار تأكيدك',
      bookingsWaitingForCustomerAction: 'حجوزات بانتظار تأكيد العميل',
      lastAddedBookings: 'أجدد الحجوزات',
      cancelledBookings: 'حجوزات ملغية',
      endedBookings: 'حجوزات منتهية',
      businessEvents: 'طلبات الشركات',
      BookingsNumbers: 'أرقام الحجوزات',
      BookingsNumbersDesc: 'تظهر الأرقام بناء على الحالات التي تم اختيارها خلال الفترة الزمنية المحددة',
      BookingsCount: 'عدد الحجوزات',
      totalPrice: 'مجموع المبيعات',
      totalPriceWithTax: 'مجموع المبيعات مع الضريبة',
      totalPaidAmount: 'مجموع المدفوع',
      totalEventfulCommission: 'مجموع عمولة إيفينتفل',
      changeRequest: 'طلب تعديل',
      createRequests: 'طلب إضافة',
      submitRequest: 'إرسال الطلب',
      editRequest: 'تعديل الطلب',
      noteToAgent: 'بضغطك على زر إرسال سيتم إرسال طلب التعديل إلى فريق إيفينتفل لضمان صحة البيانات و من ثم اعتماده بعد مراجعته خلال ٢٤ ساعة.',
      eventType: 'نوع المناسبة',
      yes: 'نعم',
      pendingDescription: 'لدى خيار الحجز هذا طلب تعديل مسبق، يمكنك تعديله',
      newPendingDescription: 'خيار الحجز هذا لن يظهر للعملاء، بانتظار تأكيد الأدمن',
      newRejectedDescription: 'تم رفض خيار الحجز، بإمكانكم تعديله ورفعه من جديد',
      changeRequests: 'طلبات التعديل',
      newBookingOptions: 'خيارات الحجز الجديدة',
      customPricings: 'التسعيرات',
      optionPricings: 'تسعيرات خيار الحجز',
      seasonalPricings: 'التسعيرات و الخصومات الموسمية',
      daysPricings: 'تسعيرات الأيام',
      editBookingOptionPrompt: {
        accept: 'متابعة',
        cancel: 'إلغاء',
        message: 'لديك طلب تعديل سابق بانتظار الموافقة، هل تريد التعديل عليه؟'
      },
      serviceForm: {
        duplicateService: 'نسخ الخدمة',
        deleteService: 'حذف الخدمة',
        delete: {
          promptHeader: 'هل تريد حذف الخدمة؟',
          accept: 'حذف الخدمة',
          cancel: 'إلغاء',
          message: 'لن تظهر ({serviceName}) في إيفينتفل ايجنت أو التطبيق بعد حذفها'
        }
      },
      servicesTable: {
        noServicesAvailable: 'لا يوجد خدمات في الوقت الحالي، يمكنكم إضافة خدمات عن طريق زر الإضافة',
      },
      editBookingOptionForm: {
        //general information
        generalInformation: 'المعلومات العامة',
        name: 'اسم باقة الحجز',
        bookingsSize: 'Bookings size',
        availability: 'Availability',
        availabilityType: 'Availability type',
        description: 'وصف مختصر للباقة (اختياري)',
        detailedDescription: 'ايش يشمل السعر؟',
        availability: 'هل يظهر خيار الحجز للعملاء؟',

        // deposits
        deposit: 'Deposit',
        depositType: 'Deposit type',
        percentageDeposit: 'Percentage deposit',
        laterDepositPaymentEnabled: 'Later deposit payment enabled',

        // pricing
        enableLaterPayment: 'تفعيل الدفع لاحقًا',
        thePrice: 'السعر',
        pricing: 'التسعير',
        price: 'السعر الثابت',
        manPrice: 'سعر الشخص / سعر الرجل / سعر الكبير (حسب المناسبة)',
        womanPrice: 'سعر الساعة / سعر المرأة / سعر الطفل (حسب المناسبة)',
        minMaxCountTitle: 'هل يوجد حد أدنى أو أعلى لباقة الحجز هذي؟',
        minimumMenCount: 'أقل عدد تقبله لـ (عدد الرجال) أو (عدد الأشخاص) أو (عدد الأطفال) حسب المناسبة',
        maximumMenCount: 'أكثر عدد تقبله لـ (عدد الرجال) أو (عدد الأشخاص) أو (عدد الأطفال) حسب المناسبة',
        minimumWomenCount: 'أقل عدد تقبله لـ (عدد النساء) أو (عدد الساعات) أو (عدد الكبار) حسب المناسبة',
        maximumWomenCount: 'أكثر عدد تقبله لـ (عدد النساء) أو (عدد الساعات) أو (عدد الكبار) حسب المناسبة',
        personPrice: 'سعر الشخص',
        weekendPrice: 'زيادة سعر نهاية الاسبوع',
        minimumPrice: 'الحد الأدنى للسعر (أقل فاتورة تقبلها)',
        pricingType: 'طريقة تسعير الباقة',
        taxingIncluding: 'السعر المُدخل',
        taxingStatus: 'تطلع الضريبة؟',
        taxIsAppliedInVenue: 'الضريبة مطبقة على المكان',
        taxIsNotAppliedInVenue: 'الضريبة غير مطبقة على المكان',
        taxIsAppliedInVenueTooltip: 'اختياركم لاتباع المكان يعني أن حالة الضريبة ستتغير بتغير حالة ضريبة المكان',
        followPlace: 'اتبع المكان',
        taxable: 'نعم',
        nonTaxable: 'لا',
        servicesFixedPrice: 'السعر الثابت للخدمات',
        servicesGroups: 'Services groups',
        servicesPerManPrice: 'سعر الخدمة للرجال',
        servicesPreWomanPrice: 'سعر الخدمة للنساء',
        servicesPerPersonPrice: 'سعر الخدمة للشخص',
        servicesWeekendExtraPrice: 'سعر الخدمات في نهاية الأسبوع',
        hasSeasonalPricing: 'هل السعر يختلف حسب التاريخ/الموسم أو عندك خصومات؟',
        hasDaysPricing: 'هل السعر يختلف حسب اليوم؟',
        sizeBased: 'حسب العدد',
        roomsBased: 'حسب المساحات',
        bookingOptionType: 'نوع خيار الحجز',
        size: 'عدد المرات',
        sizeNote: 'عدد المرات التي يمكن أن يحجز فيها خيار الحجز في الوقت الواحد',

        // discounts
        discounts: 'الخصومات',
        discountType: 'نوع الخصم',
        percentageDiscount: 'الخصم بالنسبة',
        fixedDiscount: 'الخصم بالسعر',

        taxApplied: 'السعر الإجمالي بعد الضريبة إذا كانت مطبقة على باقة الحجز',

        // other information
        otherInformation: 'Other Information',
        eventTypes: 'Event types',
        services: 'Services',
        externalServices: 'External services',
        internalServices: 'Internal services',
        depth: 'Depth',
        lft: 'Lft',
        rgt: 'Rgt',
        notes: 'Notes',
        rooms: 'Rooms',
        tags: 'Tags',
        type: 'Type',
        categories: 'اختر التصنيفات المناسبة لباقة الحجز',

        selectText: {
          fixed: 'سعر ثابت (تسعيرة وحدة)',
          per_person: 'حسب العدد (تسعيرة وحدة مهما كان العدد)',
          followingVenue: 'تبعًا لنوع تسعير المكان',
          fixedNote: 'مثال: باقة سعرها ١٠,٠٠٠، مهما كان العدد، راح يبقى سعرها ١٠,٠٠٠ ريال',
          perPersonNote: 'مثال: باقة سعرها الشخص فيها ٢٠٠ ريال، مهما كان العدد، راح ينحسب الشخص بـ ٢٠٠ ريال.',
          perPersonRangeNote: 'مثال: باقة سعرها ٢٠٠ ريال للشخص، إذا كان العدد بين ١٠٠-٢٠٠ شخص ويصير سعرها ١٨٠ ريال للشخص، إذا كان العدد بين ٢٠١-٥٠٠ شخص',
          fixedRangeNote: 'مثال: باقة سعرها ١٠,٠٠٠ إذا كان العدد بين ١٠٠-٢٠٠ شخص ويصير سعرها ٢٠,٠٠٠ إذا كان العدد بين ٢٠١-٥٠٠ شخص',
          yes: 'نعم',
          no: 'لا',
          available: 'إظهار باقة الحجز',
          hidden: 'إخفاء باقة الحجز',
          perPersonRange: 'أكثر من تسعيرة حسب العدد (تتغير كل ما زاد العدد)',
          fixedRange: 'أكثر من تسعيرة ثابتة (تتغير كل ما زاد العدد)',
        },

        bookingOptionAvailable: 'تظهر للعملاء',
        bookingOptionHidden: 'مخفية عن العملاء',
        duplicateBookingOption: 'نسخ باقة الحجز',
        copyCustomPricings: 'نسخ التسعيرات الموسمية وتسعيرات الأيام',
        copyCustomPricingsNote: 'سيتم نسخ التسعيرات المخصصة للمواسم و الأيام كما هي من خيار الحجز المنسوخ',

        rejectConfirmPrompt: {
          title: 'هل أنت متأكد من رفض الحجز؟',
          reject: 'رفض الحجز',
        },
        // images
        bookingOptionImages: 'صور باقة الحجز',
        mainImage: 'الصورة الأساسية',
        images: 'الصور الإضافية',
        extraImagesCheckBoxHover: 'اختر الصور التي تريد إرسال طلب لحذفها',
        deletedImageBrief: 'هذه الصورة سوف تُحذف عند الموافقة على طلب التعديل',
        addedImageBrief: 'هذه الصورة سوف تٌضاف عند الموافقة على طلب التعديل',
        noneImageBrief: 'هذه الصورة ستبقى كما هي عند الموافقة على طلب التعديل',
        changeMainImage: 'تغيير الصورة الأساسية',
        changeMainImageBrief: 'قم برفع صورة جديدة لتعديل الصورة الحالية',
        addExtraImages: 'إضافة صور إضافية',
        addExtraImagesBrief: 'قم برفع عدة صور جديدة لتتم إضافتها إلى الصور الحالية',
        uploadMainImage: 'الصورة أساسية',
        uploadAddExtraImages: 'الصور الإضافية',
        uploadMainImageBrief: 'قم برفع صورة أساسية لخيار الحجز',
        uploadaddExtraImagesBrief: 'قم برفع صور إضافية لخيار الحجز',


        chooseEventTypes: 'اختر أنواع المناسبات لهذه الباقة',
        chooseRooms: 'اختر الأقسام و المساحات التي يتضمنها خيار الحجز',

        enableTax: 'تفعيل الضريبة على باقة الحجز ',
        taxIncluded: {
          included: 'شامل الضريبة',
          notIncluded: 'غير شامل الضريبة',
        },

        addBookingOption: 'إضافة باقة حجز',
        editBookingOption: 'تعديل باقة الحجز',
        duplicateBookingOption: 'نسخ باقة الحجز',
        deleteBookingOption: 'حذف باقة الحجز',
        branches: 'الفروع',
        delete: {
          promptHeader: 'حذف باقة الحجز',
          accept: 'حذف باقة الحجز',
          cancel: 'إلغاء',
          message: 'لن تظهر ({bookingOptionName}) في إيفينتفل ايجنت أو التطبيق بعد حذفها',
        },

        finalPricesTable: {
          header: 'الأسعار النهائية (كما سيتم تطبيقها)',
          pricing: 'التسعير',
          beforeTax: 'قبل الضريبة',
          tax: 'الضريبة',
          totalPrice: 'السعر النهائي',
          fixedPrice: 'سعر ثابت',
          manPrice: 'سعر الشخص/الرجل/الخبير',
          womanPrice: 'سعر الساعة/المرأة/الطفل',
          rangeManPrice: 'سعر الشخص/الرجل',
          rangeWomanPrice: 'سعر الساعة/المرأة',
          range: 'المجال',
          to: 'إلى',
        },
        rangePricing: {
          alert: {
            header: 'هذه التسعيرات يتم احتسابها في حال وصل عدد الضيوف إلى عدد معين',
            line1: 'مثال: عندما يبحث العميل على مناسبة بعدد ضيوف 15، فيتم احتساب السعر الذي يقع فيه هذا العدد.',
            line2_per_person: 'عندما يكون المجال الأول يبدأ من 1 بسعر 70 ريال للرجل والمجال الثاني يبدأ من 10 بسعر 60 للرجل، سيكون السعر النهائي (15 × 60) [قبل الضريبة]',
            line2_fixed: 'عندما يكون المجال الأول يبدأ من 1 بسعر 3000 ريال للرجل والمجال الثاني يبدأ من 10 بسعر 2000 للرجل، سيكون السعر النهائي 2000 [قبل الضريبة]',
          },
          addRow: 'Add Row',
          menCount: 'Men Count',
          manPrice: 'Man Price',
          womenCount: 'Women Count',
          womanPrice: 'Woman Price',
        },
        eventStartDate: 'تاريخ بداية باقة الحجز',
        eventEndDate: 'تاريخ نهاية باقة الحجز',
        eventDatesNote: 'باقة الحجز ستختفي من التطبيق تلقائيٌا عند انتهاء تاريخ النهاية',
        eventDateShowReason: 'يظهر قسم تاريخ البداية والنهاية لأنكم اخترتم واحد من هذه الخيارات كنوع للمناسبة: ({timeBasedEventTypes})',
      }
    },
    venueInformationForm: {
      sections: {
        pricings: 'التسعيرات العامة',
        commercialInformation: 'البيانات التجارية',
        additionalInformation: 'ملاحظات إضافية'
      },
      name: 'الاسم الذي يظهر للعملاء',
      namePlaceholder: 'الاسم التجاري الذي سيظهر في التطبيق',
      city: 'المدينة',
      address: 'عنوان المكان',
      conditions: 'شروط الحجز',
      addressPlaceholder: 'مثال: الخبر، حي الخزامى',
      about: 'معلومات عن مقدم الخدمة',
      aboutPlaceholder: 'مثال: أرقى فندق 5 نجوم في مدينة الخبر يتميز بموقعه الاستراتيجي',
      capacityDescription: 'كم يكفي شخص؟',
      capacityDescriptionPlaceholder: 'مثال: يتسع ل 2 - 50 شخص',
      phoneNumber: 'رقم الاتصال',
      phoneNumberPlaceholder: 'مثال: 5xxxxxxxx',
      whatsappNumber: 'رقم الواتس اب',
      currentLogo: 'شعار المكان',
      noLogo: 'لا يوجد شعار للقاعة',
      uploadNewLogo: 'قم برفع شعار جديد',
      depositType: 'نوع العربون؟',
      selectText: {
        fixed: 'ثابت',
        percentage: 'نسبة مئوية',
        taxable: 'نعم',
        nonTaxable: 'لا',
      },
      deposit: 'قيمة العربون الثابتة',
      percentageDeposit: 'نسبة العربون %',
      percentageDepositPlaceholder: 'قيمة النسبة التي يحتسب منها العربون من قيمة الحجز',
      taxingStatus: 'تطلع الضريبة؟',
      commercialName: 'اسم المنشأة في السجل التجاري',
      commercialRegistrationNumber: 'رقم السجل التجاري',
      vatNumber: 'الرقم الضريبي',
      iban: 'رقم الايبان للحساب البنكي',
      bankName: 'اسم البنك',
      bankAccountHolderName: 'اسم صاحب الحساب',
      notesForAdmin: 'ملاحظات إضافية',
      notesForAdminPlaceholder: 'اكتب أية ملاحظات لإدارة تطبيق ايفينتفل',
      tooltip: {
        disabledButtonVenueInformation: 'قم بتغيير الخانات في بيانات القاعة لتفعيل زر الحفظ',
        saveVenueInformation: 'حفظ بيانات القاعة'
      },
      phoneNumberNumbersCount: 'تأكد أن عدد الأرقام صحيح',
      phoneNumberFormat: 'تأكد أن الرقم يبدأ بالرقم 5',
    },
    bookingList:{
      no: 'رقم الحجز',
      name: 'اسم العميل',
      phone_number: 'رقم الهاتف',
      category: 'حالة الحجز',
      booking_at: 'تاريخ الحجز',
      booked_by: 'مصدر الحجز',
      source: 'مصدر الحجز',
      price: 'سعر الحجز',
      vat: 'الضريبة',
      priceWithVat: 'السعر مع الضريبة',
      eventfulCommission: 'عمولة إيفينتفل',
      internal_services_total_price: 'سعر الخدمات',
      local_final_price: 'السعر النهائي',
      internal_services_commission: 'عمولة الخدمات'
    },
    bookingsLeads:{
      price: 'السعر',
      eventDate: 'تاريخ المناسبة',
      bookedAt: 'تاريخ الحجز',
      name: 'اسم العميل',
      phoneNumber: 'رقم الجوال',
      blockBookingOption: 'حجب خيار الحجز',
      showSpecialOffers: 'العروض المرسلة',
      whatsapp: 'واتس آب',
      showBooking: 'تفاصيل الحجز',
      sendOfferToCustomer: 'أرسل عرض للعميل',
      dateIsBooked: 'التاريخ محجوز',
      unblockDate: 'إتاحة الحجز'
    },
    addBookingSidebar: {
      theNewBookingState: 'حالة الحجز الجديد',
      confirmed: 'مؤكد',
      completed: 'مكتمل',
      cancelledWithRefund: 'ملغي مع استرداد المبلغ',
      cancelledWithoutRefund: 'ملغي بدون استرداد المبلغ',
      cancelationReason: '',
      extraServices: 'خدمات إضافية (شامل الضريبة)',
      isBookingForBusiness: 'الحجز باسم شركة',
      customerVatNumber: 'رقمها الضريبي',
      customerAddress: 'عنوان العميل',
      customerCommercialRegistrationNumber: 'السجل التجاري',
      deposit_amount_note: 'قيمة العربون لهذا الحجز: ',
      paid_at_place_amount: 'المبلغ المدفوع',
      agentPaymentNotes: 'عندك ملاحظات تخص الحجز؟ احفظها هنا',
      blockBooking: 'لحجب الحجوزات في هذا اليوم الرجاء الضغط على زر حجب الحجوزات',
      exportedReceipts: "الفواتير المصدرة",
      bookingInfo: "بيانات الحجز",
      showDetails: 'عرض التفاصيل',
      showBooking: "عرض حجز",
      editBooking: "تعديل حجز",
      addBooking: "إضافة حجز",
      customerData: "بيانات العميل",
      customerName: "إسم العميل",
      customerPhoneNumber: "رقم الهاتف",
      customerEmail: "البريد الالكتروني",
      customerIDNumer: "رقم الهوية",
      customerNotes: "ملاحظات العميل",
      eventType: 'نوع المناسبة',
      cancelationReason: 'سبب الإلغاء',
      bookingData: 'بيانات الحجز',
      bookingOptions: 'تحديد خيار الحجز',
      newBooking: 'حجز جديد',
      isSeparated: "حجز النساء مفصول عن حجز الرجال",
      isManyDays:"أكثر من يوم",
      menCount: "عدد الرجال",
      WomenCount: "عدد النساء",
      peopleCount: "عدد الاشخاص",
      startDate: 'تاريخ بداية الحجز',
      endDate: 'تاريخ نهاية الحجز',
      place: 'المكان',
      cancelBooking: 'إلغاء الحجز',
      invoices: "الفواتير",
      deposit:'العربون',
      paid_amount:'المبلغ المدفوع (لتطبيق إيفينتفل)',
      remainingAmount:'المبلغ المتبقي',
      total_price:'سعر خيار الحجز',
      percentage_tax:'نسبة الضريبة ٪   ',
      tax:'الضريبة %',
      totalPriceWithTax:'سعر خيار الحجز بعد الضريبة',
      dataBooking_states:'حالة الحجز',

      bookingNumber: 'رقم الحجز',
      created_at: 'تاريخ استلام الحجز',
      bookingDate: 'تاريخ المناسبة',
      bookingConfirmationAlert: 'اقبل الحجز لكي تظهر لك بيانات التواصل و بيانات الحجز كاملة',
      bookingVisitAlert: 'العميل وده يزوركم ويعاين المكان بالتاريخ الموضح تحت، في حال قبولكم للحجز راح نرسل تنبيه للعميل ان تاريخ المناسبة متاح للحجز والعكس للرفض',
      visitDetails: 'تفاصيل الزيارة',
      servicesNumber: 'يوجد ({servicesCount}) إضافات مختارة',

      paymentsData: 'حالة الدفع',
      discount: 'الخصم (خصم يتحمله تطبيق إيفينتفل)',
      agent_discount: 'قيمة الخصم',
      qosoor_discount: 'قيمة الخصم (يتحمله تطبيق إيفينتفل)',
      price_after_discount: 'السعر بعد الخصم',
      servicesTotalPrice: 'سعر الخدمات',
      finalPrice: 'السعر الإجمالي',
      booking_service_name: 'اسم الخدمة',
      booking_service_price: 'السعر',
      services_details: 'تفاصيل الخدمات',
      services_price: 'سعر الخدمات',
      local_final_price: 'السعر النهائي',
      local_remaining_amount: 'المبلغ المتبقي',
      external_remaining_amount: 'مبلغ الخدمات المتبقي',
      external_remaining_amount_description: 'المبلغ المتبقي الذي يجب على العميل دفعه لإيفينتفل لاستخدام خدمات خارجية',
      contact_information: 'تواصل مع العميل',
      whatsapp: 'واتس اب',
      phone_call: 'اتصال هاتفي',
      change_booking_state: 'تغيير حالة الحجز إلى',
      current_booking_state: 'حالة الحجز الحالية',
      show_next_states: 'إظهار الحالات التالية',
      hide_next_states: 'إخفاء الحالات التالية',
      show_all_states: 'إظهار جميع الحالات',
      hide_all_states: 'إخفاء الحالات',
      setReceiptAsBookingOption: 'تحديد قيمة الفاتورة حسب خيار الحجز',
      bookingFinalValue: 'قيمة الحجز',
      bookingDetails: 'تفاصيل الحجز',
      bookingOption: 'خيار الحجز',
      bookingOptionDescription: 'تفاصيل خيار الحجز',
      bookingOptionPrice: 'السعر',
      bookingOptionNone: 'لا يوجد خيار حجز',
      services: 'الخدمات',
      externalServices: 'الخدمات الخارجية',
      internalServices: 'الخدمات الداخلية',
      serviceName: 'الخدمة',
      description: 'الوصف',
      price: 'السعر',
      pleasePayForExternalServices: 'هذا الحجز يحتوى على مشتريات من مقدمي خدمات آخرين، يرجى دفع المبلغ المتبقي كاملًا عبر تطبيق إيفينتفل',
      bookingInformation: 'بيانات الحجز',
      receipt: 'الفاتورة',
      noExternalServices: 'لا يوجد خدمات خارجية',
      noInternalServices: 'لا يوجد خدمات داخلية',
      enableTaxForBooking: 'تفعيل الضريبة لهذا الحجز'
    },
    bookingPreview: {
      cancelBooking: 'إلغاء الحجز',
      refundAmount: 'المبلغ المسترد',
      refundAmountFancy: 'المبلغ اللي راح نرجعه للعميل',
      refundAmountNote: 'يوجد مبلغ ({amount}) ريال مدفوع من العميل في هذا الحجز،',
      cancelWithRefund: 'إلغاء الحجز مع إرجاع مبلغ للعميل',
      cancelWithoutRefund: 'إلغاء الحجز بدون إرجاع مبلغ للعميل',
      cancelBooking: 'إلغاء الحجز',
      cancelationNotificationNote: 'راح نرسل تنبيه للعميل بإلغاء الحجز',
      updateStateNote: 'لما تغير الحالة بينعكس التغيير على طول وبيوصل تنبيه للعميل وبتغيير حالة حجزه',
      whatsappContactToChangeState: 'لتغيير حالة الحجز تواصل معنا',
      refundAmountRequired: 'الرجاء إدخال قيمة مبلغ الإسترداد',
      cancelationReason: 'سبب الإلغاء',
      showDetails: 'عرض التفاصيل',
      customerName: "إسم العميل",
      customerPhoneNumber: "رقم الهاتف",
      customerNotes: "ملاحظات العميل",
      bookingNumber: 'رقم الحجز',
      created_at: 'تاريخ استلام الحجز',
      eventType: 'نوع المناسبة',
      menCount: "عدد الرجال",
      WomenCount: "عدد النساء",
      peopleCount: "عدد الاشخاص",
      startDate: 'تاريخ بداية الحجز',
      endDate: 'تاريخ نهاية الحجز',
      cancelBooking: 'إلغاء الحجز',
      deposit:'العربون',
      paid_amount:'المبلغ المدفوع (لتطبيق إيفينتفل)',
      remainingAmount:'المبلغ المتبقي',
      total_price:'سعر المكان',
      percentage_tax:'نسبة الضريبة ٪   ',
      tax:'الضريبة',
      totalPriceWithTax:'السعر الاجمالي بعد الضريبة',
      discount: 'الخصم (خصم يتحمله تطبيق إيفينتفل)',
      price_after_discount: 'السعر بعد الخصم',
      services_price: 'سعر الخدمات',
      local_final_price: 'السعر النهائي',
      local_remaining_amount: 'المبلغ المتبقي',
      external_remaining_amount: 'مبلغ الخدمات المتبقي',
      external_remaining_amount_description: 'المبلغ المتبقي الذي يجب على العميل دفعه لإيفينتفل لاستخدام خدمات خارجية',
      contact_information: 'تواصل مع العميل',
      whatsapp: 'واتس اب',
      phone_call: 'اتصال هاتفي',
      changeBookingStateTo: 'تغيير حالة الحجز إلى',
      currentBookingState: 'حالة الحجز الآن',
      bookingOption: 'خيار الحجز',
      bookingOptionDescription: 'تفاصيل خيار الحجز',
      bookingOptionPrice: 'السعر',
      bookingOptionNone: 'لا يوجد خيار حجز',
      services: 'الخدمات',
      externalServices: 'الخدمات الخارجية',
      internalServices: 'الخدمات الداخلية',
      serviceName: 'الخدمة',
      description: 'الوصف',
      price: 'السعر',
      pleasePayForExternalServices: 'هذا الحجز يحتوى على مشتريات من مقدمي خدمات آخرين، يرجى دفع المبلغ المتبقي كاملًا عبر تطبيق إيفينتفل',
      bookingInformation: 'بيانات الحجز',
      receipt: 'الفاتورة',
      enableTaxForBooking: 'تفعيل الضريبة لهذا الحجز',
      bookingDate: 'تاريخ المناسبة',
      moreInformation: 'تفاصيل الحجز',
      basePrice: 'السعر الأساسي',
      taxAmount: 'قيمة الضريبة'
    },
    bookingOptionPreview: {
      description: 'التفاصيل',
      laterDepositAllowed: 'يسمح بدفع العربون لاحقا',
      noBookingOptions: 'لا يوجد باقات حجز في الوقت الحالي، يمكنك إضافة باقات حجز عن طريق زر الإضافة'
    },
    servicesForm: {
      addService: 'إضافة خدمات اختيارية',
      editService: 'تعديل خدمات اختيارية',
      duplicateService: 'نسخ الخدمة',
      name: 'اسم الإضافة',
      serviceImages: 'صور الإضافة',
      percentageEventfulCommission: 'نسبة عمولة إيفينتفل',
      percentageTax: 'نسبة الضريبة',
      description: 'ايش يشمل السعر؟',
      choosePricingType: 'طريقة تسعير الإضافة',
      fixedPrice: 'السعر الثابت',
      manPrice: 'سعر الشخص / سعر الرجل / سعر الكبير (حسب المناسبة)',
      womanPrice: 'سعر الساعة / سعر المرأة / سعر الطفل (حسب المناسبة)',
      thirdInput: 'السعر المتغير الثالث',
      AddImagesBrief: 'أضف المزيد من الصور لإضافتها إلى الإضافة',
      images: 'صور الإضافة',
      imagesUpdload: 'اختر صور الإضافة',
      deleteImageBtn: 'حذف هذه الصورة الآن',
      save: 'حفظ',
      enableTax: 'تفعيل الضريبة على خدمة القاعة',
      tooltip: {
        name: 'اسم الخدمة',
        pricingType: 'نوع تسعيرة الخدمة',
        percentageTax: 'تطبيق ضريبة القيمة المضافة على الخدمة',
        fixedPrice: 'السعر الثابت',
        manPrice: 'قيمة السعر بحسب اختياركم إما بناء على الرجل الواحد أو الشخص الواحد',
        womanPrice: 'قيمة السعر بحسب اختياركم إما بناء على المرأة الواحدة أو الساعة الواحدة',
        thirdInput: 'قيمة السعر المتغير الثالث',
        description: 'وصف الخدمة',
      },
      groupPricing: {
        alert: {
          header: 'هذه التسعيرات يتم احتسابها في حال وصل عدد الضيوف إلى عدد معين',
          line1: 'مثال: عندما يكون السعر الأساسي للرجل الواحد 50 ريال، يمكن تخفيض السعر عندما يكون عدد الرجال أكثر من 30 بحيث يتم إدخال رقم 30 في خانة (عدد الرجال) والرقم 40 في خانة (سعر الرجل)',
          line2: 'ويمكن تخفيضه أكثر عند وصول عدد الرجال إلى 40، بحيث يتم إضافة سطر جديد بالبيانات المرغوب بها، الرقم 40 في خانة (عدد الرجال) والرقم 30 في خانة (سعر الرجل)',
        },
        primaryPrices: 'التسعيرات الأساسية',
        groupPrices: 'تسعيرات المجموعات',
        addRow: 'إضافة صف',
        menCount: 'عدد الرجال/الأشخاص',
        manPrice: 'سعر الرجل/الشخص',
        womenCount: 'عدد النساء/الساعات',
        womanPrice: 'سعر النساء/الساعة',
      },
      addBookingOptions: 'اضف هذه الخدمة إلى باقات الحجز'
    },
    bookingUpdatesList:{
      name: 'اسم العميل',
      phone_number: 'رقم الجوال',
      category: 'حالة الحجز',
      booking_at: 'تاريخ الحجز',
      start_date: 'تاريخ المناسبة',
      from: 'مصدر الحجز',
      showAllDetails: 'إظهار كامل التفاصيل',
    },
    contract: {
      percentage:'سيحصل تطبيق إيفينتفل على عمولة بقيمة  :percentage:٪  من كل حجز',
      applyCheck: 'الموافقة على الشروط و الأحكام',
      message: 'بضغطك على زر “أوافق” سيمكنك بدء استخدام المنصة وتجهيز خدماتك لعرضها في تطبيق إيفينتفل',
      apply: 'موافق',
      vat_number: 'الرقم الضريبي',
      company_name: 'اسم الشركة',
      commercial_record: 'السجل التجاري',
      noContract: 'لا يوجد لديكم عقد مع إيفينتفل نرجو منكم التواصل مع الفريق الإداري لتفعيل حسابكم',
    },
    onBoarding: {
      venueInformation: 'البيانات الرئيسية',
      backToOnboarding: 'الرجوع إلى تسجيل البداية',
      thankyouHeader: 'شكرًا لتسجيلكم في تطبيق ايفنتفل',
      thankyouLine1: 'الرجاء الانتظار لحظات ليتم إعادة توجيهكم...',
      venueInformationForm: {
        name: 'المنشأة (الاسم التجاري الذي سيظهر في التطبيق)',
        arabic_name: 'اسم القاعة العربي'
      },
      bookingOptions: 'باقات وخيارات الحجز',
      services: 'خدمات إضافية',
      next: 'التالي',
      done: 'إنهاء',
      complete: 'إكمال',
      previous: 'السابق',
      skip: 'تخطي الخطوة',
      tooltip: {
        nextStep : 'الانتقال إلى الخطوة التالية',
        saveAndNext: 'الحفظ والانتقال إلى الخطوة التالية',
        previous: 'الرجوع إلى الخطوة السابقة',
        complete: 'حفظ البيانات المدخلة وإنهاء عملية إدخال البيانات الأولية',
        disabledButtonVenueInformation: 'قم بتغيير الخانات في بيانات القاعة لتفعيل زر الحفظ',
        saveVenueInformation: 'حفظ بيانات القاعة'
      }
    },
    pages: {
      contract: "عقد العمل مع إيفينتفل آيجنت",
      calender: 'التقويم',
      calculator: 'حاسبة الاسعار',
      bookingsList: 'قائمة الحجوزات',
      bookingsUpdates: 'تحديثات الحجوزات',
      PricingsBookingOptions: 'الأسعار وباقات الحجز',
      dashboard: 'لوحة التحكم',
      bookingsManagement: 'إدارة الحجوزات',
      customPricings: 'التسعيرات',
      placeInfo: 'بيانات المكان',
      onBoarding: 'تسجيل البداية',
      bookingsLeads: 'العملاء المحتملين',
      discountCoupons: 'كوبونات الخصم',
      businessEvents: 'طلبات الشركات',
      venueSettlements: 'التسويات',
      reviews:'التقييمات',
      questions: 'أسئلة العملاء',
      business_marketing: 'الرصيد الإعلاني',
      businessEventConfirmationDetails: 'ستظهر بيانات المناسبة الإضافية في حال تسجيل اهتمامك.',
      BookingsCharts: 'المبيعات',
      eventfulServices: {
        geoupHeader: 'خدمات إيفنتفل',
        contactUs: 'تواصل معنا',
        contactUsDetails:'شكرا على اهتمامك! يمكنك التواصل معنا',
        thirdDimensionPhotography: 'تصوير ثلاثي الأبعاد',
        thirdDimensionPhotographyDetails:'  خبراءنا يحولون رؤيتك إلى حقيقة ثلاثية الأبعاد بإستخدام تكنولوجيا متطورة ',
        manageSocialMedia: 'إدارة السوشل ميديا',
        manageSocialMediaDetails: 'خبراء إدارة وسائل التواصل الاجتماعي ، إستراجيات مخصصة لتعزيز حضورك على السوشال ميديا، زيادة التفاعل والمتابعة. ',
        specialWebsite: 'موقع إلكتروني خاص',
        specialWebsiteDetails: 'تصميم وتطوير مواقع الويب المخصصة، تجربة مستخدم سلسة وواجهة مستخدم جذابة، موقع الويب الخاص بك يعكس هويتك ويحقق اهدافك.',
        specialApp: 'تطبيق خاص',
        specialAppDetails: '  تطوير تطبيقات الجوال المخصصة و المبتكرة، تصميم واجهة مستخدم جذابة وتجربة مستخدم سلسة، اتصل بنا اليوم لبدء تطوير تطبيقك الخاص وتحقيق رؤيتك. ',
        buttonToGo:'تواصل',
      },
      BookingOptions: 'باقات الحجز',
      Services: 'الخدمات الإضافية',
    },
    calculator:{
      calc: 'حساب',
      name: "اسم القاعة",
      description: "التفاصيل",
      price: "السعر",
      tax: "الضريبة",
      total: "الإجمالي",
      deposit: "العربون",
      eventTyopes: "تفاصيل الخدمات",
    },
    english: 'الإنجليزية',
    arabic: 'العربية',
    editsDone: 'تم التعديل',
    addedDone: 'تم الإضافة',
    updateDone: 'تم التعديل',
    deleteDone: 'تم الحذف بنجاح',
    error: 'خطأ',

    dateRangePicker: {
      from: 'من',
      to: 'إلى',
      timePeriod: "الفترة الزمنية",
      submit: "تطبيق"
    },
    calendar: {
      bookingBlock: 'حجب الحجوزات',
      doYouWantToRemoveBookingBlock: 'هل تريد حذف حجب الحجوزات ؟',
    },
    statisticsCards: {
      bookings: {
        monthNameStatistics: 'احصائيات شهر {monthName}',
        bookingsCount: '{bookingsCount} حجز',
        totalPriceBookings: 'إجمالي {totalPriceBookings} ريال',
        knowMore: 'اعرف اكثر',
        leadsCount: '{leadsCount} حجز محتمل',
        totalLeadBookings: 'بقيمة {totalLeadBookings} ريال',
        convertIntoRealSales: 'حولهم إلى مبيعات حقيقية',
        marketingCredit: 'رصيدك الإعلاني',
        marketingCreditAmount: '{marketingCredit} ريال',
        knowMoreAboutBusinessMarketing: 'تعرف على باقات الإعلان',
        sales: '{sales} ريال'
      },
      filters: {
        bookingDateTypeSelect: {
          bookingDate: 'تاريخ الحجز',
          eventDate: 'تاريخ المناسبة'
        }
      }
    },
    venueSettlements: {
      title: 'التسوية',
      startDate: 'بداية الفترة',
      endDate: 'نهاية الفترة',
      status: 'الحالة',
      bookingsCount: 'عدد الحجوزات',
      settlementAmount: 'قيمة التسوية',
      month: 'الشهر',
      exportBookings: 'تحميل الحجوزات',
      viewReceipt: 'عرض الفاتورة',
      transformationReceipt: 'إيصال الحوالة',
      completed_bookings_count: '# الحجوزات المكتملة',
      total_completed_final_price: 'قيمة المبيعات المكتملة',
      total_paid_at_place: 'قيمة المبالغ المحصلة في إيفينتفل',
      total_paid_at_eventful: 'قيمة المبالغ المحصلة لديكم',
      total_paid_amount: 'إجمالي المبالغ المحصلة',
      settlementDetails: 'تفاصيل التسوية',
      bookingsInSuttlement: 'الحجوزات المرتبطة بالتسوية',
      statuses: {
        draft: 'بانتظار موافقتكم',
        approved: 'مقبولة',
        rejected: 'مرفوضة',

      },
      booking: {
        bookingNumber: 'رقم الحجز',
        eventDate: 'تاريخ المناسبة',
        bookingDate: 'تاريخ الحجز',
        state: 'الحالة',
        customerName: 'اسم العميل',
        totalPrice: 'السعر الإجمالي (ريال)',
        paidViaEventful: 'المبالغ المحصلة لدى إيفينتفل',
        paidAtPlace: 'المبالغ المحصلة لديكم',
        totalPaidAmount: 'إجمالي المبالغ المحصلة',
        cogs: 'الصافي لكم',
        eventfulCommissionPercentage: '% إيفينتفل',
        settlementAmount: 'مبلغ التسوية'
      },
      confirmation: {
        title: 'هل أنت متأكد؟',
        rejectionReason: 'سبب الرفض',
        approvedBtn: 'تأكيد',
        rejectedBtn: 'رفض',
        approvedDetails: 'بعد تأكيد كشف الحجوزات سنقوم بتحويل مبلغ التسوية لكم خلال 3 أيام عمل',
        rejectedDetails: 'الرجاء تزويدنا بسبب رفضكم للتسوية بالتفصيل لتتم المراجعة والعمل على التسوية مرة أخرى، مع توضيح الحجوزات والمبلغ المسبب للرفض',
        rejectedDetailsPlaceholder: 'مثال: الحجز رقم xxxxx مكتمل وليس ملغي. الرجاء تحديث كشف الحجوزات بناء على ذلك',
        suttlementUpdatedTitle: 'شكرًا لكم',
        suttlementUpdated: 'تم تعديل التسوية بنجاح',
      }
    },
    venueReviews:{
      booking:'حجز',
      BookingOption:'خيار حجز',
      bookingType:'Booking type',
      bookingType:'نوع الحجز',
      addedAt:'وقت التقييم',
      account:'الحساب المرتبط بالعميل',
      bookingRating:'تقييم العميل',
      bookingReview:'رأي العميل',
      status:'حالة التقييم',
      visibleToAgent:'غير ظاهر للعملاء',
      visibleToCustomers:'ظاهر للعملاء',
      image:'صور من العميل',
      bookingDetails:'تفاصيل الحجز',
      reviewDetails : 'تفاصيل التقييم'
    },
    questions: {
      onBoardingPopup: {
        title: 'ميزة إيفينتفل الجديدة 🔥 ',
        contectTitle: 'التواصل مع عملاءكم صار أسهل',
        contect: 'أسئلة العملاء عبر التطبيق راح تظهرلكم في هذي الصفحة، ولما تجاوبون عليها راح يظهر السؤال والجواب للجميع على تطبيق إيفينتفل 🤩',
        ok: 'حسنا'
      },
      question: 'سؤال العميل',
      answer: 'الجواب',
      askedAt: 'وقت السؤال',
      bookingOption: 'باقة الحجز',
      status: 'الحالة',
      answered: 'تمت الاجابة في {answeredAt}',
      notAnswered: 'لم تتم الاجابة',
      details: 'التفاصيل',
      visibleToCustomers: 'ظاهر للعملاء',
      notVisibleToCustomers: 'غير ظاهر للعملاء',
      questionAboutPopupHead: 'سؤال العميل عن {bookingOptionName}',
      form: {
        question: 'سؤال العميل عبر التطبيق',
        answerTooltip: 'اللي بتكتبه هنا راح يظهر للعميل وكل العملاء اللي بيتصفحون باقة الحجز',
        answer: 'الجواب',
      }
    },
    months: {
      January: 'يناير',
      February: 'فبراير',
      March: 'مارس',
      April: 'أبريل',
      May: 'مايو',
      June: 'يونيو',
      July: 'يوليو',
      August: 'أغسطس',
      September: 'سبتمبر ',
      October: 'أكتوبر',
      November: 'نوفمبر',
      December: 'ديسمبر',

    },
    business_marketing: {
      is_recommended: 'باقة مرشحة',
      limied_offer: 'عرض محدود',
      choose_package: 'اختيار الباقة',
      can_not_buy_package: 'لا يوجد رصيد للشراء',
      please_relogin: 'فشل في تحديد الرصيد، الرجاء تسجيل الدخول إلى النظام مرة أخرى',
      available_credit_balance: 'الرصيد التسويقي المتاح للاستخدام',
      for_recharge_contact_us: 'لإعادة شحن الرصيد تواصل معنا عبر الواتس اب',
      no_enough_balance: 'عذرًا, لا يوجد رصيد كافي',
      confirmation_prompt: {
        accept: 'تأكيد',
        cancel: 'إلغاء',
        are_you_sure: 'هل تريد تأكيد الطلب؟',
        package_name: 'عنوان الطلب',
        current_balance: 'الرصيد الحالي',
        balance_after_request: 'الرصيد بعد التأكيد',
        confirmation_note: 'سيتم التواصل معكم لتأكيد بيانات الطلب واختيار تاريخ الحملة التسويقية وغيره من التفاصيل، ويكنكم الغاء الطلب او تأجيله لاحقاً.'
      }
    }
  },

}
