/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_SETTLEMENTS(state, settlements) {
    state.settlements = settlements
  },
  UPDATE_SETTLEMENT(state, updatedSettlement) {

    const index = state.settlements.findIndex(settlement => settlement.id === updatedSettlement.id)

    if(index !== -1) {
        state.settlements.splice(index, 1, updatedSettlement)
    }
  },
  SET_INVOLVED_BOOKINGS(state, involvedBookings) {
    state.involvedBookings = involvedBookings
  },
  SET_INVOLVED_BOOKINGS_LOADED(state, newSate) {
    state.involvedBookingsLoaded = newSate
  }
}
