/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_BOOKING_OPTIONS(state, bookingOptions) {
    state.bookingOptions = bookingOptions
  },
  UPDATE_BOOKING_OPTION(state, updatedBookingOption) {
    const index = state.bookingOptions.findIndex(bookingOption => bookingOption.id === updatedBookingOption.id)

    if(index !== -1) {
      state.bookingOptions.splice(index, 1, updatedBookingOption)
    }
  },
  DUPLICATE_BOOKING_OPTION(state, data) {
    if(data.isCurrentVenueSelected) 
      state.bookingOptions.push(data.addedBookingOptions);
  },
  ADD_BOOKING_OPTION(state, addedBookingOptions) {
    state.bookingOptions.push(addedBookingOptions);
  },
  REMOVE_BOOKING_OPTION(state, deletedBookingOption) {
    const index = state.bookingOptions.findIndex(bookingOption => bookingOption.id === deletedBookingOption.id)

    if(index !== -1) {
      state.bookingOptions.splice(index, 1)
    }
  },
}
