export default {
    install (Vue) {
        Vue.prototype.addEventToGoogleAnalytics = function(eventCategory, action) {
            let isLoggedIn = this.$store.getters.isLoggedIn;

            let currentVenue = { name: 'Unknown'};
            let currentUser = { full_name: 'Unknown'};
            if(isLoggedIn) {
                currentVenue = JSON.parse(localStorage.getItem('currentVenue'));
                currentUser = this.$store.getters.currentUser;
            }
            
            this.$gtag.event(`${currentUser.full_name} from ${currentVenue.name} has ${action}`, {
              'event_category': eventCategory,
            });
        };

    }
  }