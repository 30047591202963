/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_CUSTOMERS_QUESTIONS(state, customersQuestions) {
    state.customersQuestions = customersQuestions
  },
  UPDATE_CUSTOMER_QUESTION(state, updatedCustomerQuestion) {
    const index = state.customersQuestions.findIndex(customerQuestion => customerQuestion.id === updatedCustomerQuestion.id)

    if(index !== -1) {
      state.customersQuestions.splice(index, 1, updatedCustomerQuestion)
    }
  },
  // ADD_BOOKING_OPTION(state, addedBookingOptions) {
  //   state.bookingOptions.push(addedBookingOptions);
  // },
}
