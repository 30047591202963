/*=========================================================================================
  File Name: moduleTodoActions.js
  Description: Todo Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

export default {
  getDaysList ({ commit }) {
    return new Promise((resolve, reject) => {

      axios.get(`/days`)
        .then((response) => {
          commit('SET_DAYS_LIST', response.data.days)
          resolve(response)
        })
        .catch((error) => { 
          console.log(error, "from store :(")
          reject(error) 
        })
    })
  },
  getFacilitiesList ({ commit }, venueId) {
    return new Promise((resolve, reject) => {

      axios.get(`/facilities`)
        .then((response) => {
          commit('SET_FACILITIES_LIST', response.data.data)
          resolve(response)
        })
        .catch((error) => { 
          console.log(error, "from store :(")
          reject(error) 
        })
    })
  },

  getRoomsList({ commit }, venueId) {
    return new Promise((resolve, reject) => {

      axios.get(`/venues/${venueId}/rooms`)
        .then((response) => {         
          commit('SET_ROOMS_LIST', response.data.data)
          resolve(response)
        })
        .catch((error)   => { 
          console.log(error, "from store :(")
          reject(error) 
        })
    })
  },
  getCitiesList({ commit }) {
    return new Promise((resolve, reject) => {

      axios.get(`/cities`)
        .then((response) => {         
          commit('SET_CITIES_LIST', response.data.data)
          resolve(response)
        })
        .catch((error)   => { 
          console.log(error, "from store :(")
          reject(error) 
        })
    })
  },

  getBookingOptionsList({ commit }, venueId) {
    return new Promise((resolve, reject) => {

      axios.get(`/venues/${venueId}/bookingOptions`)
        .then((response) => {         
          commit('SET_BOOKING_OPTIONS_LIST', response.data.data)
          resolve(response)
        })
        .catch((error)   => { 
          console.log(error, "from store :(")
          reject(error) 
        })
    })
  },
  
}
