/*=========================================================================================
  File Name: moduleAuthGetters.js
  Description: Auth Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  isLoggedIn: state => !!state.token,
  authStatus: state => state.status,
  currentVenue: state => state.currentVenue,
  agentVenues: state => state.agentVenues,
  contract: state => state.contract,
  percentage: state => state.percentage
}
