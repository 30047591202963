/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_CUSTOM_PRICINGS (state, customPricings) {
    state.customPricings = customPricings
  },
  SET_NON_SEASONAL_CUSTOM_PRICING (state, customPricing) {
    state.nonSeasonalCustomPricing = customPricing
  },
  SET_SEASONAL_CUSTOM_PRICINGS (state, customPricings) {
    state.seasonalCustomPricings = customPricings
  },
  SET_CUSTOM_PRICINGS_FOR_CALENDAR (state, customPricings) {
    state.customPricingsInCalendar = [...customPricings]
  },
}
