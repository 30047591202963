/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios'

export default {
  AUTH_REQUESTED(state){
    state.statue = "loading"
  },
  AUTH_VC_SENT(state){
    state.status = "vc-code-sent"
  },
  AUTH_PHONE_NUMBER_ERROR(state){
    state.status = "error"
  },
  LOGOUT_SUCCEEDED(state){
    // update the states
    state.status = ""
    state.token = ""
    state.user = {},
    state.currentVenueId = '',
    state.currentVenue = {},
    state.agentVenues = [],

    // update the storage
    localStorage.removeItem('accessToken')
    localStorage.removeItem('currentUser')
    localStorage.removeItem('currentVenueId')
    localStorage.removeItem('currentVenue')
    localStorage.removeItem('agentVenues')

    // remove the header
    axios.defaults.headers.common['Authorization'] = null;
  },
  AUTH_SUCCEEDED(state, {token, user, venue,contract,percentage,agentVenues}){
    // update the states
    state.status = "success"
    state.token = token
    state.user = user
    const venueId = venue.id
    state.currentVenueId = venueId,
    state.agentVenues = JSON.stringify(agentVenues),
    state.currentVenue = JSON.stringify(venue);
    state.contract = contract;
    state.percentage = percentage;
    // update the storage
    localStorage.setItem('accessToken', token)
    localStorage.setItem('currentUser', JSON.stringify(user))
    localStorage.setItem('currentVenueId', venueId)
    localStorage.setItem('agentVenues', JSON.stringify(agentVenues))
    localStorage.setItem('currentVenue', JSON.stringify(venue))
    localStorage.setItem('contract', contract)
    localStorage.setItem('percentage', percentage)

    // console.log("the access token isss", token)
    // inject the token into upcoming requests
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  },
  CHANGE_VENUE(state, {venue}){
    // update the states
    const venueId = venue.id
    state.currentVenueId = venueId,
    state.currentVenue = JSON.stringify(venue);
    state.contract = venue.agent_contract;
    state.percentage = venue.agent_contract_percentage;

    // update the storage
    localStorage.setItem('currentVenueId', venueId)
    localStorage.setItem('currentVenue', JSON.stringify(venue))
    localStorage.setItem('contract', venue.agent_contract)
    localStorage.setItem('percentage', venue.agent_contract_percentage)

  },
  AGENT_VENUES(state, agentVenues) {
    state.agentVenues = JSON.stringify(agentVenues),
    localStorage.setItem('agentVenues', JSON.stringify(agentVenues))
  },
  UPDATE_VENUE_LOGO(state, {logo}){
    let venue = JSON.parse(state.currentVenue);
    venue.logo = logo;

    // update the states
    state.currentVenue = JSON.stringify(venue)
    // update the storage
    localStorage.setItem('currentVenue', JSON.stringify(venue))

  },
  SET_BEARER (state, accessToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
  },
  AUTH_VC_CODE_ERROR (state){
    state.status = "error"
  },
  SIGN_CONDITIONS_SUCCEEDED (state, {venue,contract}){
    const venueId = venue.id
    state.currentVenueId = venueId;
    state.currentVenue = venue;
    state.contract = contract;
    localStorage.setItem('currentVenueId', venueId)
    localStorage.setItem('currentVenue', JSON.stringify(venue))
    localStorage.setItem('contract', contract)
  },

  SET_USER(state, user){
    localStorage.setItem('currentUser', JSON.stringify(user));
    state.user = user;
    },

}
