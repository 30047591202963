/*=========================================================================================
  File Name: moduleTodoActions.js
  Description: Todo Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

export default {
  getReceiptFromBookingOption ({ commit }, {receipt: receipt, venueId, startDate, endDate, optionId, menCount, womenCount, peopleCount, isSeperated}) {
    return new Promise((resolve, reject) => {
      let params = new URLSearchParams();

      params.append('start_date', startDate);
      params.append('end_date', endDate);
      params.append('option_id', optionId);
      params.append('men_count', menCount? menCount : 0);
      params.append('women_count', womenCount? womenCount : 0);
      params.append('people_count', peopleCount? peopleCount : 0);
      params.append('is_seperated', isSeperated? isSeperated : 0);
      params.append('receipt_id', receipt.id ? receipt.id : null);

      axios.get(`/venues/${venueId}/receipts/bookingOption/receipt?${params.toString()}`)
        .then((response) => {
          commit('SET_BOOKING_OPTION_RECEIPT', response.data.receipt)
          resolve(response)
        })
        .catch((error) => { 
          console.log(error, "from store :(")
          reject(error) 
        })
    })
  },
  

  



  
}
