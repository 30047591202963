/*=========================================================================================
  File Name: moduleTodoActions.js
  Description: Todo Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

export default {
  async getCategories ({ commit }) {
    return await new Promise((resolve, reject) => {

      axios.get(`/categories`)
        .then((response) => {
          commit('SET_CATEGORIES', response.data.categories )
          resolve(response)
        })
        .catch((error) => { 
          console.log(error, "from store :(")
          reject(error) 
        })
    })
  },

  
  
}
