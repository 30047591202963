// axios
import axios from 'axios'

import store from './store/store';
import i18n from './i18n/i18n';


import router from './router'

const baseURL = process.env.VUE_APP_BASE_URL

const fetchClient = () => {
  const locale = i18n.locale
  // console.log('axios locale: ', locale)
  const defaultOptions = {
    baseURL,
    headers: {
      'Accept': 'application/json',
      'request-source': 'agent'
      // 'Accept-Language': locale || 'ar'
    },
  };

  // Create instance
  let instance = axios.create(defaultOptions);
  
  instance.defaults.headers.common['Accept-Language'] = locale || 'ar';

  // Set the AUTH token for all requests
  const token = localStorage.getItem('accessToken');
  if(token){
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }

  instance.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    if (error.response && error.response.status === 401) {
      console.log(error.response.data)
      router.push('/login')
    }
    return Promise.reject(error)
  })

  return instance;
};

export default fetchClient();
