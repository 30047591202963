/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'

// Use v-calendar & v-date-picker components
import VCalendar from 'v-calendar';

Vue.use(VCalendar, {
  componentPrefix: 'v',  // Use <vc-calendar /> instead of <v-calendar />
});

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)


// API Calls
import './http/requests'

// mock
import './fake-db/index.js'

// Theme Configurations
import '../themeConfig.js'


// Firebase
import '@/firebase/firebaseConfig'


// Auth0 Plugin
import AuthPlugin from './plugins/auth'
Vue.use(AuthPlugin)


// ACL
import acl from './acl/acl'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'
import './assets/css/q-styles.css'



// Tailwind
import '@/assets/css/main.css'


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'


// i18n
import i18n from './i18n/i18n'


// Vuexy Admin Filters
import './filters/filters'


// Clipboard
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)


// Tour
import VueTour from 'vue-tour'
Vue.use(VueTour)
require('vue-tour/dist/vue-tour.css')


// VeeValidate
import * as VeeValidate from 'vee-validate';
Vue.use(VeeValidate);


import SimpleVueValidation from 'simple-vue-validator';
Vue.use(SimpleVueValidation);

// Google Maps
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    // Add your API key here
    key: 'YOUR_KEY',
    libraries: 'places' // This is required if you use the Auto complete plug-in
  }
})

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)


// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'


// Feather font icon
require('./assets/css/iconfont.css')


// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';


// axios
import axios from './axios.js'
Vue.prototype.$http = axios

Vue.config.productionTip = false


Vue.use(require('vue-moment'));

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { 
    id: process.env.VUE_APP_GOOGLE_ANALUTICS_TRACKING_ID 
  },
},
router);
import googleAnalytics from './plugins/queryParameter/googleAnalytics'; // google analytics
Vue.use(googleAnalytics);


import '@morioh/v-quill-editor/dist/editor.css';
import Editor from '@morioh/v-quill-editor'
Vue.use(Editor);


import booking from './plugins/queryParameter/booking';
Vue.use(booking);

import helpers from './plugins/helpers';
Vue.use(helpers);

import Constants from '/src/utils/constants.js'
Vue.prototype.$constants = Constants; // constants object for global variables

new Vue({
  router,
  store,
  i18n,
  acl,
  render: h => h(App)
}).$mount('#app')
